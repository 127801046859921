import React, { useState, useEffect } from "react";
import Table from "Components/Common/GeneralTable";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import { toast } from "react-toastify";
import officesApi from "api/hrmapi/companySettingOffices";
import UpdateOffice from "./UpdateOffice";
function AllOffices({ fetchOffice, setFetchOffices }) {
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupId, setPopupId] = useState("");
  const [upDateId, setUpDateId] = useState("");

  const handleDeleteClick = (itemToDelete) => {
    officesApi.deleteOffice(itemToDelete.id)
      .then((response) => {
        const newData = dataToShow.filter(
          (item) => item.id !== itemToDelete.id
        );
        setDataToShow(newData);
        const Message = response
          ? "Record Deleted Successfully From List"
          : "An error occurred";
        toast.success(`${Message}`);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
      });
  };
  const handleUpdateClick = (itemId) => {
    setPopupId("updateOffice");
    setUpDateId(itemId);
  };
  const actions = [
    {
      name: "Edit",
      icon: "./assets/icon/edit.png",
      onClick: (item) => handleUpdateClick(item.id),
    },
    // {
    //   name: "Delete",
    //   color: "red",
    //   icon: "./assets/icon/delete.png",
    //   onClick: handleDeleteClick,
    // },
  ];
  useEffect(() => {
    officesApi.allOffices()
      .then((res) => {
        const modifiedData = res.data.locations.map((item) => ({
          id: item.id,
          address: item.address,
          phone: item.phone,
          zip: item.zip,
          location: item.location,
        }));
        setDataToShow(modifiedData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, [fetchOffice]);
  
  return (
    <>
      <ShowComponent condition={popupId === "updateOffice"}>
        <Popup setPopup={() => setPopupId("")} className="w-[50%]">
          <UpdateOffice
            setPopupId={setPopupId}
            upDateId={upDateId}
            setFetchOffices={setFetchOffices}
          />
        </Popup>
      </ShowComponent>

      <Table data={dataToShow} loading={loading} actions={actions} />
    </>
  );
}

export default AllOffices;
