import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function EndDayReport({
  showTime,
  selectedSalesman,
  selectedBranch,
  endDaySaleReport,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="flex justify-center mb-4">
          <img
            src="/icons/no-image.png"
            alt="No Image Found"
            className="h-[70px]"
          />
        </div>
        <div className="flex justify-center items-end mb-4">
          <div>
            <div className="flex gap-3">
              <div className="font-bold">End Day Report :</div>
              <div>
                {endDaySaleReport
                  ? FormatDate(endDaySaleReport?.session_created_at)
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-center">
            <thead className="w-full border-y-1 border-black">
              <tr>
                <th className="w-[20%] p-2">OP #</th>
                <th className="w-[20%] p-2">Branch #</th>
                <th className="w-[20%] p-2">Salesman</th>
                <th className="w-[20%] p-2">Print Date</th>
                <th className="w-[20%] p-2">Print Time</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t-1 border-black">
                <td className="w-[20%] p-2">253</td>
                <td className="w-[20%] p-2">1 (ID: {selectedBranch ? selectedBranch?.id : ""})</td>
                <td className="w-[20%] p-2">{selectedSalesman?.name
                ? capitalizeFirstLetter(selectedSalesman?.name)
                : ""}</td>
                <td className="w-[20%] p-2">{FormatDate(new Date())}</td>
                <td className="w-[20%] p-2">{showTime}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br/><br/><br/>
        <div className="border-1 border-black p-4 w-[360px] m-auto">
          <div className="font-bold w-[50%] mb-4">Sales Activity</div>
          <div className="flex w-full">
            <div className="w-[60%]">Cash: </div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_cash_received
                ? endDaySaleReport?.total_cash_received
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Visa Cash:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_visa_received
                ? endDaySaleReport?.total_visa_received
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Tabby Cash:</div>
            <div className="w-[40%]">{endDaySaleReport?.total_tabby_received}</div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Tamara Cash:</div>
            <div className="w-[40%]">{endDaySaleReport?.total_tamara_received}</div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Return Sales:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.return ? endDaySaleReport?.return : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Coupon Discount:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.coupon_discount
                ? endDaySaleReport?.coupon_discount
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Discount:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_discount
                ? endDaySaleReport?.total_discount
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Tax:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_tax
                ? endDaySaleReport?.total_tax
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Rewards Amount:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_reward
                ? endDaySaleReport?.total_reward
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-5">
            <div className="font-bold w-[60%]">Total Sales:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_sale_price
                ? endDaySaleReport?.total_sale_price
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="font-bold w-[60%]"> Net Sales:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.net_sale ? endDaySaleReport?.net_sale : "0.0"}
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default EndDayReport;
