import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Button from "Components/Common/Button";
import PageTitle from "Components/Common/PageTitle";

function SaleManDetail() {
  const location = useLocation();
  const commissionData = location.state?.commissionData || {};
  const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || !str) return "";
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/allpos-salecommission");
  };

  return (
    <AppWrapper sideBar={<PosSidebar />}>
      <PagesWrapper>
        <PageTitle pageTitle={"Sales Man Commission Detail"}/>
        <div className="flex justify-end gap-2 mb-4">
          <Button
            className="btn-primary"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <div className="overflow-x-auto bg-white rounded-xl mb-6 p-3">
          <table className="w-full text-center">
            <thead>
              <tr>
                <th className="p-3 py-4 text-blue">Commission Type</th>
                <th className="p-3 py-4 text-blue">Value Type</th>
                <th className="p-3 py-4 text-blue">Value</th>
                <th className="p-3 py-4 text-blue">Salesman Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                  {capitalizeFirstLetter(commissionData.sale_commision_type) ||
                    "N/A"}
                </td>
                <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                  {capitalizeFirstLetter(commissionData.commision_type) || "N/A"}
                </td>
                <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                  {commissionData.commision_value || "N/A"}
                </td>
                <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                  {capitalizeFirstLetter(commissionData.salesman) || "N/A"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default SaleManDetail;
