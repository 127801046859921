import React from "react";
import DatePicker from "react-datepicker";

function SelectDate({ label, selectedDate, onDateChange }) {
  return (
    label === "Start Date" ? (
      <div className="mb-5">
      <label className="font-semibold mb-3 block">{label}</label>
      <div className="">
        <DatePicker
          className="form-control"
          placeholderText="Select Date"
          selected={selectedDate}
          onChange={(date) => onDateChange(date)}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    </div>
    ): label === "End Date" ? (
      <div className="mb-5">
      <label className="font-semibold mb-3 block">{label}</label>
      <div className="">
        <DatePicker
          className="form-control"
          placeholderText="Select Date"
          selected={selectedDate}
          onChange={(date) => onDateChange(date)}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    </div>
    ):(
      (
        <div className="form-group ">
        <label className="form-label">{label}</label>
        <div className="">
          <DatePicker
            className="form-control"
            placeholderText="Select Date"
            selected={selectedDate}
            onChange={(date) => onDateChange(date)}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
    ))
  );
}

export default SelectDate;
