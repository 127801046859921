import React, { useState, useEffect } from "react";
import Button from "Components/Common/Button";
import AdminSideBar from "Components/Common/AdminSideBar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import LocationsAPI from "api/location/location";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";
import CreateLocationPermission from "./CreateLocationPermission";
import Table from "Components/Common/GeneralTable";
import { useNavigate } from "react-router-dom";

function LocationPermission() {
  const [popupId, setPopupId] = useState("");
  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const navigate = useNavigate();

  const handleDetailsClick = (itemId) => {
    navigate(`/location-permission/detail/${itemId}`);
  };
  const actions = [
    {
      name: "View",
      icon: "./assets/icon/view.png",
      onClick: (item) => handleDetailsClick(item.id),
    },
  ];

  const fetchUsers = () => {
    LocationsAPI.allAdminUser()
      .then((response) => {
        const filterData = response?.data?.user.map((item) => {
          const {id, role, created_at, location_id, email, username, ...rest } = item;
          const username_email = item.email || item.username;
          return {id, username_email, ...rest };
        });
        setLocationData(filterData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <AppWrapper sideBar={<AdminSideBar />}>
        <PagesWrapper>
          <div className="w-full flex my-4 justify-between">
            <div className="flex items-center justify-center">
              <div className="text-24 font-semibold"> Location Permission</div>
            </div>
            <div className="w-[15%] flex justify-end text-end">
              <button
                className="btn-primary w-full"
                onClick={() => setPopupId("addPermission")}
              >
                Create Permission
              </button>
            </div>
          </div>
          <ShowComponent condition={popupId === "addPermission"}>
            <Popup setPopup={() => setPopupId("")} className="w-[70%]">
              <CreateLocationPermission
                contentOnly={true}
                setPopupId={setPopupId}
                fetchUsers={fetchUsers}
              />
            </Popup>
          </ShowComponent>
          <Table data={locationData} actions={actions} loading={loading} />
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default LocationPermission;
