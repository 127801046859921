import { Input } from "@mui/material";
import DatePicker from "./DatePicker";

function FilterElement({
  label,
  predicate,
  filterValue,
  changePredicate,
  changeFilter,
  selectedDate,
  onDateChange,
  options,
}) {
  const filterOptions = {
    "Customer Type": [
      { label: "Select Type", value: "" },
      { label: "individual", value: "0" },
      { label: "business", value: "1" },
    ],
    "Discount Method": [
      { label: "Select Method", value: "" },
      { label: "Percentage", value: "0" },
    ],
  };

  return (
    <div className="mb-5">
  <label htmlFor="filter" className="font-semibold mb-3 block">
    {label}
  </label>
  <div className="flex items-center flex-row">
    <select
      className="form-control bg-slate-100 w-2/4 rounded-tr-none rounded-br-none p-[0.813rem] border-r-0"
      id="filter"
      value={predicate}
      onChange={(e) => changePredicate(e.target.value)}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {(label === "Status" ||
      label === "Customer Type" ||
      label === "Discount Method") && (
      <select
        className="form-control bg-slate-100 w-2/4 rounded-tr-none rounded-br-none p-[0.813rem] border-r-0"
        value={filterValue}
        onChange={(e) => changeFilter(e.target.value)}
      >
        {filterOptions[label].map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )}
    {!(label === "Status" || label === "Customer Type" || label === "Discount Method") && (
      <input
        type="text"
        className="form-control rounded-bl-none rounded-tl-none"
        id="basic-url"
        aria-describedby=""
        value={filterValue}
        onChange={(e) => changeFilter(e.target.value)}
      />
    )}
    {selectedDate && (
      <DatePicker
        className="filter-datepicker"
        placeholderText="Select Date"
        selected={selectedDate}
        onChange={(date) => onDateChange(date)}
      />
    )}
  </div>
  </div>

  );
}

export default FilterElement;
