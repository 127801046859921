import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SidebarMenuItem from "../Routes/SidebarMenuItem";
import { setCurrentOpenDropdown, setSidebarOpen } from "../../store/Routes"; 
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ menuItems }) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const dispatch = useDispatch();
  const currentOpenDropdown = useSelector((state) => state.route.currentOpenDropdown);
  const isSidebarOpen = useSelector((state) => state.route.isSidebarOpen); 
  const handleDropdownToggle = (dropdownName) => {
    if (dropdownName === currentOpenDropdown) {
      dispatch(setCurrentOpenDropdown(""));
    } else {
      dispatch(setCurrentOpenDropdown(dropdownName));
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };

  const currentUser = useSelector((state) => state.auth.currentUser);
  const role = currentUser?.role_id;
  const hideComponents = location.pathname === "/users/invitation/accept";

  useEffect(() => {
    if (!menuItems.some(item => item.subMenuItems && item.subMenuItems.some(subItem => subItem.path === location.pathname))) {
      dispatch(setCurrentOpenDropdown(""));
    }
  }, [location.pathname]);
  return (
    <div className="bg-blue min-h-screen">
      <div className={`${isSidebarOpen ? "w-72" : "w-20"} h-auto duration-300`}>
        <div className="flex items-center justify-center relative my-8">
          <img
            src="/assets/svg/logo-icon.svg"
            className={`cursor-pointer duration-500 inline-block ${isSidebarOpen && "hidden"}`}
            alt="Logo icon"
            onClick={navigateToHome}
          />
          <img
            src="/assets/svg/sidebar-controller.svg"
            className={`${!isSidebarOpen ? "rotate-180 cursor-pointer right-2 absolute" : "absolute cursor-pointer right-3 top-2"}`}
            onClick={() => dispatch(setSidebarOpen(!isSidebarOpen))} 
            alt="Sidebar Controller"
          />
          <h1 className={`${!isSidebarOpen && "scale-0 hidden"}`}>
            <img
              src="/assets/svg/logo.svg"
              className="cursor-pointer"
              alt="Dashboard Logo"
              onClick={navigateToHome}
            />
          </h1>
        </div>
        <ul className="relative">
          {menuItems.map((Items) => (
            <li
              key={Items.key}
              className={`relative ${!isSidebarOpen ? "sidebar-item-open" : "sidebar-item-closed"}`}
              onMouseEnter={() => Items.subMenuItems ? setOpenSubMenu(Items.key) : setOpenSubMenu(null)}
              onMouseLeave={() => setOpenSubMenu(null)}
            >
              <SidebarMenuItem
                key={Items.key}
                menuItem={Items}
                role={role}
                currentOpenDropdown={currentOpenDropdown}
                handleDropdownToggle={handleDropdownToggle}
                menuOpen={isSidebarOpen}
              />
              {!isSidebarOpen && openSubMenu === Items.key && (
                <ul
                  className="absolute top-0 left-20 p-2 bg-menu-active text-white rounded-lg z-50 w-[200px]"
                  onMouseEnter={() => setOpenSubMenu(Items.key)}
                  onMouseLeave={() => setOpenSubMenu(null)}
                >
                  {Items.subMenuItems &&
                    Items.subMenuItems.map((subItem) => (
                      <li key={subItem.key}>
                        <NavLink
                          to={subItem.path}
                          className={({ isActive }) =>
                            `${isActive ? "bg-blue block border-r-8 border-white p-4 rounded-lg text-white" : "block border-white p-4 text-white"} `
                          }
                        >
                          {subItem.text}
                        </NavLink>
                      </li>
                    ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;