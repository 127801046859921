import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LogIn from "Components/Auth/LogIn";
import MainDashboard from "Components/MainDashboard";
import AccountsDashboard from "./AccountsDashboard";
import PosDashboard from "./PosDashboard";
import CreateAdmin from "Components/CreateAdmin";
import AllAdmin from "Components/AllAdmin";
import CreateCommission from "Components/Configurations/CreateCommission";
import CommissionDetails from "Components/Configurations/CommissionDetails";
import CommissionUpdate from "Components/Configurations/CommissionUpdate";
import AllCommission from "Components/Configurations/AllCommission";
import CreateCustomer from "Components/Accounts/CreateCustomer";
import CustomerDetails from "Components/Accounts/CustomerDetails";
import UpdateCustomer from "Components/Accounts/UpdateCustomer";
import AllCustomer from "Components/Accounts/AllCustomer";
import CreateSupplier from "Components/Accounts/CreateSupplier";
import SupplierDetails from "Components/Accounts/SupplierDetails";
import UpdateSupplier from "Components/Accounts/UpdateSupplier";
import AllSupplier from "Components/Accounts/AllSupplier";
import CreateProduct from "Components/Pos/CreateProduct";
import ProductDetail from "Components/Pos/ProductDetail";
import UpdateProduct from "Components/Pos/UpdateProduct";
import AllProduct from "Components/Pos/AllProduct";
import CreatePurchase from "Components/Pos/CreatePurchase";
import PurchaseDetail from "Components/Pos/PurchaseDetail";
import AllPurchase from "Components/Pos/AllPurchase";
import CreateDiscount from "Components/Pos/CreateDiscount";
import DiscountDetails from "Components/Pos/DiscountDetails";
import AllDiscounts from "Components/Pos/AllDiscounts";
import CreateSale from "Components/Pos/CreateSale";
import SalePages from "Components/Pos/CreateSale/SalePages";
import SaleDetail from "Components/Pos/SaleDetails";
import AllSales from "Components/Pos/AllSales";
import CreateVendor from "Components/Pos/CreateVendor";
import VendorDetails from "Components/Pos/VendorDetails";
import AllVendors from "Components/Pos/AllVendor";
import CreateTax from "Components/Pos/CreateTax";
import TaxDetails from "Components/Pos/TaxDetails";
import AllTaxes from "Components/Pos/AllTaxes";
import AllSaleCommission from "Components/Pos/AllSaleCommission";
import PageNotFound from "./PageNotFound";
import CreateLocation from "Components/Pos/Locations/CreateLocation";
import AllLocation from "Components/Pos/Locations/AllLocation";
import LocationDetails from "Components/Pos/Locations/LocationDetails";
import UpdateLocation from "Components/Pos/Locations/UpdateLocation";
import CreateTransfer from "Components/Pos/Transfer/CreateTransfer";
import LocationPermission from "Components/Pos/Locations/LocationPermission";
import ForgetPassWord from "Components/Auth/ForgetPassWord";
import ResendPassWord from "Components/Auth/ResendPassWord";
import Register from "Components/Auth/RegisterUser";
import SuperAdminDashboard from "Components/SuperAdminDashboard";
import CashierDashboard from "Components/CashierDashboard";
import AllCoupons from "Components/Pos/AllCoupons";
import PosReports from "Components/Reports/PosReports";
import TransferHistory from "Components/Pos/Transfer/TransferHistory";
import Transfers from "Components/Pos/Transfer/Transfers";
import CashierUi from "Components/Pos/CreateSale/CashierUi";
import UserDetails from "Components/UserDetails";
import Circular from "Components/Pos/Circular/Circular";
import DiscountTypes from "Components/Pos/DiscountTypes/DiscountTypes";
import SaleManDetail from "Components/Pos/CreateSale/SaleManDetail";
import ProductCommissionUpdate from "Components/Configurations/ProductCommissionUpdate";
import CashierSession from "Components/Pos/CashierSession";
import HrmDashboard from "./HrmDashboard";
import Recruitment from "Components/HRM/Recruitment & Onboarding/Recruitment";
import CreateEmployeeDataManagement from "Components/HRM/EmployeeDataManagement/CreateEmployeeDataManagement";
import CreatePosition from "Components/HRM/Positions/CreatePosition";
import AllPositions from "Components/HRM/Positions/AllPositions";
import CreateDepartment from "Components/HRM/Departments/CreateDepartment";
import AllDepartments from "Components/HRM/Departments/AllDepartments";
import Department from "Components/HRM/Departments/Department";
import CreateContract from "Components/HRM/Contract/CreateContract";
import AllContract from "Components/HRM/Contract/AllContract";
import Contract from "Components/HRM/Contract/Contract";
import Position from "Components/HRM/Positions/Position";
import CreateBranches from "Components/HRM/Branches/CreateBranches";
import Branches from "Components/HRM/Branches/Branches";
import AllBranches from "Components/HRM/Branches/AllBranches";
import CreateDocument from "Components/HRM/Document/CreateDocument";
import Document from "Components/HRM/Document/Document";
import AllDocument from "Components/HRM/Document/AllDocument";
import CreateAssestsType from "Components/HRM/AssetsType/CreateAssestsType";
import AssestsType from "Components/HRM/AssetsType/AssestsType";
import AllAssestsType from "Components/HRM/AssetsType/AllAssestsType";
import CreateCompanyAssets from "Components/HRM/CompanyAssets/CreateCompanyAssets";
import CompanyAssets from "Components/HRM/CompanyAssets/CompanyAssets";
import AllComapnyAssets from "Components/HRM/CompanyAssets/AllComapnyAssets";
import AllEmployeeDataMagement from "Components/HRM/EmployeeDataManagement/AllEmployeeDataMagement";
import EmployeeDataMagement from "Components/HRM/EmployeeDataManagement/EmployeeDataMagement";
import ProductCommissionDetail from "Components/Configurations/ProductCommissionDetail";
import AssetsDetails from "Components/HRM/AssetsType/AssetsDetails";
import BranchesDetails from "Components/HRM/Branches/BranchesDetails";
import CompanyAssetsDetails from "Components/HRM/CompanyAssets/CompanyAssetsDetails";
import ContractDetails from "Components/HRM/Contract/ContractDetails";
import DeparmentsDetails from "Components/HRM/Departments/DeparmentsDetails";
import DocumentDetails from "Components/HRM/Document/DocumentDetails";
import PositionDetails from "Components/HRM/Positions/PositionDetails";
import EmployeMagement from "Components/HRM/EmployeeDataManagement/EmployeMagement";
import EmployeeDetails from "Components/HRM/EmployeeDataManagement/EmployeeDetails";
import PayrollProcessing from "Components/HRM/PayRoll/PayrollProcessing";
import CreateAllowance from "Components/HRM/PayRoll/CreateAllowance";
import AllAllowances from "Components/HRM/PayRoll/AllAllowances";
import UpdateAllowance from "Components/HRM/PayRoll/UpdateAllowance";
import AllowanceDetails from "Components/HRM/PayRoll/AllowanceDetails";
import AllPayroll from "Components/HRM/PayRoll/AllPayroll";
import UpdatePayroll from "Components/HRM/PayRoll/UpdatePayroll";
import SessionExpired from "./SessionExpired";
import LocPermissionDetail from "Components/Pos/Locations/LocPermissionDetail";
import CustomReport from "Components/Reports/CustomReport";
import InvitationEmployee from "Components/HRM/EmployeeDataManagement/InvitationEmployee";
import CompanySettings from "Components/HRM/Settings/CompanySettings/CompanySettings";
import AllCards from "Components/Configurations/AllCards";
import CardDetail from "Components/Configurations/CardDetail";
import UpdateCard from "Components/Configurations/UpdateCard";
import AllAccounts from "Components/InventoryManagement/Accounts/AllAccounts";
import AccountsDetail from "Components/InventoryManagement/Accounts/AccountsDetail";
import UpdateAccount from "Components/InventoryManagement/Accounts/UpdateAccount";

function AppRouter() {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const role = currentUser?.role_id;
  const location = useLocation();

  const hideComponents =
    location.pathname === "/users/invitation/accept" ||
    location.pathname === "/password/reset" ||
    location.pathname === "/login" ||
    location.pathname === "/resend-password";

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser == null) {
      if (hideComponents) {
        return <Register />;
      }
      navigate("/login");
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          role === 4 ? (
            <Navigate to="/cashier-ui" />
          ) : role === 3 || role === 2 || role === 1 ? (
            <Navigate to="/" />
          ) : (
            <LogIn />
          )
        }
      />
      <Route
        path="/"
        element={
          role === 2 || role === 3 ? (
            <MainDashboard />
          ) : role === 4 ? (
            <CashierDashboard />
          ) : role === 1 ? (
            <SuperAdminDashboard />
          ) : null
        }
      />
      <Route
        path="/accounts-dashboard"
        element={
          role === 2 || role === 3 ? <AccountsDashboard /> : <SessionExpired/>
        }
      />
      <Route
        path="/hrm-dashboard"
        element={
          role === 2 || role === 3 ? <HrmDashboard /> : <SessionExpired/>
        }
      />
      <Route
        path="/pos-dashboard"
        element={role === 2 || role === 3 ? <PosDashboard /> : <SessionExpired/>}
      />
      <Route path="/users/invitation/accept?" element={<Register />} />
      <Route path="/password/reset?" element={<ForgetPassWord />} />
      <Route path="/resend-password" element={<ResendPassWord />} />
      <Route
        path="/create-admin"
        element={
          role === 2 || role === 1 || role === 3 ? (
            <CreateAdmin />
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/all-user"
        element={role === 2 || role === 1 ? <AllAdmin /> : <SessionExpired/>}
      />
      <Route
        path="/user/details/:id"
        element={role === 2 || role === 3 ? <UserDetails /> : <SessionExpired/>}
      />
      <Route
        path="/create-commission"
        element={
          role === 2 || role === 3 ? <CreateCommission /> : <SessionExpired/>
        }
      />
      <Route
        path="/all-commission"
        element={
          role === 2 || role === 3 ? <AllCommission /> : <SessionExpired/>
        }
      />
      <Route
        path="/all-cards"
        element={
          role === 2 || role === 3 ? <AllCards /> : <SessionExpired/>
        }
      />
      <Route
        path="/card-detail/:id"
        element={
          role === 2 || role === 3 ? <CardDetail /> : <SessionExpired/>
        }
      />
      <Route
        path="/edit-card/:id"
        element={
          role === 2 || role === 3 ? <UpdateCard /> : <SessionExpired/>
        }
      />
      <Route
        path="/productcommission/edit/:id"
        element={
          role === 2 || role === 3 ? <ProductCommissionUpdate /> : <SessionExpired/>
        }
      />
      <Route
        path="/productcommission/details/:id"
        element={
          role === 2 || role === 3 ? <ProductCommissionDetail /> : <SessionExpired/>
        }
      />
      <Route
        path="/cashier-session"
        element={
          role === 2 || role === 3 ? <CashierSession /> : <SessionExpired/>
        }
      />
      <Route
        path="/commission/details/:id"
        element={
          role === 2 || role === 3 ? <CommissionDetails /> : <SessionExpired/>
        }
      />
      <Route
        path="/commission/edit/:id"
        element={
          role === 2 || role === 3 ? <CommissionUpdate /> : <SessionExpired/>
        }
      ></Route>
      <Route
        path="/create-customer"
        element={
          role === 2 || role === 3 ? <CreateCustomer /> : <SessionExpired/>
        }
      />
      <Route
        path="/accounts-customers"
        element={role === 2 || role === 3 ? <AllCustomer /> : <SessionExpired/>}
      />
      <Route
        path="/pos-customers"
        element={role === 2 || role === 3 ? <AllCustomer /> : <SessionExpired/>}
      />
      <Route
        path="/accounts/customer/edit/:id"
        element={
          role === 2 || role === 3 ? <UpdateCustomer /> : <SessionExpired/>
        }
      />
      <Route
        path="/pos/customer/edit/:id"
        element={
          role === 2 || role === 3 ? <UpdateCustomer /> : <SessionExpired/>
        }
      />
      <Route
        path="/pos/customer/details/:id"
        element={
          role === 2 || role === 3 ? <CustomerDetails /> : <SessionExpired/>
        }
      />
      <Route
        path="/accounts/customer/details/:id"
        element={
          role === 2 || role === 3 ? <CustomerDetails /> : <SessionExpired/>
        }
      />
      <Route
        path="/create-supplier"
        element={
          role === 2 || role === 3 ? <CreateSupplier /> : <SessionExpired/>
        }
      ></Route>
      <Route
        path="/all-supplier"
        element={role === 2 || role === 3 ? <AllSupplier /> : <SessionExpired/>}
      ></Route>
      <Route
        path="/supplier/edit/:id"
        element={
          role === 2 || role === 3 ? <UpdateSupplier /> : <SessionExpired/>
        }
      />
      <Route
        path="/supplier/details/:id"
        element={
          role === 2 || role === 3 ? <SupplierDetails /> : <SessionExpired/>
        }
      />
      <Route
        path="/create-product"
        element={
          role === 2 || role === 3 ? <CreateProduct /> : <SessionExpired/>
        }
      />
      <Route
        path="/products"
        element={role === 2 || role === 3 ? <AllProduct /> : <SessionExpired/>}
      />
      <Route
        path="/product/details/:id"
        element={
          role === 2 || role === 3 ? <ProductDetail /> : <SessionExpired/>
        }
      />
      <Route
        path="/product/edit/:id"
        element={
          role === 2 || role === 3 ? <UpdateProduct /> : <SessionExpired/>
        }
      />
      <Route
        path="/create-purchase"
        element={
          role === 2 || role === 3 ? <CreatePurchase /> : <SessionExpired/>
        }
      />
      <Route
        path="/all-purchase"
        element={role === 2 || role === 3 ? <AllPurchase /> : <SessionExpired/>}
      />
      <Route
        path="/purchase/details/:id"
        element={
          role === 2 || role === 3 ? <PurchaseDetail /> : <SessionExpired/>
        }
      />
      <Route
        path="/create-discount"
        element={
          role === 2 || role === 3 ? <CreateDiscount /> : <SessionExpired/>
        }
      />
      <Route
        path="/discounts"
        element={role === 2 || role === 3 ? <AllDiscounts /> : <SessionExpired/>}
      />
      <Route
        path="/discounts/:discount_type/:id"
        element={role === 2 || role === 3 ? <DiscountTypes /> : <SessionExpired/>}
      />
      <Route
        path="/coupons"
        element={role === 2 || role === 3 ? <AllCoupons /> : <SessionExpired/>}
      />
      <Route
        path="/discount/details/:id"
        element={
          role === 2 || role === 3 ? <DiscountDetails /> : <SessionExpired/>
        }
      />
      <Route
        path="/create-sale"
        element={
          role === 2 || role === 3 || role === 4 ? (
            <CreateSale />
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/sale-pages"
        element={
          role === 2 || role === 3 || role === 4 ? (
            <SalePages />
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/cashier-ui"
        element={
          role === 2 || role === 3 || role === 4 ? (
            <CashierUi />
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/circulars"
        element={
          role === 2 ? (
            <Circular />
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/sales"
        element={
          role === 2 || role === 3 || role === 4 ? (
            <AllSales />
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/sales/details/:id"
        element={
          role === 2 || role === 3 || role === 4 ? (
            <SaleDetail />
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/create-vendor"
        element={role === 2 || role === 3 ? <CreateVendor /> : <SessionExpired/>}
      />
      <Route
        path="/vendors"
        element={role === 2 || role === 3 ? <AllVendors /> : <SessionExpired/>}
      />
      <Route
        path="/vendor/details/:id"
        element={
          role === 2 || role === 3 ? <VendorDetails /> : <SessionExpired/>
        }
      />
      <Route
        path="/create-tax"
        element={role === 2 || role === 3 ? <CreateTax /> : <SessionExpired/>}
      />
      <Route
        path="/all-taxes"
        element={role === 2 || role === 3 ? <AllTaxes /> : <SessionExpired/>}
      />
      <Route
        path="/tax/details/:id"
        element={role === 2 || role === 3 ? <TaxDetails /> : <SessionExpired/>}
      />
      <Route
        path="/location-permission"
        element={
          role === 2 || role === 3 ? <LocationPermission /> : <SessionExpired/>
        }
      />
       <Route
        path="/location-permission/detail/:id"
        element={
          role === 2 || role === 3 ? <LocPermissionDetail /> : <PageNotFound />
        }
      />
      <Route
        path="/create-location"
        element={
          role === 2 || role === 3 ? <CreateLocation /> : <SessionExpired/>
        }
      />
      <Route
        path="/all-locations"
        element={role === 2 || role === 3 ? <AllLocation /> : <SessionExpired/>}
      />
      <Route
        path="/location/details/:id"
        element={
          role === 2 || role === 3 ? <LocationDetails /> : <SessionExpired/>
        }
      />
      <Route
        path="/location/edit/:id"
        element={
          role === 2 || role === 3 ? <UpdateLocation /> : <SessionExpired/>
        }
      />
      <Route
        path="/create-transfer"
        element={
          role === 2 || role === 3 ? <CreateTransfer /> : <SessionExpired/>
        }
      />
      <Route
        path="/transfer/:id"
        element={role === 2 || role === 3 ? <Transfers /> : <SessionExpired/>}
      />
      <Route
        path="/transfer-history"
        element={
          role === 2 || role === 3 ? <TransferHistory /> : <SessionExpired/>
        }
      />
      <Route
        path="/all-accounts"
        element={
          role === 2 || role === 3 ? <AllAccounts /> : <SessionExpired/>
        }
      />
      <Route
        path="/account-details/:id"
        element={
          role === 2 || role === 3 ? <AccountsDetail /> : <SessionExpired/>
        }
      />
      <Route
        path="/account-update/:id"
        element={
          role === 2 || role === 3 ? <UpdateAccount /> : <SessionExpired/>
        }
      />
      <Route
        path="/pos-reports"
        element={role === 2 ? <PosReports /> : <SessionExpired/>}
      />
      <Route
        path="/custom-reports"
        element={role === 2 ? <CustomReport /> : <SessionExpired/>}
      />
      <Route
        path="/allpos-salecommission"
        element={
          role === 2 || role === 3 || role === 4 ? (
            <AllSaleCommission />
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/salesman-detail"
        element={
          role === 2 || role === 3 || role === 4 ? (
            <SaleManDetail/>
          ) : (
            <SessionExpired/>
          )
        }
      />
   /* ......................    hrm  ................................................... */
      <Route
        path="/create-employee-data-management"
        element={
          role === 2 || role === 3 ? (
            <CreateEmployeeDataManagement/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/employee-Invitations"
        element={
          role === 2 || role === 3 ? (
            <InvitationEmployee/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/all-employee-data"
        element={
          role === 2 || role === 3 ? (
            <AllEmployeeDataMagement/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/employee-data-management"
        element={
          role === 2 || role === 3 ? (
            <EmployeMagement/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/employee-data/edit/:id"
        element={
          role === 2 || role === 3 ? (
            <EmployeeDataMagement/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/employee-data/details/:id"
        element={
          role === 2 || role === 3 ? (
            <EmployeeDetails/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/create-position"
        element={
          role === 2 || role === 3 ? (
            <CreatePosition/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/all-position"
        element={
          role === 2 || role === 3 ? (
            <AllPositions/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/all-contracts"
        element={
          role === 2 || role === 3 ? (
            <AllContract/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/create-contract"
        element={
          role === 2 || role === 3 ? (
            <CreateContract/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/create-branch"
        element={
          role === 2 || role === 3 ? (
            <CreateBranches/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/create-document"
        element={
          role === 2 || role === 3 ? (
            <CreateDocument/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/document/edit/:id"
        element={
          role === 2 || role === 3 ? (
            <Document/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/document/details/:id"
        element={
          role === 2 || role === 3 ? (
            <DocumentDetails/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/all-documents"
        element={
          role === 2 || role === 3 ? (
            <AllDocument/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/create-asset-type"
        element={
          role === 2 || role === 3 ? (
            <CreateAssestsType/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/asset-type/edit/:id"
        element={
          role === 2 || role === 3 ? (
            <AssestsType/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/asset-type/details/:id"
        element={
          role === 2 || role === 3 ? (
            <AssetsDetails/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/all-asset-type"
        element={
          role === 2 || role === 3 ? (
            <AllAssestsType/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/create-company-assets"
        element={
          role === 2 || role === 3 ? (
            <CreateCompanyAssets/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/company-assets/edit/:id"
        element={
          role === 2 || role === 3 ? (
            <CompanyAssets/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/company-assets/details/:id"
        element={
          role === 2 || role === 3 ? (
            <CompanyAssetsDetails/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/all-company-assets"
        element={
          role === 2 || role === 3 ? (
            <AllComapnyAssets/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/branch/edit/:id"
        element={
          role === 2 || role === 3 ? (
            <Branches/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/branch/details/:id"
        element={
          role === 2 || role === 3 ? (
            <BranchesDetails/>
          ) : (
            <SessionExpired/>
          )
        }
      />
             <Route
        path="/all-branches"
        element={
          role === 2 || role === 3 ? (
            <AllBranches/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/contract/edit/:id"
        element={
          role === 2 || role === 3 ? (
            <Contract/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/contract/details/:id"
        element={
          role === 2 || role === 3 ? (
            <ContractDetails/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/position/edit/:id"
        element={
          role === 2 || role === 3 ? (
            <Position/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/position/details/:id"
        element={
          role === 2 || role === 3 ? (
            <PositionDetails/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/create-department"
        element={
          role === 2 || role === 3 ? (
            <CreateDepartment/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/department/edit/:id"
        element={
          role === 2 || role === 3 ? (
            <Department/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/department/details/:id"
        element={
          role === 2 || role === 3 ? (
            <DeparmentsDetails/>
          ) : (
            <SessionExpired/>
          )
        }
      />
       <Route
        path="/all-departments"
        element={
          role === 2 || role === 3 ? (
            <AllDepartments/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/recruitment-&-on-boarding"
        element={
          role === 2 || role === 3 ? (
            <Recruitment/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/create-allowance"
        element={
          role === 2 || role === 3 ? (
            <CreateAllowance/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/all-allowances"
        element={
          role === 2 || role === 3 ? (
            <AllAllowances/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/allowances/edit/:id"
        element={
          role === 2 || role === 3 ? (
            <UpdateAllowance/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/allowances/details/:id"
        element={
          role === 2 || role === 3 ? (
            <AllowanceDetails/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/payroll-processing"
        element={
          role === 2 || role === 3 ? (
            <PayrollProcessing/>
          ) : (
            <SessionExpired/>
          )
        }
      />
      <Route
        path="/all-payroll"
        element={
          role === 2 || role === 3 ? (
            <AllPayroll/>
          ) : (
            <PageNotFound />
          )
        }
      />
      <Route
        path="/update-payroll/:id"
        element={
          role === 2 || role === 3 ? (
            <UpdatePayroll/>
          ) : (
            <PageNotFound />
          )
        }
      />
      <Route
        path="/company-setting"
        element={
          role === 2 || role === 3 ? (
            <CompanySettings/>
          ) : (
            <PageNotFound />
          )
        }
      />

      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}
export default AppRouter;