import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import CreateAccounts from "./CreateAccounts";
import Button from "Components/Common/Button";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";
import AccountsApi from "api/inventorymanagement/accounts";
import Table from "Components/Common/GeneralTable";
import { toast } from "react-toastify";
import Pagination from "Components/Common/Pagination";
import PageTitle from "Components/Common/PageTitle";

function AllAccounts() {
  const [popupId, setPopupId] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemToDelete, setItemToDelete] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });
  const navigate = useNavigate();

  const fetchData = () => {
    AccountsApi.showAllAccount(pageNumber)
      .then((response) => {
        setData(
          Object.groupBy(
            response?.data?.account_codes,
            ({ location }) => location
          )
        );
        setPaginationValue(response.data.page_meta);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  const handleDetailsClick = (itemId) => {
    navigate(`/account-details/${itemId}`);
  };
  const handleUpdateClick = (itemId) => {
    navigate(`/account-update/${itemId}`);
  };
  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setPopupId("accountsDelete");
  };
  const cancelDelete = () => {
    setPopupId("");
    setItemToDelete("");
  };

  const deleteConfirmed = () => {
    setPopupId("");
    if (itemToDelete) {
      AccountsApi.deleteAccount(itemToDelete.id)
        .then((response) => {
          toast.success(response?.data?.message);
          const newData = data.filter((item) => item.id !== itemToDelete.id);
          setData(newData);
          setItemToDelete("");
        })
        .catch((error) => {
          if (error) {
            toast.error("Something Went Wrong!");
          }
        });
    }
  };
  const actions = [
    {
      name: "View",
      icon: "./assets/icon/view.png",
      onClick: (item) => handleDetailsClick(item.id),
    },
    {
      name: "Edit",
      icon: "./assets/icon/edit.png",
      onClick: (item) => handleUpdateClick(item.id),
    },
    {
      name: "Delete",
      icon: "./assets/icon/delete.png",
      onClick: handleDeleteClick,
    },
  ];

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <PageTitle pageTitle={"All Accounts"} />
        <div className="flex justify-between mb-4">
          <div>
            <select
              className="form-control p-2.5 border-0"
              name="locations"
              onChange={(e) => setSelectedBranch(e.target.value)}
            >
              <option value="">Select Location</option>
              {Object.keys(data).map((location) => (
                <option value={location}>{location}</option>
              ))}
            </select>
          </div>
          <div>
            <Button
              className="btn-primary w-full"
              onClick={() => setPopupId("createAccounts")}
            >
              Create Account
            </Button>
            <ShowComponent condition={popupId === "createAccounts"}>
              <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[60%] md:w-[50%]">
                <CreateAccounts
                  contentOnly={true}
                  setPopupId={setPopupId}
                  fetchData={fetchData}
                />
              </Popup>
            </ShowComponent>
          </div>
        </div>

        <ShowComponent condition={popupId === "accountsDelete"}>
          <Popup setPopup={() => setPopupId("")} className="w-[50%] z-40 mt-5">
            {itemToDelete && (
              <div className="w-full flex flex-col text-center mt-4">
                <p className="mb-4">
                  Are you sure you want to delete this Account?
                </p>
                <div className="flex justify-center">
                  <Button
                    className="bg-green text-white py-3.5 px-5 mb-5"
                    onClick={deleteConfirmed}
                  >
                    Yes
                  </Button>
                  <Button
                    className="bg-red text-white py-3.5 px-5 mb-5 ml-3"
                    onClick={cancelDelete}
                  >
                    No
                  </Button>
                </div>
              </div>
            )}
          </Popup>
        </ShowComponent>
        <Table
          data={data[selectedBranch] || []}
          loading={loading}
          actions={actions}
        />
        <ShowComponent condition={data[selectedBranch]?.length || [].length}>
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPages={paginationValue.total_pages}
            totalCount={paginationValue.totalCount}
            startValue={paginationValue.startValue}
            endValue={paginationValue.endValue}
          />
        </ShowComponent>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default AllAccounts;
