import React, { useState } from "react";

const Pagination = ({
  setPageNumber,
  pageNumber,
  totalPages,
  totalCount,
  startValue,
  endValue,
}) => {
  const [tempPage, setTempPage] = useState(pageNumber);

  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(pageNumber + 1);
      setTempPage(tempPage + 1);
    }
  };

  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(pageNumber - 1);
      setTempPage(tempPage - 1);
    }
  };

  const handleChange = (event) => {
    setTempPage(event.target.value);
  };

  const assignPage = (e) => {
    if (e.key === "Enter") {
      const newPage = parseInt(e.target.value, 10);
      if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
        setPageNumber(newPage);
        setTempPage(newPage);
      }
    }
  };

  const handlePages = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPageNumber(newPage);
      setTempPage(newPage);
    }
  };

  const handleLastPage = () => {
    handlePages(totalPages);
    setTempPage(totalPages);
  };

  return (
    <div className="flex justify-center mb-5">
      <ul className="flex justify-between gap-3 bg-white rounded-xl p-2 text-12 leading-6">
        <li
          className={`flex items-center justify-center rounded-md ${
            pageNumber === 1 && "opacity-50 cursor-not-allowed"
          }`}
        >
          <button
            onClick={decrPage}
            className={`text-black h-6 ${
              pageNumber === 1 ? "" : ""
            } px-2  rounded-md text-center `}
          >
            Previous
          </button>
        </li>
        {tempPage > 2 && (
          <li className="flex items-center justify-center">
            <button
              onClick={() => handlePages(1)}
              className={`text-black ${
                tempPage === 2 ? "bg-blue text-white" : ""
              } px-2  rounded-md text-center`}
            >
              1
            </button>
          </li>
        )}
        {tempPage > 4 && (
          <li
            className={`text-black flex items-center justify-center${
              tempPage === 1 ? "bg-blue text-white" : ""
            } px-2  rounded-md text-center `}
          >
            .....
          </li>
        )}
        {tempPage === 4 && (
          <li className="flex items-center justify-center">
            <button
              onClick={() => handlePages(tempPage - 2)}
              className={`text-black ${
                tempPage - 2 === pageNumber ? "bg-blue text-white" : ""
              } px-2  rounded-md text-center`}
            >
              {tempPage - 2}
            </button>
          </li>
        )}
        {tempPage > 1 && (
          <li className="flex items-center justify-center">
            <button
              onClick={() => handlePages(tempPage - 1)}
              className={`text-black ${
                tempPage - 1 === pageNumber ? "bg-blue text-white" : ""
              } px-2  rounded-md text-center`}
            >
              {tempPage - 1}
            </button>
          </li>
        )}
        <li className="flex items-center justify-center">
          <button
            type="number"
            min="1"
            value={tempPage}
            className={`hideNumberArrow text-black h-6 ${
              tempPage == pageNumber ? "bg-blue text-white" : ""
            } px-2  rounded-md text-center`}
            onChange={handleChange}
            onKeyPress={assignPage}>{tempPage}
          </button>
        </li>
        {tempPage < totalPages - 1 && (
          <li className="flex items-center justify-center">
            <button
              onClick={() => handlePages(tempPage + 1)}
              className={`text-black ${
                tempPage + 1 === pageNumber ? "bg-blue text-white ": ""
              } px-2  rounded-md text-center`}
            >
              {tempPage + 1}
            </button>
          </li>
        )}
        {tempPage === 1 && totalPages > 5 && (
          <>
            <li className="flex items-center justify-center">
              <button
                onClick={() => handlePages(tempPage + 2)}
                className={`text-black ${
                  tempPage + 2 === pageNumber ? "bg-blue text-white" : ""
                } px-2  rounded-md text-center`}
              >
                {tempPage + 2}
              </button>
            </li>
            <li className="flex items-center justify-center">
              <button
                onClick={() => handlePages(tempPage + 3)}
                className={`text-black ${
                  tempPage + 3 === pageNumber ? "bg-blue text-white" : ""
                } px-2  rounded-md text-center`}
              >
                {tempPage + 3}
              </button>
            </li>
            <li className="flex items-center justify-center">
              <button
                onClick={() => handlePages(tempPage + 4)}
                className={`text-black ${
                  tempPage + 4 === pageNumber ? "bg-blue text-white" : ""
                } px-2  rounded-md text-center`}
              >
                {tempPage + 4}
              </button>
            </li>
          </>
        )}
        {tempPage < totalPages - 2 && (
          <li className="flex items-center justify-center">
            .....
          </li>
        )}
        {tempPage !== totalPages && (
          <li className="flex items-center justify-center">
            <button
              onClick={handleLastPage}
              className={`text-black ${
                totalPages === pageNumber ? "bg-blue text-white" : ""
              } px-2  rounded-md text-center`}
            >
              {totalPages}
            </button>
          </li>
        )}
        <li
          className={`flex items-center justify-center ${
            pageNumber === totalPages && "opacity-50 cursor-not-allowed"
          }`}
        >
          <button
            onClick={increasePage}
            className={`text-black h-6 ${
              totalPages === pageNumber ? "" : ""
            } px-2  rounded-md text-center`}
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
