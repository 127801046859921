import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Table from "Components/Common/GeneralTable";
import OrderApi from "api/posapi/order";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import FormatDate from "Components/Common/FormatDate";
import Button from "Components/Common/Button";
import ShowComponent from "Components/ShowComponent";
import PageTitle from "Components/Common/PageTitle";

function SaleDetail() {
  let params = useParams();
  const [dataToShow, setDataToShow] = useState([]);
  const [branchName, setBranchName] = useState("");
  const [customerInfo, setCustomerInfo] = useState("");
  const [phoneInfo, setPhoneInfo] = useState("");
  const [salesManInfo, setSalesmanInfo] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [invoiceCreatedAt, setInvoiceCreatedAt] = useState("");
  const [invoicePayment, setInvoicePayment] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [totalTax, setTotalTax] = useState("")
  const [discount, setTotalDiscount] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [aprovalCode, setAprovalCode] = useState("");

  useEffect(() => {
    setLoading(true);
    OrderApi.viewOrderAPI(params)
      .then((response) => {
        setAprovalCode(response.data.sale?.sale_received_amounts[1]?.approval_code)
        const filterData = response.data.sale_items.map((item) => {
          const { created_at, ...filterData } = item;
          return filterData;
        });
        setData(filterData);
        setDataToShow([response?.data?.sale]);
        setCustomerInfo(response?.data?.sale.customer);
        setInvoiceId(response?.data?.sale?.invoice_no);
        setInvoiceStatus(response?.data?.sale?.status);
        setInvoiceCreatedAt(response?.data?.sale?.created_at);
        setInvoicePayment(response?.data?.sale?.payment_type);
        setTotalPrice(response?.data?.sale?.total_price);
        setTotalDiscount(response?.data?.sale?.discount);
        setTotalTax(response?.data?.sale?.tax)
        setSalesmanInfo(response?.data?.sale?.sale_created_by);
        setPhoneInfo(response?.data?.sale?.phone_no);
        setBranchName(response?.data?.sale?.gulehri);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);
  const capitalizeFirstLetter = (string) => {
    return string
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/sales");
  };

  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
          <PageTitle pageTitle={"Sale Detail"}/>
          <div className="flex justify-end gap-2 mb-4">
            <Button
              className="btn-primary"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
          <div class="grid grid-cols-12 gap-x-6">
            <div class="md:col-span-4 col-span-12 mb-5 rounded-2xl bg-white p-5">
              <p class="text-16 font-semibold mb-5">
                Customer Information
              </p>
              <div>
                <div className="flex gap-2 mb-3">
                  <div className="bg-blue rounded-full w-7 h-7 p-1 flex items-center">
                    <img
                      className="w-full"
                      src="/icons/fi-rr-user.svg"
                    />
                  </div>
                  <div className="text-[#374957] text-sm mt-1">
                    {customerInfo}
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="bg-blue rounded-full w-7 h-7 pt-2 p-1 flex items-center">
                    <img
                      className="w-full"
                      src="/icons/fi-rr-phone-call.svg"
                    />
                  </div>
                  <div className="text-[#374957] text-sm mt-1">
                    {phoneInfo}
                  </div>
                </div>
              </div>
            </div>
            <div class="md:col-span-4 col-span-12 mb-5 rounded-2xl bg-white p-5">
              <p class="text-16 font-semibold mb-5">
                Invoice Information
              </p>
              <div className="flex gap-3 mb-3">
                <p className=""><b className="text-blue">Invoice No :</b></p>
                <p>{invoiceId}</p>
              </div>
              <div className="flex gap-3 mb-3">
                <p className=""><b className="text-blue">Status :</b></p>
                <p className="">{capitalizeFirstLetter(invoiceStatus)}</p>
              </div>
              <div className="flex gap-3 mb-3">
                <p className=""><b className="text-blue">Created At :</b></p>
                <p className="">{FormatDate(invoiceCreatedAt)}</p>
              </div>
              <div className="flex gap-3 mb-3">
                <p className=""><b className="text-blue">Payment Method :</b></p>
                <p>{invoicePayment}</p>
              </div>
              <ShowComponent condition={aprovalCode}>
              <div className="flex gap-3 mb-3">
                <p className=""><b className="text-blue">Auth Code :</b></p>
                <p>{aprovalCode}</p>
              </div>
              </ShowComponent>
            </div>
            <div class="md:col-span-4 col-span-12 mb-5 rounded-2xl bg-white p-5">
              <p class="text-16 font-semibold mb-5">
                Salesman Information
              </p>
              <div>
                <div className="flex gap-2 mb-3">
                  <div className="bg-blue rounded-full w-7 h-7 p-1 flex items-center">
                    <img
                      className="w-full"
                      src="/icons/fi-rr-user.svg"
                    />
                  </div>
                  <div className="text-[#374957] text-sm mt-1">
                    {salesManInfo}
                  </div>
                </div>
                <div className="flex gap-2 mb-3 mt-2">
                  <div className="bg-blue rounded-full w-7 h-7 p-1 flex items-center">
                    <img
                      className="w-full"
                      src="/icons/fi-rr-marker.svg"
                    />
                  </div>
                  <div className="text-[#374957] text-sm mt-1">
                    {branchName}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-20 mb-4 text-center font-semibold">Order Summary</div>
          <Table data={data} loading={loading} />
          <div className="flex justify-end mb-5">
            <div class="rounded-lg ml-4 bg-white w-[300px] p-5 py-2">
              <div className="flex justify-between py-3 border-b-1 border-slate-200">
                <p className="font-bold">Total Discount</p>
                <p className="">{discount}</p>
              </div>
              <div className="flex justify-between py-3 border-b-1 border-slate-200">
                <p className="font-bold">Total Tax</p>
                <p className="">{totalTax}</p>
              </div>
              <div className="flex justify-between py-3">
                <p className="font-bold">Grand Total</p>
                <p className="">{totalPrice}</p>
              </div>
            </div>
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default SaleDetail;
