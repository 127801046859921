import React, { useEffect, useState, useRef } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import Button from "Components/Common/Button";
import LocationsAPI from "api/location/location";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import CustomReportApi from "api/reports/customreport";
import UserApi from "api/adminapi/user";
import DatePicker from "react-datepicker";
import FormatDate from "Components/Common/FormatDate";
import SalesmanReport from "./SalesmanReport";
import CouponSaleReport from "./CouponSaleReport";
import DailySaleReport from "./DailySaleReport";
import EndDayReport from "./EndDayReport";
import InvoicesList from "./InvoicesList";
import SalesReportInvoices from "./SalesReportInvoices";
import InvoicesDetailsReport from "./InvoicesDetailsReport";
import ShowComponent from "Components/ShowComponent";
import SalesInvoiceVoucherJV from "./SalesInvoiceVoucherJV";
import PageTitle from "Components/Common/PageTitle";

function CustomReport() {
  const [locations, setLocations] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState({ id: "", name: "" });
  const [selectedReport, setSelectedReport] = useState("");
  const [salesmans, setSalesmans] = useState([]);
  const [selectedSalesman, setSelectedSalesman] = useState({
    id: "",
    name: "",
  });
  const [showReports, setShowReports] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [salesmanReport, setSalesmanReport] = useState("");
  const [reportData, setReportData] = useState([]);
  const [reportSummary, setReportSummary] = useState("");
  const [couponSaleReport, setCouponSaleReport] = useState([]);
  const [couponSummary, setCouponSummary] = useState("");
  const [endDaySaleReport, setEndDaySaleReport] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const pdfRef = useRef();

  useEffect(() => {
    LocationsAPI.allLocationsAPI()
      .then((response) => {
        setLocations(response?.data?.locations);
      })
      .catch((error) => {
        if (error) {
          toast.error("Error fetching locations");
        }
      });

    UserApi.allUsers()
      .then((response) => {
        const { users } = response?.data;
        const cashierUsers = users.filter((user) => user.role === "cashier");
        setSalesmans(users);
      })
      .catch((error) => {
        if (error) {
          toast.error("Error fetching users");
        }
      });
  }, []);

  const daily_sale_report = () => {
    CustomReportApi.dailySalesReport(
      selectedBranch.id,
      FormatDate(startDate),
      FormatDate(endDate)
    )
      .then((response) => {
        setShowReports(true);
        setReportData(response?.data?.list);
        setReportSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const end_day_report = () => {
    if (selectedSalesman.id) {
      CustomReportApi.endDaySaleReport(
        selectedBranch.id,
        selectedSalesman.id,
        FormatDate(startDate)
      )
        .then((response) => {
          setShowReports(true);
          setEndDaySaleReport(response?.data);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setShowReports(false);
        });
    }
  };

  const invoices_list = () => {
    CustomReportApi.invoicesListReport(
      selectedBranch.id,
      FormatDate(startDate),
      FormatDate(endDate),
      "completed"
    )
      .then((response) => {
        setShowReports(true);
        setReportData(response?.data?.list);
        setReportSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const return_invoices_list = () => {
    CustomReportApi.invoicesListReport(
      selectedBranch.id,
      FormatDate(startDate),
      FormatDate(endDate),
      "complete_refunded"
    )
      .then((response) => {
        setShowReports(true);
        setReportData(response?.data?.list);
        setReportSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const coupon_sale_report = () => {
    CustomReportApi.dailyCouponSaleReport(
      selectedBranch.id,
      FormatDate(startDate),
      FormatDate(endDate)
    )
      .then((response) => {
        setShowReports(true);
        setCouponSaleReport(response?.data?.list);
        setCouponSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const salesman_sale_report = () => {
    if (selectedSalesman.id) {
      CustomReportApi.dailySalesmanReport(
        selectedBranch.id,
        selectedSalesman.id,
        FormatDate(startDate),
        FormatDate(endDate)
      )
        .then((response) => {
          setShowReports(true);
          if (response?.data && response?.data?.length > 0) {
            setSalesmanReport(response?.data[0]);
          }
        })
        .catch((error) => {
          setShowReports(false);
          if (error) {
            toast.error("Something Wrong");
          }
        });
    }
  };

  const sales_by_invoice_report = () => {
    CustomReportApi.saleReportInvoices(
      selectedBranch.id,
      FormatDate(startDate),
      FormatDate(endDate),
      "completed"
    )
      .then((response) => {
        setShowReports(true);
        setReportData(response?.data?.list);
        setReportSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const sales_by_invoice_without_vat = () => {
    CustomReportApi.saleReportInvoices(
      selectedBranch.id,
      FormatDate(startDate),
      FormatDate(endDate),
      "completed"
    )
      .then((response) => {
        setShowReports(true);
        setReportData(response?.data?.list);
        setReportSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const sales_by_invoice_report_return = () => {
    CustomReportApi.saleReportInvoices(
      selectedBranch.id,
      FormatDate(startDate),
      FormatDate(endDate),
      "complete_refunded"
    )
      .then((response) => {
        setShowReports(true);
        setReportData(response?.data?.list);
        setReportSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const invoices_details_report = () => {
    CustomReportApi.invoiceDetailsReport(
      selectedBranch.id,
      FormatDate(startDate),
      FormatDate(endDate)
    )
      .then((response) => {
        setShowReports(true);
        setReportData(response?.data?.list);
        setReportSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const sales_invoice_voucher_JV = () => {
    const type = "invoice";
    CustomReportApi.salesInvoiceJV(
      selectedBranch.id,
      invoiceNo,
      FormatDate(startDate),
      type
    )
      .then((response) => {
        setShowReports(true);
        setReportData(response?.data?.list);
        setReportSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const return_invoice_voucher_JV = () => {
    const type = "return_invoice";
    CustomReportApi.salesInvoiceJV(
      selectedBranch.id,
      invoiceNo,
      FormatDate(startDate),
      type
    )
      .then((response) => {
        setShowReports(true);
        setReportData(response?.data?.list);
        setReportSummary(response?.data?.summary);
      })
      .catch((error) => {
        setShowReports(false);
        if (error) {
          toast.error("Something Wrong");
        }
      });
  };

  const availableReportTypes = {
    daily_sale_report,
    end_day_report,
    invoices_list,
    return_invoices_list,
    coupon_sale_report,
    salesman_sale_report,
    sales_by_invoice_report,
    sales_by_invoice_without_vat,
    sales_by_invoice_report_return,
    invoices_details_report,
    sales_invoice_voucher_JV,
    return_invoice_voucher_JV,
  };

  const handleGenerateReport = (e) => {
    e.preventDefault();
    if (startDate && endDate) {
      if (startDate > endDate) {
        toast.error("Please Select Valid Start Date and End Date");
        return;
      }
    }
    if (selectedBranch.id) {
      availableReportTypes[selectedReport]();
    }
  };

  useEffect(() => {
    setShowReports(false);
    setSalesmanReport("");
    setReportData([]);
    setReportSummary("");
    setCouponSaleReport([]);
    setCouponSummary("");
    setEndDaySaleReport("");
  }, [selectedReport, selectedBranch, selectedSalesman, startDate, endDate]);

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF(
        "p",
        "mm",
        selectedReport === "daily_sale_report" ? "a3" : "a4",
        true
      );
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = 1;
      const imgY = 10;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save(capitalizeFirstLetter(selectedReport));
    });
  };

  const reportOptions = [
    { value: "daily_sale_report", label: "Daily Sales Report" },
    { value: "end_day_report", label: "End Day Report" },
    { value: "invoices_list", label: "Invoices List" },
    { value: "return_invoices_list", label: "Return Invoices List" },
    { value: "coupon_sale_report", label: "Sales Report by Coupon" },
    { value: "salesman_sale_report", label: "Sales Report by Salesman" },
    {
      value: "sales_by_invoice_without_vat",
      label: "Sales Report by Invoice without VAT",
    },
    { value: "sales_by_invoice_report", label: "Sales Report by Invoice" },
    {
      value: "sales_by_invoice_report_return",
      label: "Sales Report by Return Invoice",
    },
    {
      value: "invoices_details_report",
      label: "Sales Report Invoices Details",
    },
    {
      value: "sales_invoice_voucher_JV",
      label: "Sales Invoice Voucher(JV)",
    },
    {
      value: "return_invoice_voucher_JV",
      label: "Return Sales Invoice Voucher(JV)",
    },
  ];

  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amPM = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const showTime = `${formattedHours}:${formattedMinutes} ${amPM}`;

  const capitalizeFirstLetter = (string) => {
    return string
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <PageTitle pageTitle="Custom Reports" />
        <form onSubmit={handleGenerateReport}>
          <div className="flex flex-wrap lg:flex-nowrap w-full gap-6 bg-white rounded-2xl p-5 pb-1 mb-5">
            <div className="w-full sm:w-[48%] mb-4 md:w-[31%]">
              <label htmlFor="reportName" className="font-semibold mb-3 block">
                Report Name
              </label>
              <select
                className="form-control"
                required
                value={selectedReport}
                onChange={(e) => setSelectedReport(e.target.value)}
              >
                <option value="">Select Report</option>
                {reportOptions?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <ShowComponent condition={selectedReport}>
              <div className="w-full sm:w-[48%] mb-4 md:w-[31%]">
                <label
                  htmlFor="branchName"
                  className="font-semibold mb-3 block"
                >
                  Branch Name
                </label>
                <select
                  className="form-control"
                  required
                  value={selectedBranch.id}
                  onChange={(e) =>
                    setSelectedBranch({
                      id: e.target.value,
                      name: e.target.options[e.target.selectedIndex].text,
                    })
                  }
                >
                  <option value="">Select Branch</option>
                  {locations?.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.address}
                    </option>
                  ))}
                </select>
              </div>
              <ShowComponent
                condition={
                  selectedReport === "sales_invoice_voucher_JV" ||
                  selectedReport === "return_invoice_voucher_JV"
                }
              >
                <div className="w-full sm:w-[48%] mb-4 md:w-[31%]">
                  <label
                    htmlFor="VoucherNo"
                    className="font-semibold mb-3 block"
                  >
                    Voucher No
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Voucher No"
                    value={invoiceNo}
                    required
                    onChange={(e) => setInvoiceNo(e.target.value)}
                  />
                </div>
              </ShowComponent>
              <ShowComponent
                condition={
                  selectedReport === "salesman_sale_report" ||
                  selectedReport === "end_day_report"
                }
              >
                <div className="w-full sm:w-[48%] mb-4 md:w-[31%]">
                  <label
                    htmlFor="salesMan"
                    className="font-semibold mb-3 block"
                  >
                    Salesman
                  </label>
                  <select
                    className="form-control"
                    required
                    value={selectedSalesman.id}
                    onChange={(e) =>
                      setSelectedSalesman({
                        id: e.target.value,
                        name: e.target.options[e.target.selectedIndex].text,
                      })
                    }
                  >
                    <option value="">Select Salesman</option>
                    {salesmans?.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                </div>
              </ShowComponent>
              <div className="w-full sm:w-[48%] mb-4 md:w-[31%]">
                <label className="font-semibold mb-3 block">
                  {selectedReport === "end_day_report"
                    ? "Session Start Date"
                    : selectedReport === "sales_invoice_voucher_JV" ||
                      selectedReport === "return_invoice_voucher_JV"
                    ? "Voucher Date"
                    : "Start Date"}
                </label>
                <DatePicker
                  required
                  className="form-control"
                  placeholderText="Select Date"
                  dateFormat="MM/dd/yyyy"
                  selected={startDate}
                  // minDate={new Date()}
                  onChange={(date) => setStartDate(date)}
                />
              </div>

              <ShowComponent
                condition={
                  selectedReport !== "end_day_report" &&
                  selectedReport !== "sales_invoice_voucher_JV" &&
                  selectedReport !== "return_invoice_voucher_JV"
                }
              >
                <div className="w-full sm:w-[48%] mb-4 md:w-[31%]">
                  <label className="font-semibold mb-3 block">End Date</label>
                  <DatePicker
                    required
                    className="form-control"
                    placeholderText="Select Date"
                    dateFormat="MM/dd/yyyy"
                    selected={endDate}
                    // minDate={new Date()}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </ShowComponent>
              <div className="mb-4">
                <label className="font-semibold mb-3 hidden sm:block">&nbsp;</label>
                <Button type="submit" className="p-[1.05rem]">
                  Generate
                </Button>
              </div>
            </ShowComponent>
          </div>
          <ShowComponent condition={showReports}>
            <div className="bg-white mb-7 rounded-2xl p-5 pt-8">
              <div
                ref={pdfRef}
              >
                <ShowComponent condition={selectedReport !== "end_day_report"}>
                  <div className="text-24 font-bold text-center mb-4">CUNZITE</div>
                </ShowComponent>

                <ShowComponent
                  condition={selectedReport === "daily_sale_report"}
                >
                  <DailySaleReport
                    startDate={startDate}
                    endDate={endDate}
                    showTime={showTime}
                    selectedBranch={selectedBranch}
                    reportData={reportData}
                    reportSummary={reportSummary}
                    capitalizeFirstLetter={capitalizeFirstLetter}
                    contentOnly={true}
                  />
                </ShowComponent>

                <ShowComponent
                  condition={selectedReport === "salesman_sale_report"}
                >
                  <SalesmanReport
                    startDate={startDate}
                    endDate={endDate}
                    selectedBranch={selectedBranch}
                    selectedSalesman={selectedSalesman}
                    salesmanReport={salesmanReport}
                    showTime={showTime}
                    capitalizeFirstLetter={capitalizeFirstLetter}
                    contentOnly={true}
                  />
                </ShowComponent>

                <ShowComponent
                  condition={selectedReport === "coupon_sale_report"}
                >
                  <CouponSaleReport
                    startDate={startDate}
                    endDate={endDate}
                    selectedBranch={selectedBranch}
                    couponSaleReport={couponSaleReport}
                    couponSummary={couponSummary}
                    showTime={showTime}
                    capitalizeFirstLetter={capitalizeFirstLetter}
                    contentOnly={true}
                  />
                </ShowComponent>
                <ShowComponent condition={selectedReport === "end_day_report"}>
                  <EndDayReport
                    showTime={showTime}
                    selectedSalesman={selectedSalesman}
                    selectedBranch={selectedBranch}
                    endDaySaleReport={endDaySaleReport}
                    capitalizeFirstLetter={capitalizeFirstLetter}
                    contentOnly={true}
                  />
                </ShowComponent>
                <ShowComponent
                  condition={
                    selectedReport === "return_invoices_list" ||
                    selectedReport === "invoices_list"
                  }
                >
                  <InvoicesList
                    startDate={startDate}
                    endDate={endDate}
                    selectedReport={selectedReport}
                    reportData={reportData}
                    reportSummary={reportSummary}
                    capitalizeFirstLetter={capitalizeFirstLetter}
                    contentOnly={true}
                  />
                </ShowComponent>
                <ShowComponent
                  condition={
                    selectedReport === "sales_by_invoice_report" ||
                    selectedReport === "sales_by_invoice_without_vat" ||
                    selectedReport === "sales_by_invoice_report_return"
                  }
                >
                  <SalesReportInvoices
                    startDate={startDate}
                    endDate={endDate}
                    showTime={showTime}
                    selectedReport={selectedReport}
                    selectedBranch={selectedBranch}
                    reportData={reportData}
                    reportSummary={reportSummary}
                    capitalizeFirstLetter={capitalizeFirstLetter}
                    contentOnly={true}
                  />
                </ShowComponent>
                <ShowComponent
                  condition={selectedReport === "invoices_details_report"}
                >
                  <InvoicesDetailsReport
                    startDate={startDate}
                    endDate={endDate}
                    showTime={showTime}
                    selectedReport={selectedReport}
                    selectedBranch={selectedBranch}
                    selectedSalesman={selectedSalesman}
                    reportData={reportData}
                    reportSummary={reportSummary}
                    capitalizeFirstLetter={capitalizeFirstLetter}
                    contentOnly={true}
                  />
                </ShowComponent>
                <ShowComponent
                  condition={
                    selectedReport === "sales_invoice_voucher_JV" ||
                    selectedReport === "return_invoice_voucher_JV"
                  }
                >
                  <SalesInvoiceVoucherJV
                    showTime={showTime}
                    invoiceNo={invoiceNo}
                    selectedBranch={selectedBranch}
                    selectedReport={selectedReport}
                    reportData={reportData}
                    reportSummary={reportSummary}
                    capitalizeFirstLetter={capitalizeFirstLetter}
                    contentOnly={true}
                  />
                </ShowComponent>
              </div>
              <div className="flex justify-end mt-5">
                <Button onClick={downloadPDF}>
                  Download PDF
                </Button>
              </div>
            </div>
          </ShowComponent>
        </form>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CustomReport;
