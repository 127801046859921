import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AdminApi from "api/adminapi/user";
import PagesWrapper from "./Common/Pages/PagesWrapper";
import ShowComponent from "./ShowComponent";
import Button from "./Common/Button";
import UserApi from "api/adminapi/user";
import AppWrapper from "./Routes/AppWrapper";
import AdminSideBar from "./Common/AdminSideBar";
import { toast } from "react-toastify";
import PasswordChecklist from "react-password-checklist";

const CreateAdmin = ({ contentOnly = false, setPopupId, fetchData }) => {
  const [byEmail, setByEmail] = useState(false);
  const [byUserName, setByUserName] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const [formState, setFormState] = useState({
    role: null,
    roles: [],
    message: "",
    error: "",
    loading: false,
  });
  const [passwordChecklist, setPasswordChecklist] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    capital: false,
    match: false,
  });

  const CurrentUserRole = useSelector(
    (state) => state.auth.currentUser?.role_id
  );

  const handleByEmail = (buttonType) => {
    setIsActive(buttonType);
    setByEmail(!byEmail);
    setByUserName(false);
    setUserName("");
    setPassword("");
    setConfirmPassword("");
  };

  const handleByUserName = (buttonType) => {
    setIsActive(buttonType);
    setByUserName(!byUserName);
    setByEmail(false);
    setEmail("");
  };

  const handlePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordShow = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    const checklist = {
      minLength: value.length >= 6,
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      number: /\d/.test(value),
      capital: /[A-Z]/.test(value),
      match: value === confirmPassword,
    };
    setPasswordChecklist(checklist);
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    setPasswordChecklist((prevChecklist) => ({
      ...prevChecklist,
      match: password === value,
    }));
  };

  const allCriteriaMet = Object.values(passwordChecklist).every((item) => item);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    if (!allCriteriaMet && byUserName) {
      toast.error("Password Does Not Meet Criteria");
      return;
    }
    setFormState((prevFormState) => ({
      ...prevFormState,
      loading: true,
    }));
    UserApi.sendEmailUser({
      user: {
        username: userName,
        password: password,
        password_confirmation: confirmPassword,
        email: email.toLocaleLowerCase(),
        role_id: parseInt(formState?.role),
      },
    })
      .then((response) => {
        const Message = response
          ? "Invitation has been sent"
          : "An error occurred";
        if (userName && Message === "Invitation has been sent") {
          toast.success("User Created Successfully");
          fetchData();
        } else {
          toast.success(`Success:${Message}`);
          fetchData();
        }
        setPopupId("");
        setFormState((prevFormState) => ({
          ...prevFormState,
          email: "",
          role: "",
          loading: false,
        }));
      })
      .catch((error) => {
        const errorss = error
          ? error?.response?.data.error
          : "An error occurred";
        toast.error(`${errorss}`);
        setFormState((prevFormState) => ({
          ...prevFormState,
          loading: false,
        }));
      });
  };

  useEffect(() => {
    if (CurrentUserRole === 2 || CurrentUserRole === 3) {
      AdminApi.getRole()
        .then((res) => {
          setFormState((prevFormState) => ({
            ...prevFormState,
            roles: res.data.roles,
          }));
        })
        .catch((error) => {
          console.error("Error fetching roles:", error);
        });
    }
  }, []);

  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper><div className="bg-white m-auto p-4 w-full sm:max-w-[500px] rounded-2xl">
        <h1 className="text-20 mb-4 text-center font-semibold">Create User</h1>
        {CurrentUserRole != 1 && (
        <div className="flex justify-center items-center gap-3">
          <button
            className={`rounded-lg p-3 px-4 w-full ${
              isActive === "email"
                ? "bg-blue text-white"
                : "bg-slate-200 text-black"
            }`}
            onClick={() => handleByEmail("email")}
          >
            By Email
          </button>
          <button
            className={`rounded-lg p-3 px-4 w-full ${
              isActive === "username"
                ? "bg-blue text-white"
                : "bg-slate-200 text-black"
            }`}
            onClick={() => handleByUserName("username")}
          >
            By Username
          </button>
        </div>
        )}
        <form onSubmit={handleCreateUser}>
          {(byEmail || CurrentUserRole === 1) && (
            <div className="mt-4">
              <div className="font-semibold mb-3 block">Email</div>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address"
                type="email"
                name="email"
                className="form-control"
                required
              />
              <ShowComponent
                condition={CurrentUserRole === 2 || CurrentUserRole === 3}
              >
              <div className="mt-4">
                <div className="font-semibold mb-3 block">Role</div>
                <select
                  value={formState.role}
                  onChange={handleInput}
                  name="role"
                  className="form-control"
                  required
                >
                  <option value=""> Please select Role</option>
                  {formState.roles
                    .filter(
                      (option) => CurrentUserRole !== 3 || option.type === 4
                    )
                    .map((option, index) => (
                      <option key={index} value={option.type}>
                        {option.role}
                      </option>
                    ))}
                </select>
              </div>
              </ShowComponent>
              <div className="flex w-full justify-center mt-4">
                <Button
                  className={`${
                    formState.loading
                      ? "flex items-center justify-center disabled btn-progress  "
                      : "flex items-center justify-center btn btn-primary"
                  }`}
                  type="submit"
                >
                  Send Email
                </Button>
                {formState.message && (
                  <div className="alert alert-success mt-3 mb-0">
                    {formState.message}
                  </div>
                )}
                {formState.error && (
                  <div className="alert alert-danger mt-3 mb-0">
                    {formState.error}
                  </div>
                )}
              </div>
            </div>
          )}
          {byUserName && (
            <div className="mt-4">
              <div className="">
                <div className="mb-4">
                  <div className="font-semibold mb-3 block">User Name</div>
                  <input
                    className="form-control"
                    value={userName}
                    type="text"
                    placeholder="Enter User Name"
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <div className="font-semibold mb-3 block">Password</div>
                  <div className="relative">
                    <input
                      className="form-control"
                      value={password}
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      required
                      onChange={(e) => handlePasswordChange(e.target.value)}
                    />
                    <span
                      onClick={handlePasswordShow}
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                    >
                      {showPassword ? "👁️" : "🔒"}
                    </span>
                  </div>
                </div>
                <div className="">
                  <div className="font-semibold mb-3 block">Confirm Password</div>
                  <div className="relative mb-4">
                    <input
                      className="form-control"
                      value={confirmPassword}
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      required
                      onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                    />
                    <span
                      onClick={handleConfirmPasswordShow}
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                    >
                      {showConfirmPassword ? "👁️" : "🔒"}
                    </span>
                  </div>
                  <PasswordChecklist
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                    ]}
                    minLength={6}
                    value={password}
                    valueAgain={confirmPassword}
                  />
                </div>
              </div>
              <ShowComponent
                condition={CurrentUserRole === 2 || CurrentUserRole === 3}
              >
                <div className="my-4">
                  <div className="">
                    <div className="font-semibold mb-3 block">Role</div>
                    <select
                      className="form-control"
                      value={formState.role}
                      onChange={handleInput}
                      name="role"
                      required
                    >
                      <option value=""> Please select Role</option>
                      {formState.roles
                        .filter(
                          (option) => CurrentUserRole !== 3 || option.type === 4
                        )
                        .map((option, index) => (
                          <option key={index} value={option.type}>
                            {option.role}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </ShowComponent>
              <div className="flex w-full justify-center mt-2">
                <button
                  className={`${
                    formState.loading
                      ? "disabled btn-progress  "
                      : "btn btn-primary"
                  }`}
                  type="submit"
                >
                  Create User
                </button>
                {formState.message && (
                  <div className="alert alert-success mt-3 mb-0">
                    {formState.message}
                  </div>
                )}
                {formState.error && (
                  <div className="alert alert-danger mt-3 mb-0">
                    {formState.error}
                  </div>
                )}
              </div>
            </div>
          )}
        </form>
      </div></PagesWrapper>
    </AppWrapper>
  );
};

export default CreateAdmin;
