import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LocationsAPI from "api/location/location";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import Button from "Components/Common/Button";
import AdminSideBar from "Components/Common/AdminSideBar";
import Table from "Components/Common/GeneralTable";
import AppWrapper from "Components/Routes/AppWrapper";
import Pagination from "Components/Common/Pagination";

function AllLocation() {
  const navigate = useNavigate();
  const [dataToShow, setDataToShow] = useState([]);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showpopup, setShowPopUp] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });
  const handleUpdateClick = (itemId) => {
    navigate(`/location/edit/${itemId}`);
  };
  const handleCreateLocation = () => {
    navigate(`/create-location`);
  };
  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setShowPopUp(true);
  };
  const deleteConfirmed = () => {
    setShowPopUp(false);
    if (itemToDelete) {
      LocationsAPI.DeleteLocationAPI(itemToDelete.id)
        .then((response) => {
          const newData = dataToShow.filter(
            (item) => item.id !== itemToDelete.id
          );
          setDataToShow(newData);
          setStatus(response.status);
          setTimeout(() => {
            setStatus("");
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
      setItemToDelete(null);
    }
  };
  const cancelDelete = () => {
    setShowPopUp(false);
    setItemToDelete(null);
  };
  const handleDetailsClick = (itemId) => {
    navigate(`/location/details/${itemId}`);
  };
  const actions = [
    {
      name: "View",
      icon: "./assets/icon/view.png",
      onClick: (item) => handleDetailsClick(item.id),
    },
    {
      name: "Edit",
      icon: "./assets/icon/edit.png",
      onClick: (item) => handleUpdateClick(item.id),
    },
    {
      name: "Delete",
      color: "red",
      icon: "./assets/icon/delete.png",
      onClick: handleDeleteClick,
    },
  ];
  useEffect(() => {
    setLoading(true);
    LocationsAPI.allLocationsAPI(pageNumber, filterPerpage)
      .then((response) => {
        const filterData = response?.data?.locations.map((item) => {
          const { updated_at, zip, currency, ...filterData } = item;
          return filterData;
        });
        setDataToShow(filterData);
        setTotalPages(response.data.total_pages);
        setPaginationValue({
          ...paginationValue,
          totalCount: response.data.total_count,
          startValue: response.data.starting_value,
          endValue: response.data.ending_value,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [pageNumber, filterPerpage]);
  return (
    <>
      <AppWrapper sideBar={<AdminSideBar />}>
        <PagesWrapper>
          <div className="w-full flex mb-4 justify-between">
            <div className="text-24 font-semibold mb-4">
              Locations
            </div>
            <div className="w-[15%] flex justify-end mt-2 text-end">
              <button
                className="btn-primary w-full"
                onClick={handleCreateLocation}
              >
                Create Locations
              </button>
            </div>
          </div>
          {status && (
            <div className="alert alert-danger mt-3 mb-0">
              Location deleted successfully
            </div>
          )}
          <ShowComponent condition={showpopup}>
            <Popup
              showpopup={showpopup}
              setShowPopUp={setShowPopUp}
              ClassName="modal-md"
            >
              {itemToDelete && (
                <div className="row text-center mt-4">
                  <p className="col-12">
                    Are you sure you want to delete this Location?
                  </p>
                  <Button
                    className="btn btn-danger bg-red  text-white p-2 mt-2 rounded-md my-3"
                    onClick={deleteConfirmed}
                  >
                    Yes
                  </Button>
                  <Button
                    className="btn btn-success bg-green  text-white p-2 mt-2 rounded-md my-3 ml-2"
                    onClick={cancelDelete}
                  >
                    No
                  </Button>
                </div>
              )}
            </Popup>
          </ShowComponent>
          <Table data={dataToShow} loading={loading} actions={actions} />
          {dataToShow.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}
export default AllLocation;
