import React from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminSideBar from "Components/Common/AdminSideBar";
import { toast } from "react-toastify";
import Button from "Components/Common/Button";
import Loader from "../../assets/svg/Loader.svg";
import PageTitle from "Components/Common/PageTitle";

function ProductCommissionUpdate() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [buttonShow, setButtonShow] = useState(true);
  const navigate = useNavigate();

  const [saleCommissionData, setSaleCommissionData] = useState({
    value: "",
    commisionType: "",
    name: "",
    product_id: "",
    discountValue: "",
  });
  useEffect(() => {
    if (window.location.pathname.includes("commission/details")) {
      setButtonShow(false);
    }
    ConfigurationAPI.viewProductCommission({ id })
      .then((response) => {
        setLoading(false);
        const Data = response.data.sale_product_commision_configuration;
        setSaleCommissionData({
          name: Data.product_name,
          product_id: Data.product_id,
          value: Data.value,
          commisionType: Data.commision_type,
          discountValue: Data.discount_value,
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);
  const handleUpdateSaleCommission = (e) => {
    e.preventDefault();
    if (
      saleCommissionData.commisionType === "percentage" &&
      parseFloat(saleCommissionData.value) > 100
    ) {
      setLoading(false);
      toast.error("Percentage value cannot be greater than 100");
      return;
    }
    setLoading(true);
    ConfigurationAPI.updateProductCommission(id, {
      sale_product_commision_configuration: {
        value: parseFloat(saleCommissionData.value),
        product_id: saleCommissionData.product_id,
        commision_type: saleCommissionData.commisionType,
        discount_thresh: parseFloat(saleCommissionData.discountValue),
        discount_thresh_type: "percentage",
      },
    })
      .then((response) => {
        setLoading(false);
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`Success:${Message}`);
        setTimeout(() => {
          if (!response.data.error) {
            navigate("/all-commission");
          }
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response.data.message);
      });
  };

  const handleBack = () => {
    navigate("/all-commission");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <PageTitle pageTitle={"Update Product Commission"}/>
        <div className="flex justify-end mb-5">
          <Button
            className="btn-primary"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {loading ? (
          <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
            <img src={Loader} className="rounded-full m-auto" alt="Loading" />
          </div>
        ) : (
          <>
            <form onSubmit={handleUpdateSaleCommission}>
              <div className="grid grid-cols-12 gap-x-6 [&>div]:lg:col-span-4 [&>div]:xl:col-span-2 [&>div]:col-span-12 bg-white rounded-xl mb-6 p-5 pb-0">
                <div className="mb-5">
                  <label className="font-semibold mb-3 block">Product Name</label>
                  <input
                    readOnly
                    value={saleCommissionData.name}
                    type="text"
                    className="form-control hideNumberArrow"
                  />
                </div>
                <div className="mb-5">
                  <label className="font-semibold mb-3 block">Commission Value</label>
                  <input
                    value={saleCommissionData.value}
                    type="number"
                    onChange={(e) =>
                      setSaleCommissionData((prevState) => ({
                        ...prevState,
                        value: e.target.value,
                      }))
                    }
                    className="form-control hideNumberArrow"
                  />
                </div>
                <div className="mb-5">
                  <label className="font-semibold mb-3 block">Commission Type</label>
                  <select
                    value={saleCommissionData.commisionType}
                    required
                    onChange={(e) =>
                      setSaleCommissionData((prevState) => ({
                        ...prevState,
                        commisionType: e.target.value,
                      }))
                    }
                    className="form-control"
                  >
                    <option value="">Select Type</option>
                    <option value="amount">Amount</option>
                    <option value="percentage">Percentage</option>
                  </select>
                </div>
                <div className="mb-5">
                  <label className="font-semibold mb-3 block">Discount Value</label>
                  <input
                    className="form-control"
                    value={saleCommissionData.discountValue}
                    required
                    onChange={(e) =>
                      setSaleCommissionData((prevState) => ({
                        ...prevState,
                        discountValue: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="mb-5">
                  <label className="font-semibold mb-3 block">&nbsp;</label>
                  {buttonShow && (
                    <button
                      className={`btn-primary p-[1.05rem] ${
                        loading
                          ? "cursor-not-allowed"
                          : " "
                      }`}
                      type="submit"
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </form>
          </>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
}

export default ProductCommissionUpdate;
