import React, { useEffect, useState } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import GernalChartData from "./GernalChartData";
import FormatDate from "Components/Common/FormatDate";
import PosReportApi from "api/reports/posreports";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import PageTitle from "Components/Common/PageTitle";

function AccountsReports() {
  const highSaleProducts = {
    hspName: [],
    hspQty: [],
    hspDate: [],
  };

  const productWiseSale = {
    pwsName: [],
    pwsQty: [],
    pwsDate: [],
  };

  const salesManSale = {
    smName: [],
    smQty: [],
    smDate: [],
  };

  const oldCustomer = {
    type: [],
    count: [],
    date: [],
  };

  const newCustomer = {
    type: [],
    count: [],
    date: [],
  };
  const [active, setActive] = useState("");
  const [highSellingProducts, setHighSellingProducts] =
    useState(highSaleProducts);
  const [productWiseSales, setProductWiseSales] = useState(productWiseSale);
  const [salesManSales, setSalesManSales] = useState(salesManSale);
  const [oldCustomerdata, setOldCustomerdata] = useState(oldCustomer);
  const [newCustomerdata, setNewCustomerdata] = useState(newCustomer);

  const getData = (sDate, eDate) => {
    PosReportApi.highestSalingProduct(sDate, eDate)
      .then((response) => {
        const Names = response.data.map((key) => key.name);
        const qty = response.data.map((key) => key.quantity);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setHighSellingProducts({
          hspName: Names,
          hspQty: qty,
          hspDate: date,
        });
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });

    PosReportApi.ProductWiseSale(sDate, eDate)
      .then((response) => {
        const Names = response.data.map((key) => key.name);
        const qty = response.data.map((key) => key.sales);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setProductWiseSales({
          pwsName: Names,
          pwsQty: qty,
          pwsDate: date,
        });
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });

    PosReportApi.salesmanSaleReport(sDate, eDate)
      .then((response) => {
        const Names = response.data.map((key) => key.username);
        const qty = response.data.map((key) => key.sales);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setSalesManSales({
          smName: Names,
          smQty: qty,
          smDate: date,
        });
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });
    PosReportApi.oldCustomerReport(sDate, eDate)
      .then((response) => {
        const type = response.data.map((key) => key.type_c);
        const count = response.data.map((key) => key.count);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setOldCustomerdata({
          type: type,
          count: count,
          date: date,
        });
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });

    PosReportApi.newCustomerReport(sDate, eDate)
      .then((response) => {
        const type = response.data.map((key) => key.type_c);
        const count = response.data.map((key) => key.count);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setNewCustomerdata({
          type: type,
          count: count,
          date: date,
        });
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });
  };

  const dateFilter = (e) => {
    let startDate = new Date();
    let endDate = new Date();
    const interval = parseInt(e.target.value);
    const unit = e.target.name;
    if (unit === "month") {
      const currentMonth = startDate.getMonth();
      startDate.setMonth(currentMonth - interval);
    } else if (unit === "week") {
      const daysPerWeek = 7;
      const daysInterval = interval * daysPerWeek;
      startDate.setDate(startDate.getDate() - daysInterval);
    }
    getData(FormatDate(startDate), FormatDate(endDate));
  };

  useEffect(() => {
    getData(false, false);
  }, []);

  const handleReset = () => {
    getData(false, false);
  };

  const weekList = [1, 3];
  const monthList = [1, 3, 6];

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <PageTitle pageTitle="POS Reports" />
        <div className="flex justify-center flex-wrap mb-8">
          {weekList.map((week, key) => (
            <button
              className="reports-buttons bg-dark text-white rounded-md hover:bg-blue p-3 px-4 mx-1"
              key={key}
              value={week}
              name="week"
              onClick={dateFilter}
            >
              {week}W
            </button>
          ))}
          {monthList.map((month, key) => (
            <button
              className="reports-buttons bg-dark text-white rounded-md hover:bg-blue p-3 px-4 mx-1"
              key={key}
              value={month}
              name="month"
              onClick={dateFilter}
            >
              {month}M
            </button>
          ))}
          <button
            className="reports-buttons bg-dark text-white rounded-md hover:bg-blue p-3 px-4 mx-1"
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
        <div className="grid grid-cols-12 gap-x-8">
          <div className="md:col-span-6 col-span-12 mb-8">
            <p className="text-20 mb-3 text-center font-semibold">New Customer</p>
            <div className="bg-white rounded-2xl p-5 shadow">
              <div className="chart">
                <GernalChartData
                  dataName={newCustomerdata.type}
                  dataQty={newCustomerdata.count}
                  dataDate={newCustomerdata.date}
                  xAxisTitle="Dates"
                  yAxisTitle="Count"
                  type="bar"
                />
              </div>
            </div>
          </div>
          <div className="md:col-span-6 col-span-12 mb-8">
            <p className="text-20 mb-3 text-center font-semibold">Old Customer</p>
            <div className="bg-white rounded-2xl p-5 shadow">
              <div className="chart">
                <GernalChartData
                  dataName={oldCustomerdata.type}
                  dataQty={oldCustomerdata.count}
                  dataDate={oldCustomerdata.date}
                  xAxisTitle="Dates"
                  yAxisTitle="Count"
                  type="bar"
                />
              </div>
            </div>
          </div>
          <div className="md:col-span-6 col-span-12 mb-8">
            <p className="text-20 mb-3 text-center font-semibold">Highest Selling Products</p>
            <div className="bg-white rounded-2xl p-5 shadow">
              <div className="chart">
                <GernalChartData
                  dataName={highSellingProducts.hspName}
                  dataQty={highSellingProducts.hspQty}
                  dataDate={highSellingProducts.hspDate}
                  xAxisTitle="Dates"
                  yAxisTitle="Quantity"
                  type="bar"
                />
              </div>
            </div>
          </div>
          <div className="md:col-span-6 col-span-12 mb-8">
            <p className="text-20 mb-3 text-center font-semibold"> Top Product Wise Sales</p>
            <div className="bg-white rounded-2xl p-5 shadow">
              <div className="chart">
                <GernalChartData
                  dataName={productWiseSales.pwsName}
                  dataQty={productWiseSales.pwsQty}
                  dataDate={productWiseSales.pwsDate}
                  xAxisTitle="Dates"
                  yAxisTitle="Sales"
                  type="bar"
                />
              </div>
            </div>
          </div>
          <div className="md:col-span-6 col-span-12 mb-8">
            <p className="text-20 mb-3 text-center font-semibold">Top Sales Man Sale</p>
            <div className="bg-white rounded-2xl p-5 shadow">
              <div className="chart">
                <GernalChartData
                  dataName={salesManSales.smName}
                  dataQty={salesManSales.smQty}
                  dataDate={salesManSales.smDate}
                  xAxisTitle="Dates"
                  yAxisTitle="Sales"
                  type="bar"
                />
              </div>
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default AccountsReports;
