import React, { useEffect } from "react";
function Popup({ children, className, setPopup }) {
  useEffect(() => {
      document.body.classList.add('overflow-hidden');
    return function cleanup() {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);
  return (
    <>
      <div className="flex justify-center absolute left-0 right-0 bottom-0 top-0 items-center">
        <div
          className={`bg-white absolute top-0 bottom-0 left-0 right-0 sm:top-auto sm:bottom-auto sm:left-auto sm:right-auto sm:fixed ${className} sm:max-h-[100vh] sm:max-h-[90vh] overflow-auto z-[100]`}
        >
          <div className="sm:h-auto w-full sm:w-auto">
            <div className="flex justify-end border-b border-gray-200 p-2">
              <button
                type="button"
                className="p-[8px] bg-slate-300 text-white rounded-full border"
                onClick={setPopup}
              >
              <img src="/assets/img/cross.svg" width="7" alt="" />
              </button>
            </div>
            <div className="p-4">{children}</div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 bg-black opacity-50 visible z-[9] cursor-pointer" onClick={setPopup}></div>
    </>
  );
}
export default Popup;