import React, { useEffect, useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Table from "Components/Common/GeneralTable";
import { useNavigate } from "react-router-dom";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import Button from "Components/Common/Button";

import { toast } from "react-toastify";

function AllPayroll() {
  const navigate = useNavigate();
  const [dataToShow, setDataToShow] = useState([
    {
      id:"1",
      name:"fe data",
      updated_at:"15/02/2020",
      created_at:"15/02/2020",
    }
  ]);
//   const [loading, setLoading] = useState(true);
  const [popupId, setPopupId] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleCreate = () => {
    // navigate(`/create-allowance`);
  };
  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setPopupId("deleteId");
  };
  const cancelDelete = () => {
    setPopupId("");
    setItemToDelete(null);
  };
  const handleUpdateClick = (itemId) => {
    navigate(`/update-payroll/${itemId}`);
  };
  const handleDetailsClick = (itemId) => {
    // navigate(`/allowances/details/${itemId}`);
  };
  const deleteConfirmed = () => {
    setPopupId("");
    // if (itemToDelete) {
    //   AllowanceApi.deleteAllowanceApi(itemToDelete.id)
    //     .then((response) => {
    //       const newData = dataToShow.filter(
    //         (item) => item.id !== itemToDelete.id
    //       );
    //       setDataToShow(newData);
    //       const Message = response
    //         ? "Record Deleted Successfully From List"
    //         : "An error occurred";
    //       toast.success(`${Message}`);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    //   setItemToDelete(null);
    // }
  };
  const actions = [
    // {
    //   name: "View",
    //   icon: "./assets/icon/view.png",
    //   onClick: (item) => handleDetailsClick(item.id),
    // },
    {
      name: "Edit",
      icon: "./assets/icon/edit.png",
      onClick: (item) => handleUpdateClick(item.id),
    },
    // {
    //   name: "Delete",
    //   color: "red",
    //  icon: "./assets/icon/delete.png",
    //   onClick: handleDeleteClick,
    // },
  ];
//   useEffect(() => {
//     AllowanceApi.allAllowanceApi()
//       .then((res) => {
//         setDataToShow(res.data);
//         setLoading(false);
//       })
//       .catch((error) => {
//         setLoading(false);
//         console.log("error", error);
//       });
//   }, []);
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <ShowComponent condition={popupId === "deleteId"}>
          <Popup setPopup={() => setPopupId("")} className="w-[50%]">
            {itemToDelete && (
              <div className="row text-center mt-4">
                <p className="col-12">
                  Are you sure you want to delete this Payroll?
                </p>
                <Button
                  className="btn btn-danger my-3"
                  onClick={deleteConfirmed}
                >
                  Yes
                </Button>
                <Button
                  className="btn btn-success my-3 ml-2"
                  onClick={cancelDelete}
                >
                  No
                </Button>
              </div>
            )}
          </Popup>
        </ShowComponent>
        <div className="w-full flex mb-4 justify-between">
          <div className="text-24 mb-4 ">
            <h1>All Payrolls</h1>
          </div>
          {/* <div className="w-[14%] flex justify-end mt-2 text-end">
            <button className="btn-primary w-full" onClick={handleCreate}>
              Create Allowance
            </button>
          </div> */}
        </div>
        <Table
          data={dataToShow}
        //   loading={loading}
          actions={actions}
          // startValue={paginationValue.startValue}
        />
      </PagesWrapper>
    </AppWrapper>
  );
}

export default AllPayroll;
