import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function SalesReportInvoices({
  startDate,
  endDate,
  showTime,
  selectedReport,
  selectedBranch,
  reportData,
  reportSummary,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-18 font-semibold text-center mb-4">
          {selectedReport === "sales_by_invoice_report_return"
            ? "Sales Report Invoices - Return"
            : "Sales Report Invoices - Summary"}
        </div>
        <div className="flex justify-between items-end mb-4">
          <div>
            <div className="flex gap-3">
              <div className="font-bold">From Date :</div>
              <div>{FormatDate(startDate)}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">To Date :</div>
              <div>{FormatDate(endDate)}</div>
            </div>
          </div>
          <div>
            <div className="flex gap-3">
              <div className="font-bold">Print Date :</div>
              <div>{FormatDate(new Date())}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Print Time :</div>
              <div>{showTime}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Page # :</div>
              <div>1</div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-center">
            <thead className="border-y-1 border-black">
              <tr>
                <th className="w-[12.50%] p-2">Salesman</th>
                <th className="w-[12.50%] p-2">Total Price</th>
                <th className="w-[12.50%] p-2">Discount</th>
                <th className="w-[12.50%] p-2">Coupon</th>
                {selectedReport === "sales_by_invoice_report_return" && (
                  <th className="w-[12.50%] p-2">Taxable</th>
                )}
                {(selectedReport === "sales_by_invoice_report" ||
                  selectedReport === "sales_by_invoice_report_return") && (
                  <th className="w-[12.50%] p-2">Tax</th>
                )}
                <th className="w-[12.50%] p-2">Bonus</th>
                <th className="w-[12.50%] p-2">Net</th>
              </tr>
            </thead>
            <tr>
              <td colSpan="6" className="p-4 font-bold">
                Branch:{" "}
                {selectedBranch?.name
                  ? capitalizeFirstLetter(selectedBranch?.name)
                  : ""}
              </td>
            </tr>
            <tbody>
              <>
                {reportData?.map((item, index) => (
                  <tr key={index} className="border-black border-t-1">
                    <td class="w-[12.50%] p-2">{item?.salesman}</td>
                    <td class="w-[12.50%] p-2">{item?.total_price}</td>
                    <td class="w-[12.50%] p-2">{item?.discount}</td>
                    <td class="w-[12.50%] p-2">{item?.coupon_discount}</td>
                    {selectedReport === "sales_by_invoice_report_return" && (
                      <td class="w-[12.50%] p-2">{item?.taxable}</td>
                    )}
                    {(selectedReport === "sales_by_invoice_report" ||
                      selectedReport === "sales_by_invoice_report_return") && (
                      <td class="w-[12.50%] p-2">{item?.tax}</td>
                    )}
                    <td class="w-[12.50%] p-2">{item?.salesman_reward}</td>
                    {(selectedReport === "sales_by_invoice_report" ||
                      selectedReport === "sales_by_invoice_report_return") ? (
                      <td class="w-[12.50%] p-2">{item?.net_sale}</td>
                    ) : (
                      <td class="w-[12.50%] p-2">
                        {item?.without_vat_net_sale}
                      </td>
                    )}
                  </tr>
                ))}
                <tr className="border-black border-t-1">
                  <td class="w-[12.50%] p-2"></td>
                  <td class="w-[12.50%] p-2 font-bold">
                    {reportSummary?.total_total_price}
                  </td>
                  <td class="w-[12.50%] p-2 font-bold">
                    {reportSummary?.total_discount}
                  </td>
                  <td class="w-[12.50%] p-2 font-bold">
                    {reportSummary?.total_coupon_discount}
                  </td>
                  {selectedReport === "sales_by_invoice_report_return" && (
                    <td class="w-[12.50%] p-2 font-bold">
                      {reportSummary?.total_taxable}
                    </td>
                  )}
                  {(selectedReport === "sales_by_invoice_report" ||
                    selectedReport === "sales_by_invoice_report_return") && (
                    <td class="w-[12.50%] p-2 font-bold">
                      {reportSummary?.total_tax}
                    </td>
                  )}
                  <td class="w-[12.50%] p-2 font-bold">
                    {reportSummary?.total_salesman_reward}
                  </td>
                  {(selectedReport === "sales_by_invoice_report" ||
                  selectedReport === "sales_by_invoice_report_return") ? (
                    <td class="w-[12.50%] p-2 font-bold">
                      {reportSummary?.total_net_sale}
                    </td>
                  ) : (
                    <td class="w-[12.50%] p-2 font-bold">
                      {reportSummary?.total_without_vat_net_sale}
                    </td>
                  )}
                </tr>
              </>
            </tbody>
          </table>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default SalesReportInvoices;
