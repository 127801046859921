import React, { useState, useEffect } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import CashierApi from "api/cashier/cashier";
import FormatDate from "Components/Common/FormatDate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function ViewTotal() {
  const defaultLoc = useSelector(state => state.auth.currentUser.default_loc);
  const sessionId = useSelector((state) => state.sales?.sessionId);
  const [viewTotalData, setViewTotalData] = useState({
    date: "",
    totalSales: "",
    cashSales: "",
    visaSales: "",
    discount: "",
    reward: "",
    taxAmount: "",
    netSale: "",
  });
  const date = new Date()
  const formattedDate = FormatDate(date)
  
  useEffect(() => {
    if(!sessionId){
      return;
    }
    CashierApi.viewTotal(defaultLoc, formattedDate, sessionId)
      .then((response) => {
        const responseData = response.data;
        setViewTotalData({
          date: responseData.created_at,
          timezone_date: responseData.timezone_date,
          totalSales: responseData.separate_cash_return,
          cashSales: responseData.total_cash_received,
          visaSales: responseData.total_visa_received,
          tabbySales: responseData.total_tabby_received,
          tamaraSales: responseData.total_tamara_received,
          couponDiscount: responseData.coupon_discount,
          discount: responseData.separate_discount,
          reward: responseData.total_reward,
          taxAmount: responseData.total_tax,
          netSale: responseData.net_sale,
          refund:responseData.return
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <PagesWrapper>
      <div>
        <h1 className="font-bold">View Total</h1>
      </div>
      <div className="flex flex-col mt-8">
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold"></label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="date"
            value={FormatDate(viewTotalData?.updated_at || new Date())}
            placeholder="DD/MM/YYYY"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Total Sales:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="totalSales"
            value={viewTotalData.totalSales}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Cash Sales:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="cashSales"
            value={viewTotalData.cashSales}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Visa Sales:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="visaSales"
            value={viewTotalData.visaSales}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Tabby Sales:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="tabbySale"
            value={viewTotalData?.tabbySales}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Tamara Sales:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="tamaraSale"
            value={viewTotalData.tamaraSales}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Coupon Discount:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="discount"
            value={viewTotalData.couponDiscount}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Discount:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="discount"
            value={viewTotalData.discount}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Bonus:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="reward"
            value={viewTotalData.reward}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Reward:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="reward"
            value={"0.00"}
            // value={viewTotalData.reward}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">VAT Amount:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="taxAmount"
            value={viewTotalData.taxAmount}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Return Sales:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="netSale"
            value={viewTotalData.refund}
            placeholder="0.00"
            disabled
          />
        </div>
        <div className="flex items-center mb-2">
          <label className="mr-4 w-24 font-bold">Net Sale:</label>
          <input
            className="border p-2 flex-1 mr-28"
            type="text"
            name="netSale"
            value={viewTotalData.netSale}
            placeholder="0.00"
            disabled
          />
        </div>
      </div>
    </PagesWrapper>
  );
}

export default ViewTotal;
