import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentOpenDropdown: null,
  isSidebarOpen: true, 
  dropdownStates: {
    manageUsers: false,
    product: false,
    order: false,
    purchase: false,
    discountOpen: false,
    isManageCustomersDropdownOpen: false,
    isManageSuppliersDropdownOpen: false,
  },
};

const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setCurrentOpenDropdown: (state, action) => {
      state.currentOpenDropdown = action.payload;
    },
    setSidebarOpen: (state, action) => { 
      state.isSidebarOpen = action.payload;
    },
  },
});

export const { setCurrentOpenDropdown, setSidebarOpen } = routeSlice.actions;
export default routeSlice.reducer;
