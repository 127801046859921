import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AdminSideBar from "Components/Common/AdminSideBar";
import { toast } from "react-toastify";
import Button from "Components/Common/Button";
import Loader from "../../assets/svg/Loader.svg";
import PageTitle from "Components/Common/PageTitle";

function CommissionDetails() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [commissionData, setCommissionData] = useState("");

  useEffect(() => {
    ConfigurationAPI.viewCommission({ id })
      .then((response) => {
        setLoading(false);
        setCommissionData(response?.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [id]);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-commission");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <PageTitle pageTitle={"Invoice Commission Detail"}/>
          <Button
            className="btn-primary"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {loading ? (
          <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
            <img
              src={Loader}
              className="rounded-full m-auto"
              alt="Loading"
            />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-12 gap-x-6">
              <div className="md:col-span-6 col-span-12">
                <div className="bg-white rounded-xl mb-6 p-5">
                    <div className="border-b border-slate-200 pb-4 flex gap-4 w-full justify-start">
                      <div className="font-bold">Commission Type:</div>
                      <div>
                        {
                          commissionData?.sale_commision_configuration
                            ?.commision_type
                        }
                      </div>
                    </div>
                    <div className="border-b border-slate-200 py-4 flex gap-4 w-full justify-start">
                      <div className="font-bold">Commission Value:</div>
                      <div>
                        {commissionData?.sale_commision_configuration?.value}
                      </div>
                    </div>
                    <div className="border-b border-slate-200 py-4 flex gap-4 w-full justify-start">
                      <div className="font-bold">Discount Type:</div>
                      <div>
                        {
                          commissionData?.sale_commision_configuration
                            ?.discount_type
                        }
                      </div>
                    </div>
                    <div className="pt-4 flex gap-4 w-full justify-start">
                      <div className="font-bold">Discount Value:</div>
                      <div>
                        {
                          commissionData?.sale_commision_configuration
                            ?.discount_value
                        }
                      </div>
                    </div>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12">
                <div className="bg-white rounded-xl mb-6 p-5">
                  <table className="w-full bg-white border-t-1 border-r-1 border-slate-200">
                    <thead className="w-full">
                      <tr className="w-full justify-between flex">
                        <th className="py-2 px-4 w-[20%] border-b-1 border-l-1 border-slate-200 flex justify-center">
                          Id
                        </th>
                        <th className="py-2 px-4 w-[80%] border-b-1 border-l-1 border-slate-200 flex justify-center">
                          Location
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {commissionData.sale_commision_configuration.locations.map(
                        (item) => (
                          <tr
                            key={item.location_id}
                            className="w-full justify-between flex"
                          >
                            <td className="py-2 px-4 w-[20%] border-b-1 border-l-1 border-slate-200 flex justify-center">
                              {item.location_id}
                            </td>
                            <td className="py-2 px-4 w-[80%] border-b-1 border-l-1 border-slate-200 flex justify-center">
                              {item.address}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CommissionDetails;
