import React, { useEffect, useState } from "react";
import AdminSideBar from "Components/Common/AdminSideBar";
import AppWrapper from "Components/Routes/AppWrapper";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import OrderApi from "api/posapi/order";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";
import FormateDateTime from "Components/Common/FormateDateTime";
import PageTitle from "Components/Common/PageTitle";

function CashierSession() {
  const [tableData, setTableData] = useState([]);

  const allData = () => {
    OrderApi.allSessions()
      .then((response) => {
        setTableData(response?.data?.cashier_sessions);
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    allData();
  }, []);

  const handleReOpen = (userId) => {
    OrderApi.ReOpenCashier({
      user_id: userId,
    })
      .then((response) => {
        if (response?.data?.message) {
          toast.success(response.data.message);
          allData();
        }
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <PageTitle pageTitle={"Cashier Session"}/>
        <div className="overflow-x-auto bg-white rounded-xl mb-6 p-3">
          <table className="w-full text-center">
            <thead>
              <tr>
                <th className="p-3 py-4 text-blue">ID</th>
                <th className="p-3 py-4 text-blue">Start Time</th>
                <th className="p-3 py-4 text-blue">End Time</th>
                <th className="p-3 py-4 text-blue">User ID</th>
                <th className="p-3 py-4 text-blue">Status</th>
                <th className="p-3 py-4 text-blue">Actions</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length === 0 ? (
                <tr>
                  <td colSpan={9} className="pb-4 text-center">
                    No Data Available
                  </td>
                </tr>
              ) : (
                tableData.map((data, index) => (
                  <tr key={index}>
                    <td className="p-3 py-4 font-medium border-t-1 border-slate-200">{data.id}</td>
                    <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                      {FormateDateTime(data.start_time)}
                    </td>
                    <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                      {FormateDateTime(data.end_time)}
                    </td>
                    <td className="p-3 py-4 font-medium border-t-1 border-slate-200">{data.user_id}</td>
                    <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                      {capitalizeFirstLetter(data.status)}
                    </td>
                    <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                      <Button onClick={() => handleReOpen(data.user_id)} className="text-12 p-1.5">
                        {data.status === "open" ? "Open" : "Re-Open"}
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CashierSession;
