import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function SalesmanReport({
  startDate,
  endDate,
  selectedBranch,
  selectedSalesman,
  salesmanReport,
  showTime,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-18 font-semibold text-center mb-4">
          Sales Report by Salesman
        </div>
        <div className="flex justify-between items-end mb-4">
          <div>
            <div className="flex gap-3">
              <div className="font-bold">From Date :</div>
              <div>{FormatDate(startDate)}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">To Date :</div>
              <div>{FormatDate(endDate)}</div>
            </div>
          </div>
          <div>
            <div className="flex gap-3">
              <div className="font-bold">Print Date :</div>
              <div>{FormatDate(new Date())}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Print Time :</div>
              <div>{showTime}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Page # :</div>
              <div>1</div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-center">
            <thead className="border-y-1 border-black">
              <tr>
                <th className="w-[16.66%] p-2">Salesman</th>
                <th className="w-[16.66%] p-2">Total Price</th>
                <th className="w-[16.66%] p-2">Discount</th>
                <th className="w-[16.66%] p-2">Tax</th>
                <th className="w-[16.66%] p-2">Bonus</th>
                <th className="w-[16.66%] p-2">Net</th>
              </tr>
            </thead>
            <tr>
              <td colSpan="6" className="p-4 font-bold">
                Branch:{" "}
                {selectedBranch?.name
                  ? capitalizeFirstLetter(selectedBranch?.name)
                  : ""}
              </td>
            </tr>
            <tbody>
              {salesmanReport || salesmanReport.length > 0 ? (
                <>
                  <tr className="border-t-1 border-black">
                    <td class="w-[16.66%] p-2">
                      {selectedSalesman?.name
                        ? capitalizeFirstLetter(selectedSalesman?.name)
                        : ""}
                    </td>
                    <td class="w-[16.66%] p-2">
                      {salesmanReport?.sub_total}
                    </td>
                    <td class="w-[16.66%] p-2">
                      {salesmanReport?.discount}
                    </td>
                    <td class="w-[16.66%] p-2">
                      {salesmanReport?.tax}
                    </td>
                    <td class="w-[16.66%] p-2">
                      {salesmanReport?.value}
                    </td>
                    <td class="w-[16.66%] p-2">
                      {salesmanReport?.total_price}
                    </td>
                  </tr>

                  <tr className="w-full border-t-1 border-black">
                    <td class="w-[16.66%] p-2"></td>
                    <td
                      class="w-[16.66%]  p-2 font-bold"
                      style={{ textAlign: "center" }}
                    >
                      {salesmanReport?.sub_total}
                    </td>
                    <td
                      class="w-[16.66%]  p-2 font-bold"
                      style={{ textAlign: "center" }}
                    >
                      {salesmanReport?.discount}
                    </td>
                    <td
                      class="w-[16.66%]  p-2 font-bold"
                      style={{ textAlign: "center" }}
                    >
                      {salesmanReport?.tax}
                    </td>
                    <td
                      class="w-[16.66%]  p-2 font-bold"
                      style={{ textAlign: "center" }}
                    >
                      {salesmanReport?.value}
                    </td>
                    <td
                      class="w-[16.66%] p-2 font-bold"
                      style={{ textAlign: "end" }}
                    >
                      {salesmanReport?.total_price}
                    </td>
                  </tr>

                  <tr className="w-full">
                    <td class="w-[16.66%] p-2"></td>
                    <td
                      class="w-[16.66%] p-2 font-bold"
                      style={{ textAlign: "center" }}
                    >
                      {salesmanReport?.sub_total}
                    </td>
                    <td
                      class="w-[16.66%] p-2 font-bold"
                      style={{ textAlign: "center" }}
                    >
                      {salesmanReport?.discount}
                    </td>
                    <td
                      class="w-[16.66%] p-2 font-bold"
                      style={{ textAlign: "center" }}
                    >
                      {salesmanReport?.tax}
                    </td>
                    <td
                      class="w-[16.66%] p-2 font-bold"
                      style={{ textAlign: "center" }}
                    >
                      {salesmanReport?.value}
                    </td>
                    <td
                      class="w-[16.66%] p-2 font-bold"
                      style={{ textAlign: "end" }}
                    >
                      {salesmanReport?.total_price}
                    </td>
                  </tr>
                </>
               ) : (
                <tr className="pt-4 text-center border-t-1 border-black">
                  <td colSpan="6" className="p-4 font-bold">
                    No Data Found
                  </td>
                </tr>
              )} 
            </tbody>
          </table>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default SalesmanReport;
