import React from "react";

function GeneralSelectDropDown({ title, value, onChange, options, className, name, required }) {
  if (!options) {
    return null;
  }

  return (
    title === "Show per page" ? (
      <div className="">
        <label className="font-semibold mb-3 block">{title}</label>
        <select
          className={`form-control ${className}`}
          value={value}
          name={name}
          onChange={onChange}
          required={required}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    ) : title === "Payment Status" ?(
      <div className={className}>
        <label className="font-semibold mb-3 block">{title}</label>
        <select
          className="form-control" // Add className here
          value={value}
          name={name}
          onChange={onChange}
          required={required}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    ):(
      <div className="form-group">
        <label className="font-semibold mb-3 block">{title}</label>
        <select
          className={className}
          value={value}
          name={name}
          onChange={onChange}
          required={required}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    ) 
  );
}

export default GeneralSelectDropDown;
