import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function InvoicesList({
  startDate,
  endDate,
  selectedReport,
  reportData,
  reportSummary,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-18 font-semibold text-center mb-4">
          {selectedReport ? capitalizeFirstLetter(selectedReport) : ""}
        </div>
        <div className="text-18 font-semibold text-center mb-4">
          From Date : {FormatDate(startDate)} To Date : {FormatDate(endDate)}
        </div>
        <div className="flex justify-end items-end mb-4">
          <div>
            <div className="flex gap-3">
              <div className="font-bold">Print Date :</div>
              <div>{FormatDate(new Date())}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Page # :</div>
              <div>1</div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-center">
            <thead className="border-y-1 border-black">
              <tr>
                <th className="w-[10%] p-2">B #</th>
                <th className="w-[10%] p-2">Invoice#</th>
                <th className="w-[10%] p-2">Invoice Date</th>
                <th className="w-[10%] p-2">Salesman</th>
                <th className="w-[10%] p-2">Order #</th>
                <th className="w-[10%] p-2">Bouns</th>
                <th className="w-[10%] p-2">Subtotal</th>
                <th className="w-[10%] p-2">Discount</th>
                <th className="w-[10%] p-2">Tax</th>
                <th className="w-[10%] p-2">Total</th>
              </tr>
            </thead>
            <tbody>
              {reportData?.map((item, index) => (
                <tr
                  key={index}
                  className="w-full border-b-1 border-black"
                >
                  <td className="w-[10%] p-2">{item?.location_id}</td>
                  <td className="w-[10%] p-2">{item?.invoice_no}</td>
                  <td className="w-[10%] p-2">
                    {item.updated_at ? FormatDate(item.updated_at) : ""}
                  </td>
                  <td className="w-[10%] p-2">{item?.salesman_name}</td>
                  <td className="w-[10%] p-2">{"Local"}</td>
                  <td className="w-[10%] p-2">{item?.salesman_reward}</td>
                  <td className="w-[10%] p-2">{item?.total_sale_price}</td>
                  <td className="w-[10%] p-2">{item?.total_discount}</td>
                  <td className="w-[10%] p-2">{item?.total_tax}</td>
                  <td className="w-[10%] p-2">{item?.net_sale}</td>
                </tr>
              ))}

              <tr>
                <td className="w-[10%] p-2">{""}</td>
                <td className="w-[10%] p-2">{""}</td>
                <td className="w-[10%] p-2">{""}</td>
                <td className="w-[10%] p-2">{""}</td>
                <td className="w-[10%] p-2">{""}</td>
                <td className="w-[10%] p-2 font-bold">
                  {reportSummary?.total_salesman_reward}
                </td>
                <td className="w-[10%] p-2 font-bold">
                  {reportSummary?.total_total_price}
                </td>
                <td className="w-[10%] p-2 font-bold">
                  {reportSummary?.total_discount}
                </td>
                <td className="w-[10%] p-2 font-bold">
                  {reportSummary?.total_tax}
                </td>
                <td className="w-[10%] p-2 font-bold">
                  {reportSummary?.total_net_sale}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default InvoicesList;
