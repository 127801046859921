import Button from "Components/Common/Button";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminSideBar from "Components/Common/AdminSideBar";
import { toast } from "react-toastify";
import Loader from "../../assets/svg/Loader.svg";
import PageTitle from "Components/Common/PageTitle";

function CommissionUpdate() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [buttonShow, setButtonShow] = useState(true);
  const navigate = useNavigate();

  const [saleCommissionData, setSaleCommissionData] = useState({
    value: "",
    commisionType: "",
    discountValue: "",
  });

  useEffect(() => {
    if (window.location.pathname.includes("commission/details")) {
      setButtonShow(false);
    }
    ConfigurationAPI.viewCommission({ id })
      .then((response) => {
        setLoading(false);
        const Data = response.data.sale_commision_configuration;
        setSaleCommissionData({
          value: Data.value,
          commisionType: Data.commision_type,
          discountValue: Data.discount_value,
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleUpdateSaleCommission = (e) => {
    e.preventDefault();
    if (
      saleCommissionData.commisionType === "percentage" &&
      parseFloat(saleCommissionData.value) > 100
    ) {
      setLoading(false);
      toast.error("Percentage value cannot be greater than 100");
      return;
    }
    setLoading(true);
    ConfigurationAPI.updateCommission(id, {
      sale_commision_configuration: {
        value: parseFloat(saleCommissionData.value),
        commision_type: saleCommissionData.commisionType,
        discount_thresh: parseFloat(saleCommissionData.discountValue),
        discount_thresh_type: "percentage",
      },
    })
      .then((response) => {
        setLoading(false);
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`Success:${Message}`);
        setTimeout(() => {
          if (!response.data.error) {
            navigate("/all-commission");
          }
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response.data.message);
      });
  };

  const handleBack = () => {
    navigate("/all-commission");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <PageTitle pageTitle={"Update Invoice Commission"}/>
        <div className="flex justify-end mb-5">
          <Button
            className="btn-primary"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {loading ? (
          <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
            <img src={Loader} className="rounded-full m-auto" alt="Loading" />
          </div>
        ) : (
          <>
            <div className="bg-white rounded-xl mb-6 p-5 pb-0">
              <form onSubmit={handleUpdateSaleCommission}>
                <div className="grid grid-cols-12 gap-x-6">
                  <div className="md:col-span-3 mb-5 col-span-12">
                    <div className="font-semibold mb-3 block">Commission Value</div>
                    <div>
                      <input
                        value={saleCommissionData.value}
                        type="number"
                        onChange={(e) =>
                          setSaleCommissionData((prevState) => ({
                            ...prevState,
                            value: e.target.value,
                          }))
                        }
                        className="form-control hideNumberArrow"
                      />
                    </div>
                  </div>
                  <div className="md:col-span-3 mb-5 col-span-12">
                    <div className="font-semibold mb-3 block">Commission Type</div>
                    <div>
                      <select
                        value={saleCommissionData.commisionType}
                        required
                        onChange={(e) =>
                          setSaleCommissionData((prevState) => ({
                            ...prevState,
                            commisionType: e.target.value,
                          }))
                        }
                        className="form-control"
                      >
                        <option value="">Select Type</option>
                        <option value="amount">Amount</option>
                        <option value="percentage">Percentage</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:col-span-3 mb-5 col-span-12">
                    <div className="font-semibold mb-3 block">Discount Value</div>
                    <div>
                      <input
                        className="form-control"
                        value={saleCommissionData.discountValue}
                        required
                        onChange={(e) =>
                          setSaleCommissionData((prevState) => ({
                            ...prevState,
                            discountValue: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="md:col-span-3 mb-5 col-span-12">
                    <div className="font-semibold mb-3 hidden md:block">&nbsp;</div>
                    {buttonShow && (
                      <button
                        className={`btn-primary p-[1.05rem] ${
                          loading
                            ? "cursor-not-allowed"
                            : ""
                        }`}
                        type="submit"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CommissionUpdate;
