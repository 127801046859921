import React from "react";
import FormatDate from "./FormatDate";
import Button from "./Button";
import { useState, useEffect } from "react";
import ShowComponent from "Components/ShowComponent";
import Popup from "./Pages/Popup";
import Loader from "../../assets/svg/Loader.svg";
import CircularViews from "Components/Pos/Circular/CircularModal";

const TableDetails = ({ data, loading, header }) => {
  const capitalizeAndReplaceUnderscores = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [popupId, setPopupId] = useState(false);

  const changeHandler = (value) => {
    if (value instanceof File || value instanceof Blob) {
      setSelectedFile(value);
      setPopupId("filePreview");
      setPreview(URL.createObjectURL(value));
    } else {
      setPreview(value);
      setPopupId("filePreview");
    }
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const renderTopImage = () => {
    const imageKeys = [
      "avatar_url",
      "profile_photo_url",
      "profile_photo",
      "file_url",
    ];
    const imageUrl = Object.entries(data).find(([key, value]) =>
      imageKeys.includes(key)
    )?.[1];
    if (imageUrl) {
      return (
        <div className="text-center p-4">
          <img
            src={imageUrl}
            alt="Avatar"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <ShowComponent condition={popupId === "filePreview"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[50%]">
          <CircularViews url={preview} />
        </Popup>
      </ShowComponent>
      <div className="w-full">
        {loading ? (
          <p className={`text-center ${loading ? "loader" : ""}`}>
            <img src={Loader} className="m-auto" alt="" />
          </p>
        ) : data ? (
          <div className="bg-white rounded-2xl">
            {renderTopImage()}
            <div className="px-4 py-2">
              <div className="w-full">
                {header &&(
                <div className="w-full justify-between flex">
                  <div className="font-weight-bold text-left p-4 border-b">
                    Heading
                  </div>
                  <div className="font-weight-bold text-right p-4 border-b">
                    Description
                  </div>
                </div>
                )}
                <div className="w-full">
                  {Object.entries(data).map(([key, value]) => (
                    <div key={key} className="border-b last:border-b-0 justify-between flex">
                      <div className="font-bold flex p-4">
                        {key ==="username_email" ? "Username/Email" : capitalizeAndReplaceUnderscores(key)}
                      </div>
                      <div className="p-4">
                        {key === "attachment" ? (
                          <>
                            <Button
                              className="w-[100px]"
                              onClick={() => changeHandler(value)}
                            >
                              View
                            </Button>
                          </>
                        ) : [
                            "created_at",
                            "invitation_sent_at",
                            "invitation_accepted_at",
                            "updated_at",
                            "issue_date",
                            "expiry_date",
                            "joining_date",
                            "termination_date",
                            "warranty_expiry_date",
                            "id_expiry_date",
                            "probation_end",
                            "purchase_date",
                            "dob",
                            "start_date",
                            "assign_date",
                            "return_date",
                            "end_date",
                          ].includes(key) ? (
                          <div className=" w-full flex">
                            {value ? FormatDate(value) : ""}
                          </div>
                        ) : [
                            "default",                         
                            "work_permit",
                            "invitation_accepted",
                            "track_attendance",
                            "required",
                            "issue_date",
                            "expiry_date",
                            "document_no",
                            "temporary_contract",
                            // "ticket_entitled",
                          ].includes(key) ? (
                          <div>{value ? "Yes" : "No"}</div>
                        ) : (
                          <div>{value}</div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">No data found.</div>
        )}
      </div>
    </>
  );
};

export default TableDetails;
