import React from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminSideBar from "Components/Common/AdminSideBar";
import { toast } from "react-toastify";
import Button from "Components/Common/Button";
import Loader from "../../assets/svg/Loader.svg";
import PageTitle from "Components/Common/PageTitle";

function ProductCommissionDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [commissionData, setCommissionData] = useState("");

  useEffect(() => {
    ConfigurationAPI.viewProductCommission({ id })
      .then((response) => {
        setLoading(false);
        setCommissionData(response?.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          toast.error("Something Went Wrong");
        }
      });
  }, []);

  const handleBack = () => {
    navigate("/all-commission");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <PageTitle pageTitle={"Product Commission Detail"}/>
          <Button
            className="btn-primary"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>

        {loading ? (
          <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
            <img
              src={Loader}
              className="rounded-full m-auto"
              alt="Loading"
            />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-12 gap-x-6">
              <div className="md:col-span-6 col-span-12">
                <div className="bg-white rounded-xl mb-6 p-5">
                  <div className="border-b border-slate-200 pb-4 flex gap-4 w-full justify-start">
                    <div className="font-bold">Commission Type:</div>
                    <div>
                      {
                        commissionData?.sale_product_commision_configuration
                          ?.commision_type
                      }
                    </div>
                  </div>
                  <div className="border-b border-slate-200 py-4 flex gap-4 w-full justify-start">
                    <div className="font-bold">Commission Value:</div>
                    <div>
                      {commissionData?.sale_product_commision_configuration?.value}
                    </div>
                  </div>
                  <div className="border-b border-slate-200 py-4 flex gap-4 w-full justify-start">
                    <div className="font-bold">Discount Type:</div>
                    <div>
                      {
                        commissionData?.sale_product_commision_configuration
                          ?.discount_type
                      }
                    </div>
                  </div>
                  <div className="pt-4 flex gap-4 w-full justify-start">
                    <div className="font-bold">Discount Value:</div>
                    <div>
                      {
                        commissionData?.sale_product_commision_configuration
                          ?.discount_value
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12">
                <div className="overflow-x-auto bg-white rounded-xl mb-6 p-3">
                  <table className="w-full text-center">
                    <thead>
                      <tr>
                        <th className="p-3 py-4 text-blue">
                          Id
                        </th>
                        <th className="p-3 py-4 text-blue">
                          Location
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {commissionData.sale_product_commision_configuration.locations.map(
                        (item) => (
                          <tr
                            key={item.location_id}>
                            <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                              {item.location_id}
                            </td>
                            <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                              {item.address}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
}

export default ProductCommissionDetail;
