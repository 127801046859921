import Table from "Components/Common/GeneralTable";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import ConfigurationAPI from "api/configuration/configuration";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CreateCommission from "./CreateCommission";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import { useDispatch, useSelector } from "react-redux";
import { popUpId, clearTable } from "store/SaleCommission";
import { toast } from "react-toastify";
import Pagination from "Components/Common/Pagination";
import Button from "Components/Common/Button";
import PageTitle from "Components/Common/PageTitle";

function AllCommission() {
  const saleCommissionData = useSelector(
    (state) => state.saleCommission.saleCommissionData
  );
  const dispatch = useDispatch();
  const setPopupId = useSelector((state) => state.saleCommission.popUp);
  const [invoiceData, setInvoiceData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [popup, setPopup] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState("20");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });
  
  useEffect(() => {
    setLoading(true);
      fetchData();
      fetchComissionData();
  }, [pageNumber,refresh, perPage]);


  const fetchData = () => {
    ConfigurationAPI.allProductComissionApi(
      pageNumber,
      perPage,
    )
      .then((response) => {
        const filterData = response.data.sale_product_commision_configurations.map((item) => {
          const {
            locations,
            ...filterData
          } = item;
          return filterData;
        });
        setProductData(filterData);
        setTotalPages(response.data.total_pages);
        setPaginationValue({
          ...paginationValue,
          totalCount: response.data.total_count,
          startValue: response.data.starting_value,
          endValue: response.data.ending_value,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  const fetchComissionData = () => {
    ConfigurationAPI.allCommission()
    .then((response) => {
      const filterData = response.data.sale_commision_configurations.map((item) => {
        const {
          locations,
          ...filterData
        } = item;
        return filterData;
      });
      setInvoiceData(filterData);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  const navigate = useNavigate();
  const handleSaleCommissionDetails = (itemId) => {
    navigate(`/commission/details/${itemId}`);
  };
  const handleSaleCommissionUpdate = (itemId) => {
    navigate(`/commission/edit/${itemId}`);
  };
  const handleProductCommissionDetails = (itemId) => {
    navigate(`/productcommission/details/${itemId}`);
  };
  const handleProductCommissionUpdate = (itemId) => {
    navigate(`/productcommission/edit/${itemId}`);
  };
  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setPopup("invoiceCommissionDelete");
  };

  const handleDeleteProduct = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setPopup("productCommissionDelete");
  };

  const deleteConfirmed = () => {
    setPopup("");
    if (itemToDelete) {
      ConfigurationAPI.DeleteInvoiceCommission(itemToDelete.id)
        .then((response) => {
          const Message = response
            ? response.data.message
            : "An error occurred";
          toast.success(`Success:${Message}`);
          const newData = invoiceData.filter(
            (item) => item.id !== itemToDelete.id
          );
          setInvoiceData(newData);
        })
        .catch((error) => {
          toast.error(error?.response.data.message);
          console.log(error);
        });
      setItemToDelete(null);
    }
  };

  const deleteProductConfirmed = () => {
    setPopup("");
    if (itemToDelete) {
      ConfigurationAPI.DeleteProductCommission(itemToDelete.id)
        .then((response) => {
          const Message = response
            ? response.data.message
            : "An error occurred";
          toast.success(`Success:${Message}`);
          const newData = productData.filter(
            (item) => item.id !== itemToDelete.id
          );
          setProductData(newData);
        })
        .catch((error) => {
          toast.error(error?.response.data.message);
          console.log(error);
        });
      setItemToDelete(null);
    }
  };
  const cancelDelete = () => {
    setPopup("");
    setItemToDelete(null);
  };

  const actions = [
    {
      name: "View",
      icon: "./assets/icon/view.png",
      onClick: (item) => handleSaleCommissionDetails(item.id),
    },
    {
      name: "Edit",
      icon: "./assets/icon/edit.png",
      onClick: (item) => handleSaleCommissionUpdate(item.id),
    },
    {
      name: "Delete",
      icon: "./assets/icon/delete.png",
      onClick: handleDeleteClick,
    },
  ];
  const actionsProductCommission = [
    {
      name: "View",
      icon: "./assets/icon/view.png",
      onClick: (item) => handleProductCommissionDetails(item.id),
    },
    {
      name: "Edit",
      icon: "./assets/icon/edit.png",
      onClick: (item) => handleProductCommissionUpdate(item.id),
    },
    {
      name: "Delete",
      icon: "./assets/icon/delete.png",
      onClick: handleDeleteProduct,
    },
  ];
  const handleSaleCommissionCreate = (e) => {
    const { name } = e.target;
    if (name === "product") {
      setPopup("");
    } else {
      dispatch(clearTable());
      setPopup("");
     dispatch(popUpId(""))
    }
  };
  const handleComfirm=()=>{
    if(saleCommissionData.comissionType){  
      setPopup("commission");
    }else{
      dispatch(popUpId(""))
    }
  }
  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <ShowComponent condition={popup === "commission"}>
        <Popup setPopup={() => setPopup("")} className="w-[35%] z-40 mt-5">
          <div className="row text-center">
            <p className="mb-4">
              Are you sure you want to close Create Commission
            </p>
            <button
              className="btn btn-danger bg-red mb-5  text-white p-2 mt-2 rounded-md"
              name="inovice"
              onClick={handleSaleCommissionCreate}
            >
              Yes
            </button>
            <button
              className="btn btn-success bg-green mb-5 ml-2  text-white p-2 mt-2 rounded-md"
              name="product"
              onClick={handleSaleCommissionCreate}
            >
              No
            </button>
          </div>
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popup === "invoiceCommissionDelete"}>
        <Popup setPopup={() => setPopup("")} className="w-full sm:w-[410px]">
          {itemToDelete && (
            <div className="w-full flex flex-col text-center">
              <div className="mb-3">
                Are you sure you want to delete this Commission?
              </div>
              <div className="flex justify-center">
              <Button className="bg-green text-white p-2 mx-1 mt-1 rounded-md" onClick={deleteConfirmed}>
                Yes
              </Button>
              <Button
                className="bg-red text-white p-2 mx-1 mt-1 rounded-md"
                onClick={cancelDelete}
              >
                No
              </Button>
              </div>
            </div>
          )}
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popup === "productCommissionDelete"}>
        <Popup setPopup={() => setPopup("")} className="w-full sm:w-[410px]">
          {itemToDelete && (
            <div className="w-full flex flex-col text-center">
              <p className="mb-3">
                Are you sure you want to delete this Commission?
              </p>
              <div className="flex justify-center">
              <Button
                className="btn-primary bg-green text-white p-2 mx-1 mt-1 rounded-md"
                onClick={deleteProductConfirmed}
              >
                Yes
              </Button>
              <Button
                className="btn-primary bg-red text-white p-2 mx-1 mt-1 rounded-md"
                onClick={cancelDelete}
              >
                No
              </Button>
              </div>
            </div>
          )}
        </Popup>
      </ShowComponent>
      <PagesWrapper>
        <PageTitle pageTitle={"All Commissions"}/>
        <div className="flex justify-end mb-5">
          <button
            className="btn-primary"
            type="button"
            name="createCommission"
            onClick={() => dispatch(popUpId("createCommission"))}
          >
            Create Commission
          </button>
        </div>
        <div className="text-20 mb-4 text-center font-semibold">
          Invoice Commission
        </div>
        <div className="mb-8">
          <Table data={invoiceData} loading={loading} actions={actions} />
        </div>
        <ShowComponent condition={setPopupId === "createCommission"}>
          <Popup setPopup={handleComfirm} className="w-full sm:w-[60%]">
            <CreateCommission 
            setRefresh = {setRefresh} 
            fetchData = {fetchData}
            fetchComissionData = {fetchComissionData}
            />
            <ShowComponent condition={popup === "commission"}>
              <Popup setPopup={() => setPopup("")} className="w-full sm:w-[410px]">
                <div className="text-center">
                  <p className="mb-2">
                    Are you sure you want to close Create Commission
                  </p>
                  <button
                    className="bg-green mx-1 text-white p-2 mt-2 rounded-md"
                    name="inovice"
                    onClick={handleSaleCommissionCreate}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-red mx-1 text-white p-2 mt-2 rounded-md"
                    name="product"
                    onClick={handleSaleCommissionCreate}
                  >
                    No
                  </button>
                </div>
              </Popup>
            </ShowComponent>
          </Popup>
        </ShowComponent>
        <div className="text-20 mb-4 text-center font-semibold">
          Per Product Commission
        </div>
        <Table
          data={productData}
          loading={loading}
          actions={actionsProductCommission}
        />
          {productData.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
      </PagesWrapper>
    </AppWrapper>
  );
}
export default AllCommission;
