import React, { useState } from "react";
import AppWrapper from "Components/Routes/AppWrapper";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import AdminSideBar from "Components/Common/AdminSideBar";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";

function CreateCard({ contentOnly = false, setPopupId, fetchCards }) {
  const [cardName, setCardName] = useState("");
  const [image, setImage] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleCreateCard = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("card[img]", image);
    formData.append("card[name]", cardName);
    ConfigurationAPI.createCard(formData)
      .then((response) => {
        const message = response?.data?.message;
        const error = response?.data?.error;
        if (message && error) {
          toast.warning(message + " " + error);
        } else {
          toast.success(response.data.message);
        }
        setPopupId("");
        fetchCards();
        setCardName("");
        setImage("");
      })
      .catch((error) => {
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-20 mb-4 text-center font-semibold">Create Card</div>
        <form onSubmit={handleCreateCard}>
          <div className="w-full">
            <div className="mb-5">
              <label className="font-semibold mb-3 block" htmlFor="cardName">Card Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Card Name"
                value={cardName}
                required
                onChange={(e) => setCardName(e.target.value)}
              />
            </div>
            <div className="mb-5">
              <label className="font-semibold mb-3 block" htmlFor="uploadImage">Upload Image</label>
              <input
                type="file"
                className="w-full p-4 mt-2 bg-gray-200 form-control"
                accept="image/*"
                required
                onChange={handleImageUpload}
              />
            </div>
            <div className="text-center">
              <Button type="submit">Create Card</Button>
            </div>
          </div>
        </form>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateCard;
