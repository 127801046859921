import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";
import ConfigurationAPI from "api/configuration/configuration";
import DiscountAPI from "api/posapi/discount";
import LocationsAPI from "api/location/location";
import Select from "react-select";

import {
  setSaleCommissionData,
  selectedCategoryOptionName,
  addItemToTable,
  filterItem,
  updateItemPercentage,
  updateItemValueType,
  clearTable,
  removeItemFromTable,
  popUpId,
} from "store/SaleCommission";

function CreateCommission({ setRefresh, fetchData, fetchComissionData }) {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [discountThresh, setDiscountThresh] = useState("");
  const selectInputRef = useRef();

  useEffect(() => {
    LocationsAPI.allLocationsAPI()
      .then((response) => {
        setLocations(response.data.locations);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const locationsToCreate = selectedLocation.map(({ value }) => value);

  const dispatch = useDispatch();
  const saleCommissionData = useSelector(
    (state) => state.saleCommission.saleCommissionData
  );
  const filteredProducts = useSelector(
    (state) => state.saleCommission.filteredProducts
  );
  const tableData = useSelector((state) => state.saleCommission.tableData);
  const selectedCategoryOption = useSelector(
    (state) => state.saleCommission.selectedCategoryOption
  );
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [product, setProduct] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [popupId, setPopupId] = useState("");

  const handleCreateSaleCommission = (e) => {
    setLoading(true);
    e.preventDefault();
    if (saleCommissionData.comissionType === "inovice") {
      ConfigurationAPI.CreateCommission({
        sale_commision_configuration: {
          value: parseInt(saleCommissionData?.Percentage),
          commision_type: saleCommissionData?.valueType,
          location_ids: locationsToCreate,
          discount_thresh: parseInt(discountThresh),
          discount_thresh_type: "percentage",
        },
      })
        .then((res) => {
          fetchComissionData();
          setLoading(false);
          toast.success(res?.data.message);
          setRefresh(true);
          dispatch(clearTable());
          dispatch(popUpId());
          selectInputRef.current.clearValue();
        })
        .catch((error) => {
          setLoading(false);
          const errorMessage = error?.response?.data.message
            ? error?.response.data.message
            : "An error occurred";
          if (error?.response?.data?.message) {
            toast.error(`Invoice Commmission Already Created`);
          }
        });
    } else {
      const productCommission = tableData.map((product) => ({
        product_id: parseInt(product.product_id, 10),
        commision_type: product.commission?.valueType,
        value: parseInt(product.commission?.Percentage, 10),
      }));

      ConfigurationAPI.productComission({
        sale_product_commision_configuration: {
          commisions: productCommission,
          location_ids: locationsToCreate,
          discount_thresh: parseInt(discountThresh),
          discount_thresh_type: "percentage",
        },
      })
        .then((res) => {
          fetchData();
          toast.success(res.data.message);
          setRefresh(true);
          dispatch(clearTable());
          dispatch(popUpId());
        })
        .catch((error) => {
          const errorMessage = error.response?.data.message
            ? error.response.data.message
            : "An error occurred";
          toast.error(`Error: ${errorMessage}`);
        });
    }
  };

  useEffect(() => {
    DiscountAPI.productsAvailable()
      .then((res) => {
        const uniqueCategories = Array.from(
          new Set(res.data.map((item) => item.category_name))
        );
        setCategories(uniqueCategories);
        setProduct(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const inputOnChange = (e) => {
    const { name, value } = e.target;
    if (tableData.length || saleCommissionData.valueType) {
      setPopupId("cancelCommission");
      dispatch(
        setSaleCommissionData({
          ...saleCommissionData,
          inputValue: value,
        })
      );
    } else {
      dispatch(
        setSaleCommissionData({
          ...saleCommissionData,
          [name]: value,
        })
      );
    }
  };
  const addProductToTable = (product) => {
    const productExistsInTable = tableData.some(
      (item) => item.product_id === product.product_id
    );

    if (!productExistsInTable) {
      const updatedProduct = {
        product_id: product.product_id,
        product_name: product.product_name || product.label,
        commission: {
          Percentage: saleCommissionData.Percentage || 0,
          valueType: saleCommissionData.valueType || "percentage",
        },
      };

      dispatch(addItemToTable([...tableData, updatedProduct]));
    } else {
      dispatch(
        removeItemFromTable({
          product_id: product.product_id,
        })
      );
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      const updatedProducts = filteredProducts.map((product) => ({
        ...product,
        commission: {
          Percentage: saleCommissionData?.Percentage || 0,
          valueType: saleCommissionData?.valueType || "percentage",
        },
      }));
      dispatch(addItemToTable(updatedProducts));
      setSelectAll(false);
    } else {
      dispatch(addItemToTable([]));
      setSelectAll(true);
    }
  };
  useEffect(() => {
    if (!selectedCategoryOption || selectedCategoryOption.length === 0) {
      dispatch(filterItem([]));
      setSelectAll(true);
    }
  }, [selectedCategoryOption]);

  const handleCommissionChange = (productId, value) => {
    dispatch(
      updateItemPercentage({ product_id: productId, Percentage: value })
    );
  };

  const handleTypeChange = (productId, value) => {
    dispatch(updateItemValueType({ product_id: productId, valueType: value }));
  };

  const handleCategoryChange = (selectedOptions) => {
    const selectedLabels = selectedOptions?.map(({ label, value }) => ({
      label,
      value,
    }));
    const filteredData = product.filter((item) =>
      selectedLabels.some((selected) => selected.label === item.category_name)
    );
    dispatch(selectedCategoryOptionName(selectedLabels));
    dispatch(filterItem(filteredData));
  };

  const handleSaleCommissionCreate = (e) => {
    const { name } = e.target;
    if (name === "product") {
      setPopupId("");
    } else {
      const value = saleCommissionData.inputValue;
      dispatch(clearTable());
      dispatch(
        setSaleCommissionData({
          ...saleCommissionData,
          valueType: "",
          Percentage: "",
          comissionType: value,
        })
      );
      setPopupId("");
    }
  };

  return (
    <div className="sm:min-h-[60vh]">
      <ShowComponent condition={popupId === "cancelCommission"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[410px]">
          <div className="row text-center">
            <p className="mb-3">
              Are you sure you want to change Commission Type
            </p>
            <button
              className="bg-green text-white p-2 rounded-md mx-1"
              name="inovice"
              onClick={handleSaleCommissionCreate}
            >
              Yes
            </button>
            <button
              className="bg-red text-white p-2 rounded-md mx-1"
              name="product"
              onClick={handleSaleCommissionCreate}
            >
              No
            </button>
          </div>
        </Popup>
      </ShowComponent>
      <form onSubmit={handleCreateSaleCommission}>
        <div className="grid grid-cols-12 gap-x-6 [&>div]:lg:col-span-4 [&>div]:xl:col-span-3 [&>div]:col-span-12 rounded-xl">
          <div className="mb-4">
            <label className="font-semibold mb-3 block">Commission Type</label>
            <select
              className="form-control"
              name="comissionType"
              value={saleCommissionData.comissionType}
              onChange={inputOnChange}
              required
            >
              <option value="">Select Commission Type</option>
              <option value="inovice">Invoice Commission</option>
              <option value="product">Per Product Commission</option>
            </select>
          </div>
          <ShowComponent
            condition={saleCommissionData.comissionType === "inovice"}
          >
            <div className="mb-4">
              <label className="font-semibold mb-3 block">Value Type</label>
              <select
                className="form-control"
                name="valueType"
                value={saleCommissionData.valueType}
                onChange={inputOnChange}
                required
              >
                <option value="">Please Select Value Type</option>
                <option value="percentage">Percentage</option>
                <option value="amount">Amount</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="font-semibold mb-3 block">Commission Value</label>
              <input
                className="form-control"
                type="text"
                name="Percentage"
                placeholder="Enter Commission Value"
                required
                value={saleCommissionData.Percentage}
                onChange={(e) =>
                  dispatch(
                    setSaleCommissionData({
                      ...saleCommissionData,
                      Percentage: e.target.value,
                    })
                  )
                }
              />
            </div>
          </ShowComponent>
          <ShowComponent
            condition={
              saleCommissionData.comissionType === "inovice" ||
              saleCommissionData.comissionType === "product"
            }
          >
            <div className="mb-4">
              <label className="font-semibold mb-3 block">Discount Value</label>
              <input
                className="form-control hideNumberArrow"
                type="number"
                placeholder="Enter Value Less Than Equal"
                value={discountThresh}
                min={0}
                required
                onChange={(e) => setDiscountThresh(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="font-semibold mb-3 block">Location</label>
              <Select
                classNames={{
                  control: () => "form-control overflow-auto p-[0.375rem] border border-slate-200",
                }}
                required
                ref={selectInputRef}
                value={selectedLocation.map(({ value, label }) => ({
                  value,
                  label,
                }))}
                onChange={(selectedOptions) => {
                  setSelectedLocation(selectedOptions);
                }}
                options={locations.map((value) => ({
                  value: value.id,
                  label: value.address,
                }))}
                isSearchable={true}
                isMulti={true}
              />
            </div>
          </ShowComponent>
          <div className="">
            <label class="font-semibold mb-3 hidden lg:block">&nbsp;</label>
            <ShowComponent
              condition={saleCommissionData.comissionType === "inovice"}
            >
              <button
                  className={`p-[1.05rem] btn-primary ${
                    loading && "cursor-not-allowed"
                  }`}
                  type="submit"
                >
                  Create Commission
                </button>
            </ShowComponent>
          </div>
        </div>
        <ShowComponent
          condition={saleCommissionData.comissionType === "product"}
        >
          <div className="grid grid-cols-12 gap-x-6 [&>div]:lg:col-span-4 [&>div]:xl:col-span-3 [&>div]:col-span-12 bg-white rounded-xl mb-6">
            <div className="mb-4">
              <label className="font-semibold mb-3 block">Category</label>
              <Select
                required
                classNames={{
                  control: () => "form-control overflow-auto p-[0.375rem] border border-slate-200",
                }}
                isMulti={true}
                value={selectedCategoryOption}
                onChange={handleCategoryChange}
                options={categories.map((category) => ({
                  value: category,
                  label: category,
                }))}
                isClearable={true}
              />
            </div>
            <div>
              <label className="font-semibold mb-3 block">Search Product</label>
              <Select
                options={filteredProducts.map((obj) => ({
                  value: obj.product_name,
                  label: obj.product_name,
                  product_id: obj.product_id,
                  quantity: obj.quantity,
                }))}
                  classNames={{
                    control: () => "form-control overflow-auto p-[0.375rem] border border-slate-200",
                  }}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    addProductToTable(selectedOption);
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div className="grid grid-cols-12 gap-x-6">
              <div className="lg:col-span-6 col-span-12 rounded-md border border-slate-200 p-4 mb-4">
                
                    <div className="flex justify-between mb-4 items-center">
                      <div className="font-semibold mb-3 block">Products</div>
                      {filteredProducts.length > 0 && (
                          <button
                            className="btn-primary text-12 p-2 py-1.5 rounded-md"
                            type="button"
                            onClick={handleSelectAll}
                          >
                            {filteredProducts.length === tableData.length
                              ? "Unselect All"
                              : "Select All"}
                          </button>
                      )}
                    </div>
                    {dataLoading ? (
                      <div className="w-full mt-9 justify-center text-center">
                        Loading...
                      </div>
                    ) : filteredProducts.length > 0 ? (
                      <div
                        className="justify-between grid grid-cols-2 xl:grid-cols-3 gap-x-2 gap-y-2 overflow-x-auto">
                        {filteredProducts.map((obj) => (
                          <div
                            className="sytlecreatesale sytlecreatesalegap w-full justify-between items-center flex border-solid border-1 border-gray-200 rounded-md p-2 text-center"
                            key={obj.id}
                          >
                            <div className="text-12">
                              {obj.product_name}
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                className="w-full rounded-md text-center"
                                checked={tableData.some(
                                  (selectedProduct) =>
                                    selectedProduct.product_id ===
                                    obj.product_id
                                )}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  if (isChecked) {
                                    addProductToTable(obj);
                                  } else {
                                    dispatch(
                                      removeItemFromTable({
                                        product_id: obj.product_id,
                                      })
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center">
                        No Selected Category
                      </div>
                    )}
                
              </div>
              <div
                className="lg:col-span-6 col-span-12 rounded-md border border-slate-200 p-4 mb-4">
                <div className="text-[#287DFD] w-full flex justify-between p-1">
                  <div className="w-[30%] flex justify-start">
                    <span>Product</span>
                  </div>
                  <div className="w-[30%]  flex justify-center">
                    <span>Commission</span>
                  </div>
                  <div className="w-[30%]  flex justify-center">
                    <span>Type</span>
                  </div>
                  <div className="w-[5%]  flex justify-center"></div>
                </div>
                {tableData.length > 0 ? (
                  <>
                    {tableData.map((product) => {
                      return (
                        <div
                          className="flex w-full justify-between p-1 items-center"
                          key={product.product_id}
                        >
                          <div className="w-[30%] ">{product.product_name}</div>
                          <div className="w-[30%]">
                            <div className="flex w-full justify-center">
                              <input
                                type="number"
                                min={0}
                                className="border w-full  border-bg-gray p-2 hideNumberArrow form-control"
                                value={product.commission?.Percentage}
                                onChange={(e) =>
                                  handleCommissionChange(
                                    product.product_id,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="w-[30%] ">
                            <select
                              value={product.commission?.valueType}
                              required
                              onChange={(e) =>
                                handleTypeChange(
                                  product.product_id,
                                  e.target.value
                                )
                              }
                              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            >
                              <option value="percentage">Percentage</option>
                              <option value="amount">Amount</option>
                            </select>
                          </div>
                          <div className="w-[5%] flex  justify-center items-center">
                            <div
                              className=" w-full text-center"
                              onClick={() =>
                                dispatch(
                                  removeItemFromTable({
                                    product_id: product.product_id,
                                  })
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="red"
                                height="16"
                                width="14"
                                viewBox="0 0 448 512"
                                className="inline-block"
                              >
                                <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="mt-5 text-center">
                    No Selected Product
                  </div>
                )}
              </div>
            </div>
          </div>
        </ShowComponent>
        <ShowComponent
          condition={saleCommissionData.comissionType === "product"}
        >
          <div className="form-group w-full justify-end flex">
            <button
              className={`btn-primary ${
                loading && "cursor-not-allowed"
              }`}
              type="submit"
            >
              Create Commission
            </button>
          </div>
        </ShowComponent>
      </form>
    </div>
  );
}

export default CreateCommission;
