import ActiveInactiveButton from "Components/Common/ActiveInactiveButton";
import Button from "Components/Common/Button";
import SelectDate from "Components/Common/DatePicker";
import FilterElement from "Components/Common/FilterElement";
import GernalSelectDropDown from "Components/Common/GeneralSelectDropDown";
import Table from "Components/Common/GeneralTable";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Pagination from "Components/Common/Pagination";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import PurchasesAPI from "api/posapi/purchases";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormatDate from "Components/Common/FormatDate";
import CreatePurchase from "./CreatePurchase";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";

function AllPurchase() {
  const navigate = useNavigate();
  const [dataToShow, setDataToShow] = useState([]);
  const [filteredData,setFilteredData]= useState([])
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [filter, setFilter] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [popupId, setPopupId] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });

  const [filters, setFilters] = useState({
    idPredicate: "eq",
    filterId: "",
    namePredicate: "cont",
    filterName: "",
    startDate: "",
    endDate: "",
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const {
        idPredicate,
        filterId,
        namePredicate,
        filterName,
        startDate,
        endDate,
      } = filters;

      const response = await PurchasesAPI.allPurchasesAPI(
        pageNumber,
        filterPerpage,
        filter,
        idPredicate,
        filterId,
        namePredicate,
        filterName,
        startDate,
        endDate
      );

      setDataToShow(response.data.purchases);
      setFilteredData(
        response.data.purchases.map((item) => ({
          id: item.id,
          created_at: item.created_at,
          created_by: item.purchase_created_by,
          supplier: item.vendor,
          grand_total: item.total_price,
        }))
      );
      setTotalPages(response.data.total_pages);
      setPaginationValue({
        ...paginationValue,
        totalCount: response.data.total_count,
        startValue: response.data.starting_value,
        endValue: response.data.ending_value,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [pageNumber, filterPerpage, filter, filters]);

  const handleDateChange = (date, field) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: date,
    }));
  };

  const handleFilterChange = (filterName, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };

  const handleDetailsClick = (itemId) => {
    navigate(`/purchase/details/${itemId}`);
  };

  const actions = [
    {
      name: "View",
      icon: "./assets/icon/view.png",
      onClick: (item) => handleDetailsClick(item.id),
    },
  ];

  const filterElements = [
    {
      label: "ID",
      predicate: filters.idPredicate,
      filterValue: filters.filterId,
      changePredicate: (value) => handleFilterChange("idPredicate", value),
      changeFilter: (value) => handleFilterChange("filterId", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Purchase Created Name",
      predicate: filters.namePredicate,
      filterValue: filters.filterName,
      changePredicate: (value) => handleFilterChange("namePredicate", value),
      changeFilter: (value) => handleFilterChange("filterName", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
  ];

  const selectPerpage = [
    {
      title: "Show per page",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];

  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
        <div className="w-full flex my-4 justify-between">
            <div className="flex items-center justify-center">
              <h1 className="text-24 font-semibold">Purchase</h1>
            </div>
            <div className="w-[15%] flex justify-end text-end">
              <button
                className="btn-primary w-full"
                onClick={() => setPopupId("addPurchase")}
              >
                Create Purchase
              </button>
            </div>
          </div>
          <ShowComponent condition={popupId === "addPurchase"}>
            <Popup setPopup={() => setPopupId("")} className="w-full sm:w-4/5 xl:w-3/6">
            <div className="h-full relative">
              <CreatePurchase
              setPopupId={setPopupId}
              fetchData={fetchData}
              contentOnly={true} />
              </div>
            </Popup>
          </ShowComponent>
          <div className="mb-2 flex ">
          <div className="flex justify-end w-full">
              <div className="justify-start mt-2">
                <button
                  className="w-full p-2 flex justify-around items-center bg-white rounded-md "
                  onClick={() => setShowFilters(!showFilters)}
                  type="button"
                >
                  <div className="w-[8%]">
                    <img
                      src="./icons/fi-rr-settings-sliders.svg"
                      alt="image"
                      className="m-2"
                    />
                  </div>
                  <div className="m-2">Advanced Filters</div>
                </button>
              </div>
            </div>
            </div>
          {showFilters && (
            <div className="card">
              <div className="card-body">
                <div className="flex flex-wrap gap-4 py-4 ">
                  {filterElements.map((filterElement, index) => (
                    <FilterElement
                      key={index}
                      label={filterElement.label}
                      predicate={filterElement.predicate}
                      filterValue={filterElement.filterValue}
                      changePredicate={filterElement.changePredicate}
                      changeFilter={filterElement.changeFilter}
                      options={filterElement.options}
                    />
                  ))}
                  <SelectDate
                    label="Start Date"
                    selectedDate={filters.startDate}
                    onDateChange={(date) => handleDateChange(date, "startDate")}
                  />
                  <SelectDate
                    label="End Date"
                    selectedDate={filters.endDate}
                    onDateChange={(date) => handleDateChange(date, "endDate")}
                  />
                  {selectPerpage.map((Item, index) => (
                    <GernalSelectDropDown
                      key={index}
                      title={Item.title}
                      value={Item.value}
                      onChange={Item.onChange}
                      options={Item.options}
                    />
                  ))}
                  <ActiveInactiveButton filter={filter} setFilter={setFilter} />
                </div>
              </div>
            </div>
          )}
          <Table
            data={filteredData}
            loading={loading}
            actions={actions}
            startValue={paginationValue.startValue}
          />
          {dataToShow.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default AllPurchase;