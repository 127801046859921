import React, { useState } from "react";
import { setAuthData } from "store/Auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthAPI from "api/auth/auth";
import Button from "../Common/Button";
import AuthWrapper from "Components/Common/Pages/AuthWrapper";
import GeneralInput from "Components/Common/GeneralInput";
import { toast } from "react-toastify";
import { SessionId } from "store/Sale";

function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    loading: false,
    message: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  const handleLogin = (e) => {
    e.preventDefault();
    setFormState({
      ...formState,
      loading: true,
    });

    AuthAPI.signIn({
      user: {
        email: formState.email.toLowerCase(),
        password: formState.password,
      },
    })
      .then((response) => {
        if (response && response.data) {
          dispatch(
            setAuthData({
              currentUser: {
                id: response.data.user.id,
                email: response.data.user.email,
                role_id: response.data.user.role_id,
                company_id: response.data.user.company_id,
                user_name: response.data.user.username,
                default_loc: response.data.user.default_loc,
                access_code: response.data.user.access_code,
              },
              token: response.headers.authorization,
            })
          );
          dispatch(SessionId(response?.data?.cashier_session?.id));
          localStorage.setItem("token", response.headers.authorization);
          navigate("/");
          setTimeout(() => {
            toast.success("Login Successful Welcome!");
          }, 1000);
        } else {
          toast.error(response.data.msg);
          setFormState({
            ...formState,
            loading: false,
          });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.msg);
        const erros =
          error || error.response.data.message
            ? error.response.data.message
            : "An error occurred";
        if (erros) {
          toast.error(`Email / Username Not Found`);
        }
        setFormState({
          ...formState,
          loading: false,
        });
      });
  };
  const handleForgetPassword = () => {
    navigate("/resend-password");
  };
  const inputFields = [
    {
      title: "Enter Email/Username",
      placeholder: "",
      type: "text",
      name: "email",
      className: "mb-9",
      required: true,
      paraClassName: "font-bold mb-4",
    },
    {
      title: "Enter Password",
      placeholder: "",
      type: "password",
      name: "password",
      className: "mb-9",
      required: true,
      paraClassName: "font-bold mb-4",
    },
  ];

  return (
    <AuthWrapper>
      <h1 className="mb-12 text-36 font-bold text-dark dark:text-grey-mode text-center md:text-left">
        Sign In to&nbsp;
        <p className="mt-3 inline-block md:block">your Account</p>
      </h1>
      <form onSubmit={handleLogin}>
        {inputFields.map((field, index) => (
          <GeneralInput
            key={index}
            title={field.title}
            // paraClassName={field.title}
            value={formState[field.name]}
            onChange={handleInputChange}
            placeholder={field.placeholder}
            type={field.type}
            className={field.className}
            name={field.name}
            required={field.required}
            paraClassName={field.paraClassName}
          />
        ))}
        <div className="text-right">
          <button
            className="mb-9 underline text-blue border-0 cursor-pointer inline-block font-bold"
            onClick={handleForgetPassword}
            type="button"
          >
            Forgot Password?
          </button>
        </div>
        <Button loading={formState.loading} type="submit" className="w-full">
          Sign in
        </Button>
      </form>
    </AuthWrapper>
  );
}
export default LogIn;
