import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "../Common/Header/Header";
import ViewStock from "Components/Pos/CreateSale/ViewStock";
import Popup from "Components/Common/Pages/Popup";
import TrackProductModal from "./TrackProductModal/TrackProductModal";
import ShowComponent from "Components/ShowComponent";

const AppWrapper = ({ children, sideBar, contentOnly = false }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [trackProduct, setTrackProduct] = useState(false);
  const location = useLocation();

  const handleTrackProduct = () => {
    setTrackProduct((trackProduct) => !trackProduct);
  };

  const hideComponents =
    location.pathname === "/users/invitation/accept" ||
    location.pathname === "/password/reset" ||
    location.pathname === "/login" ||
    location.pathname === "/resend-password";

  if (contentOnly) {
    return <>{children}</>;
  } else
    return (
      <div className={`${!hideComponents && "bg-blue"} flex`}>
        {sideBar}
        <div
          className={`rounded-tl-3xl rounded-bl-3xl overflow-hidden flex-1 ${
            !hideComponents && "bg-body px-5"
          }`}
        >
          {!hideComponents && currentUser !== null && (
            <Header handleTrackProduct={handleTrackProduct} />
          )}
          <ShowComponent condition={trackProduct}>
            <Popup setPopup={handleTrackProduct}>
              <TrackProductModal />
            </Popup>
          </ShowComponent>
          {children}
          {/* {!hideComponents && currentUser !== null && <Footer />} */}
        </div>
      </div>
    );
};

export default AppWrapper;
