import React, { useState } from "react";
import FormatDate from "./FormatDate";
import ActionsRender from "./ActionsRender";
import Loader from "../../assets/svg/Loader.svg";
const Table = ({ data, actions, loading, onSendClick }) => {
  const capitalizeAndReplaceUnderscores = (str) => {
    if (typeof str === 'string') {
      return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    } else {
      return str;
    }
  };

  const headers =
    data.length > 0
      ? Object.keys(data[0]).filter((header) => header !== "category_id")
      : [];
  const renderHeader = (header, index) => (
    <th
      key={index}
      className={`p-3 py-4 text-blue ${
        index === 0 ? "" : ""
      } `}
    >
      {header}
      {/* <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 gap-0"/> */}
    </th>
  );

  const renderCell = (item, header, headerIndex) => {
    let cellContent;
    if (header === "avatar_url") {
      return (
        <td
          key={headerIndex}
          className={`p-3 py-4 font-medium border-t-1 border-slate-200${
            headerIndex === 0 ? "" : ""
          } `}
        >
        <img
          src={item[header]}
          alt="Avatar"
          className="w-10 h-10 rounded-full"
        />
        </td>
      );
    } else if (header === "card_img"){
      const divClasses = `${
        headerIndex === 0 ? "w-[10%]" : "w-full"
      } border-box ml-2 text-wrap break-all text-center ${
        header === "card_img" ? "flex justify-center items-center" : ""
      }`;
      cellContent = (
        <td key={headerIndex} className={divClasses}>
          <img
            src={item[header]}
            alt={header === "card_img" ? "Card Image" : ""}
            className="w-20 h-10"
          />
        </td>
      );  
    } else if (
        [
          "created_at",
          "invitation_sent_at",
          "invitation_accepted_at",
          "updated_at",
          "issue_date",
          "expiry_date",
          "joining_date",
          "termination_date",
          "warranty_expiry_date",
          "id_expiry_date",
          "probation_end",
          "purchase_date",
          "dob",
          "start_date",
          "end_date",
        ].includes(header)
    ) {
      cellContent = item[header] ? FormatDate(item[header]) : "N/A";
    } else if (header === "description") {
      cellContent =
        item[header].length > 10 ? item[header].substring(0, 5) + "..." : item[header];
    } else if (header === "active") {
      cellContent = item[header] ? "Active" : "Inactive";
    } else if (
      header === "default" ||
        header === "invitation_accepted" ||
        header === "track_attendance" ||
        header === "required" ||
        header === "issue_date" ||
        header === "expiry_date" ||
        header === "document_no" ||
        header === "has_image" ||
        header === "expiry_reminders" ||
        header === "temporary_contract"
    ) {
      cellContent = item[header] ? "Yes" : "No";
    } else if (header === "reinvite_enable") {
      cellContent = item[header] ? (
        <button
          onClick={() => onSendClick(item.id)}
          className="bg-orange text-white rounded p-1 text-12"
        >
          Resend
        </button>
      ) : <p className="text-green">accepted</p>;
    } else if (typeof item[header] === "object") {
      cellContent = "";
    } else {
      cellContent = header === "username_email" ? item[header] : capitalizeAndReplaceUnderscores(item[header]);
    }
  
    return (
      <td
        key={headerIndex}
        className={`p-3 py-4 font-medium border-t-1 border-slate-200${
          headerIndex === 0 ? "" : ""
        } `}
      >
        {cellContent}
      </td>
    );
  };

  const renderAction = (action, actionIndex, item) => (
    <button
      className=""
      key={actionIndex}
      onClick={() => action.onClick(item)}
    >
      <img  src={typeof action.icon === "function" ? action.icon(item) : action.icon} className="w-[1.563rem] mx-1" />
    </button>
  );
  const transformedHeaders =
    data.length > 0
      ? Object.keys(data[0])
          .filter((header) => header !== "category_id")
          .map((header) =>
            header === "avatar_url"
              ? "Images"
              : header ==="username_email" ? "Username/Email" 
              : header ==="reinvite_enable" ? "Status"
              : header ==="active" ? "Status"
              : header ==="card_img" ? "Card Image"
              : capitalizeAndReplaceUnderscores(header))
      : [];

  return (
    <>
      {loading ? (
        <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
          <img src={Loader} className="m-auto" alt="" />
        </div>
      ) : (
        <>
          {data.length > 0 ? (
            <div className="overflow-x-auto bg-white rounded-xl mb-6 p-3">
              <table className="w-full text-center">
                <thead>
                  <tr>
                    {transformedHeaders.map(renderHeader)}
                    {data.length > 0 && actions && actions.length > 0 && (
                      <th className="p-3 py-4 text-blue">Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                  <tr>
                    <>
                      {headers.map((header, headerIndex) =>
                        renderCell(item, header, headerIndex)
                      )}
                      {actions && actions.length > 0 && (
                      <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                        {actions.map((action, actionIndex) =>
                          renderAction(action, actionIndex, item)
                        )}
                      </td>
                      )}
                    </>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="p-4 w-full text-center">No data available.</div>
          )}
        </>
      )}
    </>
  );
};

export default Table;
