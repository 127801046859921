import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import ShowComponent from "Components/ShowComponent";

function SalesInvoiceVoucherJV({
  showTime,
  invoiceNo,
  selectedBranch,
  selectedReport,
  reportData,
  reportSummary,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-18 font-semibold text-center mb-4">
          Transaction Listing
        </div>
        <div className="text-18 font-semibold text-center mb-4">
          Posted Transaction
        </div>
        <div className="text-18 font-semibold text-center mb-4 text-green">{invoiceNo}</div>
        <div className="flex justify-between items-end mb-4">
          <div>
            <div className="flex gap-3">
              <div className="font-bold">Branch Name :</div>
              <div>{selectedBranch?.name}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Date :</div>
              <div>{reportData && reportData.length > 0 ? FormatDate(reportData[0].updated_at):""}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Description :</div>
              <div>
                {" "}
                {selectedReport === "return_invoice_voucher_JV"
                  ? "Sales Return Invoice Voucher"
                  : "Sales Invoice Voucher"}
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-3">
              <div className="font-bold">Print Date :</div>
              <div>{FormatDate(new Date())}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Print Time :</div>
              <div>{showTime}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Page # :</div>
              <div>1</div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="border-y-1 border-black">
              <tr className="w-full text-center">
                <th className="w-[15%] p-2">Account Code</th>
                <th className="w-[15%] p-2">Account Name</th>
                <th className="w-[40%] p-2">Description</th>
                <th className="w-[15%] p-2">Debit</th>
                <th className="w-[15%] p-2">Credit</th>
              </tr>
            </thead>

            <tbody>
              {reportData && reportData?.length > 0 ? (
                <>
                  {reportData?.map((item, index) => (
                    <tr
                      key={index}
                      className="text-center border-t-1 border-black"
                    >
                      {/* <tr className="text-center border-t-1 border-black"> */}
                      <td class="w-[15%] p-2">{item?.code}</td>
                      <td class="w-[15%] p-2">{item?.name}</td>
                      <td class="w-[40%] p-2">{item?.description}</td>
                      <ShowComponent condition={item?.entry_type === "debit"}>
                        <td class="w-[15%] p-2">{item?.value}</td>
                        <td class="w-[15%] p-2">{"0.00"}</td>
                      </ShowComponent>
                      <ShowComponent condition={item?.entry_type === "credit"}>
                        <td class="w-[15%] p-2">{"0.00"}</td>
                        <td class="w-[15%] p-2">{item?.value}</td>
                      </ShowComponent>
                    </tr>
                  ))}

                  <tr className="text-center border-y-1 border-black">
                    <td class="w-[15%] p-2"></td>
                    <td class="w-[15%] p-2"></td>
                    <td class="w-[40%] p-2 font-bold">Grand Total:</td>
                    <td class="w-[15%] p-2 font-bold">
                      {reportSummary?.debit}
                    </td>
                    <td class="w-[15%] p-2 font-bold">
                      {reportSummary?.credit}
                    </td>
                  </tr>
                </>
              ) : (
                <tr className="w-full pt-4 text-center border-t-1 border-black">
                  <td colSpan="5" className="p-4 font-bold">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex w-full mt-10">
          <div className="w-[33.33%] text-lg flex justify-center mr-20">
            <span className="text-lg font-bold mr-3">Prepared by: </span>
            Administrator
          </div>
          <div className="w-[33.33%] text-lg font-bold flex justify-center">
            Checked by
          </div>
          <div className="w-[33.33%] text-lg font-bold flex justify-center ml-20">
            Verified by
          </div>
        </div>
        <div className="flex w-full mt-10 mb-8">
          <div className="w-[33.33%] border-b-1 border-black mr-20"></div>
          <div className="w-[33.33%] border-b-1 border-black"></div>
          <div className="w-[33.33%] border-b-1 border-black ml-20"></div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default SalesInvoiceVoucherJV;
