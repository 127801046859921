import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "Components/Common/GeneralTable";
import ProductsAPI from "api/posapi/product";
import Pagination from "Components/Common/Pagination";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import FilterElement from "Components/Common/FilterElement";
import FormatDate from "Components/Common/FormatDate";
import GernalSelectDropDown from "Components/Common/GeneralSelectDropDown";
import ActiveInactiveButton from "Components/Common/ActiveInactiveButton";
import PosSidebar from "Components/Common/PosSidebar";
import Button from "Components/Common/Button";
import DateFilter from "Components/Common/DatePicker";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";
import TransfersAPI from "api/posapi/transfers";
import CreateProduct from "./CreateProduct";

function AllProduct() {
  const navigate = useNavigate();
  const [filterSetTableData, setFilterSetTableData] = useState([]);
  const [tabledata, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupId, setPopupId] = useState("");
  const [file, setFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [filter, setFilter] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(0);

  const handleSuccessMessage = (response) => {
    const Message = response ? response.data.message : "An error occurred";
    toast.success(`Success:${Message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });
  const handleStartDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      endDate: date,
    }));
  };

  const [filters, setFilters] = useState({
    idPredicate: "eq",
    filterId: "",
    categoryPredicate: "cont",
    filtercategoryname: "",
    productnamePredicate: "cont",
    filterProductname: "",
    skuPredicate: "cont",
    filterSku: "",
    startDate: "",
    endDate: "",
  });

  const handleFilterChange = (filterName, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    if (!selectedLocationId) {
      toast.error("Select a Location");
      return;
    }
    if (!file) {
      setPopupId("importfile");
      toast.error("Please add file");
      return;
    }
    const formData = new FormData();
    formData.set("product_csv[file]", file);
    formData.set("product_csv[location_id]", selectedLocationId);
    ProductsAPI.uploadCSVFile(formData)
      .then((response) => {
        setPopupId("");
        handleSuccessMessage(response);
        fetchProducts();
        setFile(null);
        setSelectedLocationId(0);
        const fileInput = document.getElementById("browsecsv");
        fileInput.value = "";
      })
      .catch((error) => {
        console.error("File upload error:", error);
      });
  };

  const handleImportFile = (e) => {
    e.preventDefault();
    if (!file) {
      setPopupId("importfile");
      return;
    }
    const formData = new FormData();
    formData.set("product_csv[file]", file);
    formData.set("product_csv[location_id]", selectedLocationId);
    ProductsAPI.uploadCSVFile(formData)
      .then((response) => {
        setPopupId("");
        handleSuccessMessage(response);
        fetchProducts();
        setFile(null);
        setSelectedLocationId(0);
        const fileInput = document.getElementById("browsecsv");
        fileInput.value = "";
      })
      .catch((error) => {
        console.error("File upload error:", error);
      });
  };

  const fetchProducts = () => {
    setLoading(true);
    const {
      idPredicate,
      filterId,
      categoryPredicate,
      filtercategoryname,
      productnamePredicate,
      filterProductname,
      skuPredicate,
      filterSku,
      startDate,
      endDate,
    } = filters;
    const formattedStartDate = startDate ? FormatDate(startDate) : "";
    const formattedEndDate = endDate ? FormatDate(endDate) : "";
    ProductsAPI.allProductsAPI(
      pageNumber,
      filterPerpage,
      filter,
      idPredicate,
      filterId,
      categoryPredicate,
      filtercategoryname,
      productnamePredicate,
      filterProductname,
      skuPredicate,
      filterSku,
      formattedStartDate,
      formattedEndDate
    )
      .then((response) => {
        setTableData(response.data.products);
        setFilterSetTableData(
          response.data.products.map((item) => {
            return {
              id: item.id,
              name: item.name,
              sKU: item.sku,
              barcode: item.barcode,
              category: item.category,
              cost: item.cost,
              price: item.price,
              createdAt: FormatDate(item.created_at),
            };
          })
        );
        setTotalPages(response.data.total_pages);
        setPaginationValue({
          ...paginationValue,
          totalCount: response.data.total_count,
          startValue: response.data.starting_value,
          endValue: response.data.ending_value,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchProducts();
  }, [pageNumber, filterPerpage, filter, filters]);
  useEffect(() => {
    TransfersAPI.accessibleLocationsAPI()
      .then((response) => {
        setLocationData(response.data.locations);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleDetailsClick = (itemId) => {
    navigate(`/product/details/${itemId}`);
  };
  const handleUpdateClick = (itemId) => {
    navigate(`/product/edit/${itemId}`);
  };
  const actions = [
    {
      name: "View",
      icon: "./assets/icon/view.png",
      color: "blue",
      onClick: (item) => handleDetailsClick(item.id),
    },
    {
      name: "Edit",
      icon: "./assets/icon/edit.png",
      onClick: (item) => handleUpdateClick(item.id),
    },
  ];
  const filterElements = [
    {
      label: "ID",
      predicate: filters.idPredicate,
      filterValue: filters.filterId,
      changePredicate: (value) => handleFilterChange("idPredicate", value),
      changeFilter: (value) => handleFilterChange("filterId", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Product Name",
      predicate: filters.productnamePredicate,
      filterValue: filters.filterProductname,
      changePredicate: (value) =>
        handleFilterChange("productnamePredicate", value),
      changeFilter: (value) => handleFilterChange("filterProductname", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Sku",
      predicate: filters.skuPredicate,
      filterValue: filters.filterSku,
      changePredicate: (value) => handleFilterChange("skuPredicate", value),
      changeFilter: (value) => handleFilterChange("filterSku", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Category",
      predicate: filters.categoryPredicate,
      filterValue: filters.filtercategoryname,
      changePredicate: (value) =>
        handleFilterChange("categoryPredicate", value),
      changeFilter: (value) => handleFilterChange("filtercategoryname", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Start Date",
      selectedDate: filters.startDate,
      onDateChange: handleStartDateChange,
    },
    {
      label: "End Date",
      selectedDate: filters.endDate,
      onDateChange: handleEndDateChange,
    },
  ];
  const selectPerpage = [
    {
      title: "Show per page",
      className: "flex justify-end",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];

  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
          <ShowComponent condition={popupId === "importfile"}>
            <Popup setPopup={() => setPopupId("")} className="w-[50%]">
              <div className="text-center">
                <div>
                  <p className="text-22 mb-2">Import Products</p>
                </div>
                <p className="mb-2">Select CSV file to import products list.</p>
                <div className="flex justify-center w-full mb-2">
                  <div className="w-1/2">
                    <select
                      className="form-control"
                      value={selectedLocationId}
                      onChange={(e) => {
                        setSelectedLocationId(e.target.value);
                      }}
                      required
                    >
                      <option value="">Select a Location</option>
                      {locationData.map((location) => (
                        <option key={location.id} value={location.id}>
                          {location.address}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex justify-center w-full">
                  <label
                    htmlFor="browsecsv"
                    className="h-12 p-2 w-[40%] btn-primary cursor-pointer items-center text-center flex"
                  >
                    {file ? (
                      <span className="ml-2">Choose File: {file.name}</span>
                    ) : (
                      <>
                        <img
                          src="./icons/upload.png"
                          alt="Upload Icon"
                          className="h-4"
                        />
                        <span className="ml-2">Choose File:</span>
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    accept=".csv"
                    id="browsecsv"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <div className="h-12 w-[14%] flex justify-center">
                    <Button
                      className="w-full btn-primary ml-2"
                      type="submit"
                      onClick={handleUploadFile}
                    >
                      Done
                    </Button>
                  </div>
                </div>
              </div>
            </Popup>
          </ShowComponent>
          <ShowComponent condition={popupId === "addProduct"}>
            <Popup setPopup={() => setPopupId("")} className="w-[444px]">
              <CreateProduct
                setPopupId={setPopupId}
                fetchProducts={fetchProducts}
              />
            </Popup>
          </ShowComponent>
          <div className="w-full flex my-4 justify-between">
            <div className="flex items-center justify-center">
              <h1 className="text-24 font-semibold">Products</h1>
            </div>
            <div className="w-[15%] flex justify-end text-end">
              <button
                className="btn-primary w-full"
                onClick={() => setPopupId("addProduct")}
              >
                Create Product
              </button>
            </div>
          </div>
          <div className="mb-4 flex ">
            <div className="text-left flex justify-content-start align-items-center w-1/2">
              <div className="ml-2 mt-2.5 w-[26%] justify-center align-items-center">
                <button
                  className="justify-center text-center flex mt-3 w-full btn-primary"
                  type="submit"
                  name="importfile"
                  onClick={handleImportFile}
                >
                  Import Products
                </button>
              </div>
            </div>
            <div className="flex justify-end w-1/2">
              <div className=" justify-center mt-4">
                <button
                  className="w-full p-2 flex justify-around items-center bg-white rounded-md "
                  onClick={() => setShowFilters(!showFilters)}
                  type="button"
                >
                  <div className="w-[8%]">
                    <img
                      src="./icons/fi-rr-settings-sliders.svg"
                      alt="image"
                      className="m-2"
                    />
                  </div>
                  <div className="m-2">Advanced Filters</div>
                </button>
              </div>
            </div>
          </div>

          {showFilters && (
            <div className="card">
              <div className="card-body">
                <div className="flex flex-wrap gap-4  ">
                  {filterElements.map((filterElement, index) =>
                    filterElement.selectedDate !== undefined ? (
                      <DateFilter
                        key={index}
                        label={filterElement.label}
                        selectedDate={filterElement.selectedDate}
                        onDateChange={filterElement.onDateChange}
                      />
                    ) : (
                      <FilterElement
                        key={index}
                        label={filterElement.label}
                        predicate={filterElement.predicate}
                        filterValue={filterElement.filterValue}
                        changePredicate={filterElement.changePredicate}
                        changeFilter={filterElement.changeFilter}
                        options={filterElement.options}
                      />
                    )
                  )}
                  {selectPerpage.map((Item, index) => (
                    <GernalSelectDropDown
                      key={index}
                      title={Item.title}
                      value={Item.value}
                      onChange={Item.onChange}
                      options={Item.options}
                    />
                  ))}
                  <ActiveInactiveButton filter={filter} setFilter={setFilter} />
                </div>
              </div>
            </div>
          )}
          <Table
            data={filterSetTableData}
            actions={actions}
            loading={loading}
            startValue={paginationValue.startValue}
          />
          {tabledata.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default AllProduct;
