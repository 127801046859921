import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

export default function CouponSaleReport({
  startDate,
  endDate,
  selectedBranch,
  couponSaleReport,
  couponSummary,
  showTime,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-18 font-semibold text-center mb-4">
          Sales Report by Coupon
        </div>
        <div className="text-18 font-semibold text-center mb-4">
          From Date: {FormatDate(startDate)} To Date: {FormatDate(endDate)}
        </div>
        <div className="flex justify-end items-end mb-4">
          <div>
            <div className="flex gap-3">
              <div className="font-bold">Print Date :</div>
              <div>{FormatDate(new Date())}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold">Print Time :</div>
              <div>{showTime}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="font-bold w-[50%]">Page # :</div>
              <div className="w-[50%]">1</div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-center">
            <thead className="w-full border-y-1 border-black">
              <tr>
                <th className="w-[14.28%] p-2">Voucher Code</th>
                <th className="w-[14.28%] p-2">Invoice No</th>
                <th className="w-[14.28%] p-2">Invoice Date</th>
                <th className="w-[14.28%] p-2">Total Price</th>
                <th className="w-[14.28%] p-2">Discount</th>
                <th className="w-[14.28%] p-2">Net Amount</th>
                <th className="w-[14.28%] p-2">RET</th>
              </tr>
            </thead>
            <tr>
              <td colSpan="7" className="p-4 font-bold">
                Branch:{" "}
                {selectedBranch?.name
                  ? capitalizeFirstLetter(selectedBranch?.name)
                  : ""}
              </td>
            </tr>
            <tbody>
              {couponSaleReport && couponSaleReport.length > 0 ? (
                <>
                  {couponSaleReport?.map((item, index) => (
                    <tr
                      key={index}
                      className="border-t-1 border-black"
                    >
                      <td className="w-[14.28%] p-2">{item.coupon_code}</td>
                      <td className="w-[14.28%] p-2">{item.invoice_no}</td>
                      <td className="w-[14.28%] p-2">
                        {FormatDate(item.updated_at)}
                      </td>
                      <td className="w-[14.28%] p-2">{item.sub_total}</td>
                      <td className="w-[14.28%] p-2">{item.coupon_discount}</td>
                      <td className="w-[14.28%] p-2">{item.total_price}</td>
                      <td className="w-[14.28%] p-2 font-bold">
                        {item.status === "completed" ? "N" : "Y"}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td class="w-[14.28%] p-2"></td>
                    <td class="w-[14.28%] p-2"></td>
                    <td class="w-[14.28%] p-2"></td>
                    <td class="w-[14.28%] p-2 font-bold">
                      {couponSummary?.total_sub_total}
                    </td>
                    <td class="w-[14.28%] p-2 font-bold">
                      {couponSummary?.total_coupon_discount}
                    </td>
                    <td class="w-[14.28%] p-2 font-bold">
                      {couponSummary?.total_total_price}
                    </td>
                    <td class="w-[14.28%] p-2"></td>
                  </tr>
                  <tr>
                    <td class="w-[14.28%] p-2"></td>
                    <td class="w-[14.28%] p-2"></td>
                    <td class="w-[14.28%] p-2"></td>
                    <td class="w-[14.28%] p-2 font-bold">
                      {couponSummary?.total_sub_total}
                    </td>
                    <td class="w-[14.28%] p-2 font-bold">
                      {couponSummary?.total_coupon_discount}
                    </td>
                    <td class="w-[14.28%] p-2 font-bold">
                      {couponSummary?.total_total_price}
                    </td>
                    <td class="w-[14.28%] p-2"></td>
                  </tr>
                </>
              ) : (
                <tr className="w-full pt-4 border-t-1 border-black">
                  <td colSpan="7" className="p-4 font-bold">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}
