import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function DailySaleReport({
  startDate,
  endDate,
  showTime,
  selectedBranch,
  reportData,
  reportSummary,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-18 font-semibold text-center mb-4">
          Daily Sales Report
        </div>
        <div className="flex justify-between items-end mb-4">
          <div>
            <div className="flex gap-3">
              <div className="font-bold">From Date :</div>
              <div>{FormatDate(startDate)}</div>
            </div>
            <div className="flex gap-3 w-full mt-3">
              <div className="font-bold">To Date :</div>
              <div>{FormatDate(endDate)}</div>
            </div>
          </div>
          <div>
            <div className="flex gap-3">
              <div className="font-bold">Print Date :</div>
              <div>{FormatDate(new Date())}</div>
            </div>
            <div className="flex gap-3 w-full mt-3">
              <div className="font-bold w-[50%]">Print Time :</div>
              <div>{showTime}</div>
            </div>
            <div className="flex gap-3 w-full mt-3">
              <div className="font-bold">Page # :</div>
              <div>1</div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table class="w-full text-center">
            <thead className="w-full border-y-1 border-black">
              <tr>
                <th className="w-[8.33%] p-2">Date</th>
                <th className="w-[8.33%] p-2">Cash Amount</th>
                <th className="w-[8.33%] p-2">Visa Amount</th>
                <th className="w-[8.33%] p-2">Tabby Amount</th>
                <th className="w-[8.33%] p-2">Tamara Amount</th>
                <th className="w-[8.33%] p-2">Total Price</th>
                <th className="w-[8.33%] p-2">Discount & Coupon</th>
                <th className="w-[8.33%] p-2">Tax Amount</th>
                <th className="w-[8.33%] p-2">Bonus Amount</th>
                <th className="w-[8.33%] p-2">Net Amount</th>
                <th className="w-[8.33%] p-2">Cost Amount</th>
                <th className="w-[8.33%] p-2">Profit</th>
              </tr>
            </thead>
            <tr>
              <td colSpan="12" className="p-4 font-bold">
                Branch:{" "}
                {selectedBranch?.name
                  ? capitalizeFirstLetter(selectedBranch?.name)
                  : ""}
              </td>
            </tr>
            <tbody className="w-full pt-3">
              {reportData && reportData.length > 0 ? (
                <>
                  {reportData?.map((item, index) => (
                    <tr
                      key={index}
                      className="border-t-1 border-black"
                    >
                      <td class="w-[8.33%] p-2">
                        {FormatDate(item?.updated_at)}
                      </td>
                      <td class="w-[8.33%] p-2">{item?.total_cash_amount}</td>
                      <td class="w-[8.33%] p-2">{item?.total_visa_amount}</td>
                      <td class="w-[8.33%] p-2">{item?.total_tabby_amount}</td>
                      <td class="w-[8.33%] p-2">{item?.total_tamara_amount}</td>
                      <td class="w-[8.33%] p-2">{item?.total_total_price}</td>
                      <td class="w-[8.33%] p-2">{item?.total_discount}</td>
                      <td class="w-[8.33%] p-2">{item?.total_tax}</td>
                      <td class="w-[8.33%] p-2">
                        {item?.total_salesman_reward}
                      </td>
                      <td class="w-[8.33%] p-2">{item?.total_net_sale}</td>
                      <td class="w-[8.33%] p-2">{item?.total_cost_amount}</td>
                      <td class="w-[8.33%] p-2">{item?.total_profit}</td>
                    </tr>
                  ))}
                  <tr className="border-t-1 border-black">
                    <td class="w-[8.33%] p-2 font-bold"></td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_cash_amount}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_visa_amount}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_tabby_amount}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_tamara_amount}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_total_price}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_discount}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_tax}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_salesman_reward}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_net_sale}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_cost_amount}
                    </td>
                    <td class="w-[8.33%] p-2 font-bold">
                      {reportSummary?.total_profit}
                    </td>
                  </tr>
                </>
              ) : (
                <tr className="border-t-1 border-black">
                  <td colSpan="12" className="p-4 font-bold">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default DailySaleReport;
