import React from "react";
import { NavLink } from "react-router-dom";
import ShowComponent from "Components/ShowComponent";
import MenuArrowDown from "../../assets/svg/MenuArrowDown.svg";
import MenuArrowUp from "../../assets/svg/MenuArrowUp.svg";

function SidebarMenuItem({
  menuItem,
  role,
  currentOpenDropdown,
  handleDropdownToggle,
  menuOpen,
}) {
  if (!menuItem.allowedRoles.includes(role)) {
    return null;
  }

  const renderIcon = menuItem.src ? (
    <img className="w-6 h-6" src={menuItem.src} alt="" />
  ) : (
    <img className="w-6 h-6" src="/assets/svg/sidebar-dashboard-icon.svg" alt="" />
  );

  return (
    menuItem.dropdown ? (
      <>
        <div
          className={`${menuOpen ? "flex gap-2 justify-start text-white items-center p-3 mx-4 my-3 block cursor-pointer rounded-lg border-r-8" : "py-4 flex justify-center"} ${
            currentOpenDropdown === menuItem.key ? (menuOpen ? "bg-menu-active border-white" : "after:content-[''] after:absolute after:w-2 after:top-0 after:left-0 after:bottom-0 after:bg-white after:rounded-tr-md after:rounded-br-md") : "not-active-dropdown border-blue"
          }`}
          onClick={() => handleDropdownToggle(menuItem.key)}
        >
          <span>{renderIcon}</span>
          <ShowComponent condition={menuOpen}>
            <span>{menuItem.text}</span>
          </ShowComponent>
          {currentOpenDropdown === menuItem.key ? (
            menuOpen ? <img src={MenuArrowUp} className="ml-auto" alt="" /> : ""
          ) : (
            menuOpen ? <img src={MenuArrowDown} className="ml-auto" alt="" /> : ""
          )}
        </div>
        {menuOpen && menuItem.dropdown && (
          <ul
            className={`block m-5 ml-10 relative ${currentOpenDropdown === menuItem.key ? "block after:content-[''] after:absolute after:top-0 after:bottom-0 after:bg-menu-left-border after:w-[2px] after:rounded-full after:left-[-1px]" : "hidden"}`}
          >
            {menuItem.subMenuItems.map((subMenuItem) => (
              <li key={subMenuItem.key}>
                <NavLink
                  to={subMenuItem.path}
                  className={({ isActive }) =>
                    `p-3 items-center flex gap-3 text-white pl-4 relative ${isActive ? "after:content-[''] after:absolute after:top-0 after:bottom-0 after:bg-white after:w-[4px] after:left-[-2px] after:rounded-full z-[1]" : "not-active-link"}`
                  }
                >
                  <span>
                    {subMenuItem.src ? (
                      <img className="w-6 h-6" src={subMenuItem.src} alt="" />
                    ) : (
                      <img className="w-6 h-6" src="/assets/svg/sidebar-dashboard-icon.svg" alt="" />
                    )}
                  </span>
                  <span>{subMenuItem.text}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </>
    ) : (
      <NavLink
        to={menuItem.path}
        className={({ isActive }) =>
          `relative ${menuOpen ? "flex gap-2 justify-start text-white items-center p-3 mx-4 my-3 rounded-lg border-r-8" : "py-4 flex justify-center"} ${
            isActive ? (menuOpen ? "bg-menu-active border-white" : "after:content-[''] after:absolute after:w-2 after:top-0 after:left-0 after:bottom-0 after:bg-white after:rounded-tr-md after:rounded-br-md") : "not-active-link border-blue"
          }`
        }
      >
        <span>{renderIcon}</span>
        <ShowComponent condition={menuOpen}>
          <span>{menuItem.text}</span>
        </ShowComponent>
      </NavLink>
    )
  );
}
export default SidebarMenuItem;