import React, { useState, useEffect } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import CashierApi from "api/cashier/cashier";
import { useSelector } from "react-redux";
import FormatDate from "Components/Common/FormatDate";
import GeneralInput from "Components/Common/GeneralInput";
import Button from "Components/Common/Button";
import OrderApi from "api/posapi/order";
import { toast } from "react-toastify";
import ShowComponent from "Components/ShowComponent";

function ViewInvoice({ searchAble = false, refundable = false ,checkable}) {
  const [dataToShow, setDataToShow] = useState([]);
  const [filterInvoices, setFilterInvoices] = useState([]);
  const [summaryData,setSummaryData] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("")
  const [buttonCheck,setButtonCheck] = useState("completed")
  const [loading, setLoading] = useState(false);
  const defaultLoc = useSelector((state) => state.auth.currentUser.default_loc);
  const sessionId = useSelector((state) => state.sales?.sessionId);
  const date = new Date()
  const formattedDate = FormatDate(date)
  
  const fetchInvoice = () => {
    if(!sessionId){
      return;
    }
  CashierApi.viewInvoice(defaultLoc,buttonCheck,formattedDate,sessionId)
      .then((response) => {
        console.log(response.data.summary)
        const invoiceData = response.data.list.map((invoice) => ({
          invoice_no: invoice.invoice_no,
          updated_at: invoice.updated_at,
          created_at: invoice.created_at,
          discount: invoice.discount,
          total_price: invoice.sub_total,
          phone_no: invoice.phone_no,
          tax: invoice.tax,
          is_tax_free: invoice.is_tax_free,
          refund_invoice:invoice.refund_invoice,
          status: invoice.status,
          value:invoice.value,
          net_ammount:invoice.total_price
        }));
        const summary = {
          total_discount: response.data.summary.total_discount,
          total_reward: response.data.summary.total_reward,
          total_tax: response.data.summary.total_tax,
          total: response.data.summary.total_total_amount,
          net_sale:response.data.summary.total_net_sale
        }
        
        setSummaryData(summary);
        setDataToShow(invoiceData);
        setFilterInvoices(invoiceData)
        
      })
      .catch((error) => {
        console.log("error", error);
      });
    
}

  const InvoiceCount = new Set(dataToShow.map((invoice) => invoice.invoice_no)).size;
  const handleSearchInvoice = e => {
    let localInvoices = dataToShow
    if (!invoiceNumber) {
      setFilterInvoices(dataToShow)
    } else {
      setFilterInvoices(localInvoices.filter(({ invoice_no }) => invoice_no === invoiceNumber.trim()))
    }
    console.log(dataToShow)
  }
  const handleSetInvoice = e => {
    const { value } = e.target
    setInvoiceNumber(value)
    if (!value) {
      setFilterInvoices(dataToShow)
    }
  }
  const handlebuttonCheck =(e) => {
    const { value } = e.target;
    console.log("New buttonCheck value:", value);
    setButtonCheck(value); 
  };
  const RefundInvoice = (invoice) => {
    OrderApi.refund(invoice).then(res => {
      toast.success("Invoice Refunded");
      fetchInvoice()
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    fetchInvoice()
  }, [buttonCheck]);
  return (
    <PagesWrapper>
      <ShowComponent condition={searchAble}>
        <div className="flex justify-between items-center">
          <GeneralInput placeholder="Invoice No" onChange={handleSetInvoice} value={invoiceNumber} />
          <Button onClick={handleSearchInvoice}>Search</Button>
        </div>
      </ShowComponent>
      <ShowComponent condition={checkable}>
        <div className="box-border w-40  border-2 border-gray-300">
      <div className="">
      <h6 className="font-medium">Type</h6>
      </div>
      <br></br>
      <div flex justify-left items-left>
          <input type="radio" id="completedInvoices" name="invoiceType" onClick={handlebuttonCheck} value="completed" checked={buttonCheck === "completed"}/>
         <label for ="completedInvoices">Invoices</label>
      </div>
      <div flex justify-left items-left>
          <input type="radio" id="returnedInvoices" name="invoiceType" onClick={handlebuttonCheck} value="refunded_sale_child" checked={buttonCheck === "refunded_sale_child"}/>
         <label for="returnedInvoices" >Return Invoices</label>
      </div>
      </div>
      </ShowComponent>
      <br></br>
      <div className="container overflow-auto mx-auto mt-8">
        <table className="min-w-full bg-white border border-gray-300 mb-5">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4">Invoice No</th>
              <th className="py-2 px-4">Invoice Date</th>
              <th className="py-2 px-4">Total Amount</th>
              <th className="py-2 px-4">Discount Amount</th>
              <th className="py-2 px-4">VAT Amount</th>
              <th className="py-2 px-4">Bonus</th>
              <th className="py-2 px-4">Reward</th>
              <ShowComponent condition={checkable}>
              <th className="py-2 px-4">Net Amount</th>
              </ShowComponent>
              <th className="py-2 px-4">Status</th>
              <th className="py-2 px-4">Customer Mobile#</th>
              <th className="py-2 px-4">VAT Free</th>
              <ShowComponent condition={buttonCheck==="completed"}>
              <th className="py-2 px-4">Return</th>
              </ShowComponent>
              <ShowComponent condition={checkable}>
                {buttonCheck ==="refunded_sale_child" &&(
              <th className="py-2 px-4">Refunded Invoice</th>
              )}
              </ShowComponent>
            </tr>
          </thead>
          <tbody>
            {
              filterInvoices.length ?
                filterInvoices?.map((invoice) => (
                  <tr key={invoice.id} className="text-center border-b">
                    <td className="py-2 px-4">{invoice.invoice_no}</td>
                    <td className="py-2 px-4">
                      {FormatDate(invoice.updated_at)}
                    </td>
                    <td className="py-2 px-4">{invoice.total_price}</td>
                    <td className="py-2 px-4">{invoice.discount}</td>
                    <td className="py-2 px-4">{invoice.tax}</td>
                    <td className="py-2 px-4">{invoice.value === null ? "0.0":invoice.value}</td>
                    <td className="py-2 px-4">{"0.0"}</td>
                    <ShowComponent condition={checkable}>
                    <td className="py-2 px-4">{invoice.net_ammount}</td>
                    </ShowComponent>
                    <td className="py-2 px-4">{invoice.status === "refunded_sale_child" ? "Refunded" : invoice.status === "complete_refunded" ? "Refunded" : "Completed"}</td>
                    <td className="py-2 px-4">{invoice.phone_no}</td>
                    <td className="py-2 px-4">
                      {invoice.is_tax_free?.toString()}
                    </td>
                    <ShowComponent condition={buttonCheck==="completed"}>
                    <td className="py-2 px-4 font-bold">{invoice.status === "completed" ? <span>N</span>  : <span className="text-blue">Y</span>}</td>
                    </ShowComponent>

                    <ShowComponent condition={refundable}>
                      <td className="py-2 px-4">
                        <button className="text-red" onClick={() => RefundInvoice(invoice.invoice_no)}>Refund</button>
                      </td>
                    </ShowComponent>
                    <ShowComponent condition={checkable}>
                    <td className="py-2 px-4">{invoice.refund_invoice}</td>
                    </ShowComponent>  
                  </tr>
                )) : <tr className="text-center">
                  <td colSpan={10} className="py-4 px-4 border-b">
                    No Data Available
                  </td>
                </tr>
            }
          </tbody>
        </table>
        <div>
          <h2 className="font-bold mb-3">Total Invoice : {InvoiceCount}</h2>
        </div>
        <ShowComponent condition={checkable}>
        <div className="flex flex-row justify-center items-center border-gray-300 ">
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">Total Amount</div>
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">Total Discount</div>
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">Total VAT</div>
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">Total Bonus</div>
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">Total Net Amount</div>
        </div>
        <div className="flex flex-row justify-center items-center border-gray-300 ">
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">{summaryData.total ? summaryData.total : "0.0"}</div>
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">{summaryData.total_discount ? summaryData.total_discount : "0.0"}</div>
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">{summaryData.total_tax ? summaryData.total_tax : "0.0"} </div>
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">{summaryData.total_reward ? summaryData.total_reward : "0.0"}</div>
        <div className="box-border h-7 w-40 border-2 flex items-center justify-center border-gray-300 rounded-sm">{summaryData.net_sale ? summaryData.net_sale : "0.0"}</div>     
        </div>
        </ShowComponent>
      </div>
    </PagesWrapper>
  );
}

export default ViewInvoice;
