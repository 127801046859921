import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearAllAuthData } from "store/Auth";
import { clearCart } from "store/Sale";
import AuthAPI from "api/auth/auth";
import { toast } from "react-toastify";
import ShowComponent from "Components/ShowComponent";
import Button from "../Button";
import NotificationSidebar from "../NotificationSidebar";
import useWindowDimensions from "Contexts/windowDimensions";
import { SessionId } from "store/Sale";

function Header({ handleTrackProduct }) {
  const navigate = useNavigate();
  const navigateToAccounts = () => {
    navigate("/accounts-dashboard");
  };
  const navigateToPos = () => {
    navigate("/pos-dashboard");
  };
  const navigateToHrm = () => {
    navigate("/hrm-dashboard");
  };
  const navigateToHome = () => {
    navigate("/");
  };
  const [isActive, setIsActive] = useState(false);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.auth.currentUser?.user_name);
  const dropdownRef = useRef(null);
  useEffect(() => {
    if (width <= 1024) {
      document.body.classList.add("sidebar-gone");
    } else {
      document.body.classList.replace("sidebar-gone", "sidebar-show");
    }
  }, [width]);

  const handleToggleUserLogout = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      setIsActive(false);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);

  const handleLogout = () => {
    AuthAPI.signOut()
      .then((response) => {
        dispatch(SessionId(""));
        setTimeout(() => {
          toast.success(response?.data?.msg);
        }, 100);
        if (response) {
          localStorage.removeItem("token");
          navigate("/login");
          dispatch(
            clearAllAuthData({
              currentUser: null,
              token: null,
              role_id: "",
              user_name: "",
            })
          );
          dispatch(clearCart());
        }
      })
      .catch((error) => {
        if (error) {
          toast.error("An error Accourd");
        }
      });
  };

  return (
    <div className="p-3 flex px-4 -mx-6 bg-white mb-6">
      <div className="w-full justify-end text-end relative">
        <div className="flex items-center justify-end">
          {/* <NotificationSidebar></NotificationSidebar> */}
          <Button
            className="mr-3 p-2 text-12 rounded"
            onClick={handleTrackProduct}
          >
            Track Products
          </Button>

          <button
            className="relative rounded flex items-center z-[1]"
            ref={dropdownRef}
            onClick={handleToggleUserLogout}
          >
            <span className="uppercase text-blue text-16 inline-block w-6 h-6 bg-slate-200 rounded-full flex justify-center items-center">
            {(userName && userName.length) ? userName[0] : "U"}
            </span>
            <span className="ml-2 mr-1 text-12 capitalize">{userName}</span>
            <ShowComponent condition={isActive}>
              <i class="fas fa-chevron-up text-[0.6rem]"></i>
            </ShowComponent>
            <ShowComponent condition={!isActive}>
              <i class="fas fa-chevron-down text-[0.6rem]"></i>
            </ShowComponent>
            {isActive && (
              <div className="absolute bg-white rounded shadow-xl p-2 pt-1 top-10 w-[160px] right-0">
                {/* <ul className="text-left mb-1">
                  <li className="py-2 border-b">
                    <a className="text-13 cursor-pointer" onClick={navigateToHome}>
                      Main Dashboard
                    </a>
                  </li>
                  <li className="py-2 border-b">
                    <a className="text-13 cursor-pointer" onClick={navigateToAccounts}>
                      Accounts Dashboard
                    </a>
                  </li>
                  <li className="py-2 border-b">
                    <a className="text-13 cursor-pointer" onClick={navigateToPos}>
                      POS Dashboard
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-13 cursor-pointer" onClick={navigateToHrm}>
                      HRM Dashboard
                    </a>
                  </li>
                </ul> */}
                <button
                  className="btn-primary bg-red w-full"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
export default Header;