import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";
import PageTitle from "Components/Common/PageTitle";

function CardDetail() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [dataToShow, setDataToShow] = useState({});

  useEffect(() => {
    ConfigurationAPI.cardDetail(params.id)
      .then((response) => {
        setLoading(false);
        setDataToShow(response?.data?.card);
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-cards");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <PageTitle pageTitle={"Card Detail"}/>
        <div className="flex justify-end mb-5">
          <Button
            className="btn-primary"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {loading ? (
          <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
            <img
              src="/assets/img/loader.gif"
              className="rounded-full m-auto"
              alt="Loading"
            />
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-x-6">
            <div className="md:col-span-8 col-span-12 mb-5">
              <div className="text-20 mb-4 font-semibold">Card # {dataToShow.id}</div>
              <div className="bg-white rounded-2xl text-center p-5">
                <div className="flex gap-4">
                  <div className=" font-bold">Card Name:</div>
                  <div>{dataToShow.name}</div>
                </div>
              </div>
            </div>
            <div className="md:col-span-4 col-span-12 mb-5">
              <div className="text-20 mb-4 font-semibold">Card Image</div>
              <div className="bg-white rounded-2xl text-center p-5">
                {dataToShow.card_img ? (
                  <img
                    src={dataToShow.card_img}
                    alt="Image"
                    className="rounded-2xl inline-block"
                  />
                ) : (
                  <img
                    src="/icons/no-image.png"
                    alt="No Image Found"
                    className="rounded-2xl inline-block"
                  />
                )}
                </div>
            </div>
          </div>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
}
export default CardDetail;