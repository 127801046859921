import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import React, { useState, useEffect } from "react";
import CunziteCreateSale from "./cunziteCreateSale.png"
import ThemeCreateSale from "./themeCreateSale.png"
import { useNavigate } from "react-router-dom";
import PageTitle from "Components/Common/PageTitle";
function SalePages() {
    const navigate = useNavigate()
    const handleNavigate = e => {
        const { id } = e.target
        navigate(`/${id}`)
    }
    return (
        <AppWrapper sideBar={<PosSidebar />}>
            <PagesWrapper>
            <PageTitle pageTitle={"Choose Screen"}/>
                <div className="grid grid-cols-12 gap-x-6">
                    <div className="md:col-span-6 col-span-12 mb-5 rounded-2xl bg-white shadow-[0_5px_10px_rgb(0,0,0,0.12)]">
                        <div className="m-5 relative hover:after:content-[''] hover:after:top-0 hover:after:bottom-0 hover:after:left-0 hover:after:right-0 hover:after:absolute hover:after:bg-black/50 hover:after:rounded-lg cursor-pointer" id="cashier-ui" onClick={handleNavigate}>
                            <img src={CunziteCreateSale} className="rounded-lg" alt="" />
                        </div>
                    </div>
                    <div className="md:col-span-6 col-span-12 mb-5 rounded-2xl bg-white shadow-[0_5px_10px_rgb(0,0,0,0.12)]">
                        <div className="m-5 relative hover:after:content-[''] hover:after:top-0 hover:after:bottom-0 hover:after:left-0 hover:after:right-0 hover:after:absolute hover:after:bg-black/50 hover:after:rounded-lg cursor-pointer" id="create-sale" onClick={handleNavigate}>
                            <img src={ThemeCreateSale} className="rounded-lg" alt="" />
                        </div>
                    </div>
                </div>
            </PagesWrapper>
        </AppWrapper>
    );
}
export default SalePages;