import React from "react";
import FormatDate from "Components/Common/FormatDate";

function CloseCashier({
  defaultLoc,
  viewClosingDayReport,
  setPopupId,
  showTime,
}) {
  return (
    <>
      <div className="border-[1px] border-gray-400 p-5">
        <div className="flex flex-col">
          <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            Branch ID: {defaultLoc}
          </button>
          <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            Inventory ID: 2
          </button>
          <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            POS ID: 1
          </button>
          <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            Test Head Office
          </button>
          <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            {FormatDate(viewClosingDayReport.timezoneDate)}
          </button>
          <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-gray-400">
            {showTime}
          </button>
        </div>
        <div className="mt-4">
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">Total Sales(Includes Tax)</div>
            <div class="flex items-center mr-3">
              {!viewClosingDayReport.totalSales
                ? "0.0"
                : viewClosingDayReport.totalSales}
            </div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">Cash Sales</div>
            <div class="flex items-center mr-3">
              {!viewClosingDayReport.cashSales
                ? "0.0"
                : viewClosingDayReport.cashSales}
            </div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">Visa Sales</div>
            <div class="flex items-center mr-3">
              {!viewClosingDayReport.visaSales
                ? "0.0"
                : viewClosingDayReport.visaSales}
            </div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">Tabby Sales</div>
            <div class="flex items-center mr-3">{viewClosingDayReport.tabbySales}</div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">Tamara Sales</div>
            <div class="flex items-center mr-3">{viewClosingDayReport.tamaraSales}</div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">Discount Amount</div>
            <div class="flex items-center mr-3">
              {!viewClosingDayReport.discount
                ? "0.0"
                : viewClosingDayReport.discount}
            </div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">Bonus</div>
            <div class="flex items-center mr-3">
              {!viewClosingDayReport.reward
                ? "0.0"
                : viewClosingDayReport.reward}
            </div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">Reward Amount</div>
            <div class="flex items-center mr-3">{"0.0"}</div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">Return Sales(Includes Tax)</div>
            <div class="flex items-center mr-3">
              {!viewClosingDayReport.refund
                ? "0.0"
                : viewClosingDayReport.refund}
            </div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
            <div class="flex items-center ml-3">TAX Amount</div>
            <div class="flex items-center mr-3">
              {!viewClosingDayReport.taxAmount
                ? "0.0"
                : viewClosingDayReport.taxAmount}
            </div>
          </div>
          <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-gray-400">
            <div class="flex items-center ml-3">Net Sales(Includes Tax)</div>
            <div class="flex items-center m-3">
              {!viewClosingDayReport.netSale
                ? "0.0"
                : viewClosingDayReport.netSale}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <button
          class="bg-[#b6c9ee] font-bold py-2 px-4 border-2 border-gray-400"
          onClick={() => setPopupId("")}
        >
          OK
        </button>
        <button
          class="bg-[#b6c9ee] font-bold py-2 px-4 border-2 border-gray-400"
          onClick={() => setPopupId("")}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export default CloseCashier;
