import React from "react";
import Button from "./Button";
import { Tooltip } from "@mui/material";

function ActiveInactiveButton({ filter, setFilter }) {
  return (
    <div>
      <Tooltip
        title={filter ? "Filter Enabled" : "Filter Disabled"}
        placement="bottom"
        arrow
      >
        <Button
          className={`p-[1.05rem] ${
            filter ? "bg-green disabled" : "bg-grey btn-primary"
          }`}
          type="button"
          onClick={() => {
            filter ? setFilter(false) : setFilter(true);
          }}
        >
        {filter ? "Apply" : "Apply"}
        </Button>
      </Tooltip>
    </div>
  );
}

export default ActiveInactiveButton;
