import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";
import PageTitle from "Components/Common/PageTitle";

function UpdateCard() {
  const [cardName, setCardName] = useState("");
  const [image, setImage] = useState(null);
  const params = useParams();

  useEffect(() => {
    ConfigurationAPI.cardDetail(params.id)
      .then((response) => {
        setCardName(response?.data?.card?.name);
        setImage(response?.data?.card?.card_img);
      })
      .catch((error) => {
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
  }, [params.id]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleUpdateCard = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("card[name]", cardName);
    ConfigurationAPI.cardUpdate(params.id, formData)
      .then((response) => {
        const message = response?.data?.message;
        const error = response?.data?.error;
        if (message && error) {
          toast.warning(message + " " + error);
        } else {
          toast.success(response.data.message);
        }
        setCardName("");
        setImage("");
      })
      .catch((error) => {
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-cards");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <PageTitle pageTitle={"Update Card"}/>
        <div className="flex justify-end mb-5">
          <Button
            className="btn-primary"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <form onSubmit={handleUpdateCard}>
          <div className="bg-white rounded-xl mb-6 p-5">
            <label htmlFor="cardName" className="font-semibold mb-3 block">Card Name</label>
            <div className="flex gap-4">
              <input
                type="text"
                className="form-control w-[380px]"
                placeholder="Enter Card Name"
                value={cardName}
                required
                onChange={(e) => setCardName(e.target.value)}
              />
              {/*<img src={image} alt="Image" className="w-full h-14" />*/}
              <Button type="submit">Update</Button>
            </div>
          </div>
        </form>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default UpdateCard;
