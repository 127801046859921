import React, { useState, useEffect } from "react";
import ActiveInactiveButton from "Components/Common/ActiveInactiveButton";
import FilterElement from "Components/Common/FilterElement";
import GernalSelectDropDown from "Components/Common/GeneralSelectDropDown";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Pagination from "Components/Common/Pagination";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import PosSaleCommissionAPI from "api/posapi/salecommission.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PageTitle from "Components/Common/PageTitle";

function AllSaleCommission() {
  const userRole = useSelector((state) => state.auth.currentUser.role_id);
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [filter, setFilter] = useState(false);
  const [idPredicate, setIdPredicate] = useState("eq");
  const [filterId, setFilterId] = useState("");
  const [percentagePredicate, setPercentagePredicate] = useState("eq");
  const [filterPercentage, setFilterPercentage] = useState("");
  const [valuePredicate, setValuePredicate] = useState("eq");
  const [filtervalue, setFiltervalue] = useState("");
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });

  const handleAdvanceFilter = () => {
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  useEffect(() => {
    setLoading(true);
    PosSaleCommissionAPI.AllSaleCommissionPOS(
      pageNumber,
      filterPerpage,
      filter,
      idPredicate,
      filterId,
      percentagePredicate,
      filterPercentage,
      valuePredicate,
      filtervalue
    )
      .then((response) => {
        setDataToShow(response.data.sale_commisions);
        setTotalPages(response.data.total_pages);
        setPaginationValue({
          ...paginationValue,
          totalCount: response.data.total_count,
          startValue: response.data.starting_value,
          endValue: response.data.ending_value,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [
    pageNumber,
    filterPerpage,
    filter,
    filter && idPredicate,
    filter && filterId,
    filter && percentagePredicate,
    filter && filterPercentage,
    filter && valuePredicate,
    filter && filtervalue,
  ]);

  const filterElements = [
    {
      label: "ID",
      predicate: idPredicate,
      filterValue: filterId,
      changePredicate: (value) => setIdPredicate(value),
      changeFilter: (value) => setFilterId(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Percentage",
      predicate: percentagePredicate,
      filterValue: filterPercentage,
      changePredicate: (value) => setPercentagePredicate(value),
      changeFilter: (value) => setFilterPercentage(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Value",
      predicate: valuePredicate,
      filterValue: filtervalue,
      changePredicate: (value) => setValuePredicate(value),
      changeFilter: (value) => setFiltervalue(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
  ];

  const selectPerpage = [
    {
      title: "Show per page",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];
  const navigate = useNavigate();

  const handleDetailsClick = (commissionData) => {
    navigate(`/salesman-detail`, { state: { commissionData } });
  };

  const capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string' || !str) return "";
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };
  

  return (
    <AppWrapper sideBar={<PosSidebar />}>
      <PagesWrapper>
        <PageTitle pageTitle={"Sales Man Commissions"}/>
        <div className="flex justify-end mb-4">
          <button
            className="btn-primary"
            onClick={handleAdvanceFilter}
            type="button">
            Advanced Filters
          </button>
        </div> 
        <div>
          {showAdvancedFilters && (
            <div className="grid grid-cols-12 gap-x-6 [&>div]:lg:col-span-4 [&>div]:xl:col-span-2 [&>div]:col-span-12 bg-white rounded-xl mb-6 p-5 pb-0">
              {filterElements.map((filterElement, index) => (
                <FilterElement
                  key={index}
                  label={filterElement.label}
                  predicate={filterElement.predicate}
                  filterValue={filterElement.filterValue}
                  changePredicate={filterElement.changePredicate}
                  changeFilter={filterElement.changeFilter}
                  options={filterElement.options}
                />
              ))}
              {selectPerpage.map((Item, index) => (
                <GernalSelectDropDown
                  className="form-control"
                  key={index}
                  title={Item.title}
                  value={Item.value}
                  onChange={Item.onChange}
                  options={Item.options}
                />
              ))}
              <div className="mb-5">
                <label class="font-semibold mb-3 block">&nbsp;</label>
                <ActiveInactiveButton filter={filter} setFilter={setFilter} />
              </div>
            </div>
          )}
          <div className="overflow-x-auto bg-white rounded-xl mb-6 p-3">
            <table className="w-full text-center">
              <thead>
                <tr>
                  <th class="p-3 py-4 text-blue">
                    Invoice ID
                  </th>
                  <th class="p-3 py-4 text-blue">
                    Invoice No
                  </th>
                  <th class="p-3 py-4 text-blue">
                    Commission Type
                  </th>
                  <th class="p-3 py-4 text-blue">
                    Value Type
                  </th>
                  <th class="p-3 py-4 text-blue">
                    Com/Amt
                  </th>
                  <th class="p-3 py-4 text-blue">
                    Value
                  </th>
                  {userRole != 4 && (
                    <th class="p-3 py-4 text-blue">
                      Salesman Name
                    </th>
                  )}
                  <th class="p-3 py-4 text-blue">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataToShow.length === 0 ? (
                  <tr>
                    <td colSpan={9} className="p-3 py-4 font-medium border-t-1 border-slate-200">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  dataToShow.map((commission, index) => {
                    return (
                      <tr key={index}>
                        <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                          {commission.sale_id || "N/A"}
                        </td>
                        <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                          {commission.invoice_no || "N/A"}
                        </td>
                        <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                          {capitalizeFirstLetter(
                            commission.sale_commision_type || "N/A"
                          )}
                        </td>
                        <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                          {capitalizeFirstLetter(
                            commission.commision_type || "N/A"
                          )}
                        </td>
                        <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                          {commission.commision_value || "N/A"}
                        </td>
                        <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                          {commission.value || "N/A"}
                        </td>
                        {userRole != 4 && (
                          <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                            {capitalizeFirstLetter(commission.salesman || "N/A")}
                          </td>
                        )}
                        <td className="p-3 py-4 font-medium border-t-1 border-slate-200">
                          <img src="./assets/icon/view.png" className="inline-block" alt="" onClick={() => handleDetailsClick(commission)} />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          {dataToShow.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default AllSaleCommission;
